import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyConfig } from '@ngx-formly/core';
import { isObject } from 'lodash';
@Component({
  selector: 'formly-field-ic1-input',
  template: `
    <div [ngClass]="classes">
      <ng-ui-kit-input
        [type]="type"
        [id]="id"
        [icId]="id"
        [icName]="name"
        [label]="formLabel"
        [OnlyNumbers]="OnlyNumbers"
        [NoSpecialChars]="NoSpecialChars"
        [placeholder]="placeholder"
        [status]="status"
        [formControl]="formControl"
        [errorLabel]="errorLabel | async"
        [formlyAttributes]="field"
        [minlength]="field.props.minlength"
        [maxlength]="field.props.maxlength"></ng-ui-kit-input>

      <!-- <div *ngIf="showError" class="formly-error">
        {{ errorLabel | async }}

        <span *ngIf="currentError === 'PASSWORD_STRENGHT'">
          <ng-ui-kit-tooltip [icId]="'ic1-tooltip-long'" [color]="'warning'">
            <h4 translate>FORMS.VALIDATORS.PASSWORD_SECURITY</h4>
            <div translate>FORMS.VALIDATORS.PASSWORD_DESCRIPTION</div>
          </ng-ui-kit-tooltip>
        </span>

        <span *ngIf="currentError === 'PASSWORD_STRENGHT_MINOSSE'">
          <ng-ui-kit-tooltip [icId]="'ic1-tooltip-long'" [color]="'warning'">
            <h4 translate>FORMS.VALIDATORS.PASSWORD_SECURITY</h4>
            <div translate>FORMS.VALIDATORS.PASSWORD_DESCRIPTION_MINOSSE</div>
          </ng-ui-kit-tooltip>
        </span>
      </div> -->
      <!-- <pre
        >{{ field | json }}
  </pre> -->
    </div>
  `,
})
export class InputFieldType extends FieldType<FieldTypeConfig> {
  constructor(private config: FormlyConfig) {
    super();
  }

  currentError: string = "";

  //se l'id è  stato settato in automatico da formly non lo prendo ma uso quello di key
  override get id(): string {

    if (this.field.id && !this.field.id.includes('formly_')) {
      return `${this.field.id || this.field.key}`;
    } else {
      return `${this.field.key}`;
    }
  }

  get formLabel(): string {
    return `${this.field.props.label} ${this.field.props?.required ? '*' : ''}`;
  }

  get placeholder(): string {
    return this.field?.props?.placeholder || this.formLabel;
  }


  get name(): string {
    return `${this.field.name || this.id}`;
  }

  get OnlyNumbers(): any {
    return this?.props?.['onlyNumbers'] || false;
  }
  get NoSpecialChars(): any {
    return this?.props?.['NoSpecialChars'] || false;
  }
  get classes() {
    return `formly-input ${this.type} `;
  }

  get type(): any {
    return this.props.type || 'text';
  }

  get status(): any {
    return this.showError ? 'error' : '';
  }

  get showStrenght(): boolean {
    return this.currentError === 'PASSWORD_STRENGHT';
  }

  get errorLabel(): any {
    const fieldForm = this.field.formControl;
    this.currentError = '';
    for (const error in fieldForm.errors) {
      if (fieldForm.errors.hasOwnProperty(error)) {
        let message = this.config.getValidatorMessage(error);
        //console.log('errorLabel', { message, error });
        this.currentError = error;
        if (isObject(fieldForm.errors[error])) {
          if (fieldForm.errors[error].errorPath) {
            return undefined;
          }

          if (fieldForm.errors[error].message) {
            message = fieldForm.errors[error].message;
          }
        }

        if (this.field.validation?.messages?.[error]) {
          message = this.field.validation.messages[error];
        }

        if (this.field.validators?.[error]?.message) {
          message = this.field.validators[error].message;
        }

        if (this.field.asyncValidators?.[error]?.message) {
          message = this.field.asyncValidators[error].message;
        }

        if (typeof message === 'function') {
          return message(fieldForm.errors[error], this.field);
        }

        return message;
      } else {
        this.currentError = '';
      }
    }

    return undefined;
  }
}
