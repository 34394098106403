<div class="welcome-page">
  <app-logo></app-logo>
  <div class="welcome-card">
    <h1 [translate-html]="'WELCOME.TITLE'"></h1>
    <h4 [translate-html]="'WELCOME.SUBTITLE'"></h4>

    <p [translate-html]="'WELCOME.DESCRIPTION'"></p>

    <div class="buttons">
      <a *ngIf="!hasChangedFromParam">
        <ng-ui-kit-button [label]="'WELCOME.BUTTONS.DOWNLOAD' | translate" color="secondary" type="button">
        </ng-ui-kit-button>
      </a>

      <a (click)="goSelectDevice()" class="ic1-btn ic1-btn-md ic1-btn-primary">
        <!-- <ng-ui-kit-button
          [label]="'WELCOME.BUTTONS.RENEW' | translate"
          type="button"

        >
        </ng-ui-kit-button> -->
        <span [translate]="'WELCOME.BUTTONS.RENEW'"></span>
      </a>
    </div>
  </div>
  <div class="white-circle">
    <img src="assets/images/welcome_man.png" alt="Logo" class="man-welcome" />
  </div>
</div>
