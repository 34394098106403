<!-- Loading renew-type -->

<app-loading *ngIf="isLoading" size="lg"></app-loading>

<app-message
  *ngIf="status === 'ERROR'"
  status="error"
  text="Si è verificato un errore durante il processo di rinnovo."
  [hideClose]="true"
>
</app-message>

<ng-container *ngIf="showCertificates">
  <div class="ic1-my-20">
    <h4 translate="SUBSTITUTION.ORDER_PAID.TITLE"></h4>
  </div>

  <div *ngIf="showCompletedComponent">
    <app-substitution-complete-title [cosmoContacts]="cosmoContacts">
    </app-substitution-complete-title>
    <br />
    <br />
  </div>

  <!-- Renew type ottenuto -->
  <div *ngIf="!isLoading && status !== 'ERROR'">
    <app-certificates-list
      (certSelected)="onCertsSelected($event)"
      [viewMode]="mainStep"
      [selectable]="true"
      [autoSelect]="true"
    ></app-certificates-list>

    <!-- <app-certificate (certSelected)="onCertSelected($event)" [certificate]="device?.signCertificate"
      viewMode="substitution" [selectable]="true"></app-certificate> -->

    <div class="buttons-row">
      <ng-ui-kit-button
        (onClick)="startEmission()"
        [label]="proceedLabel"
        color="primary"
        [disabled]="!selectedCertifcates?.length"
      ></ng-ui-kit-button>
    </div>
  </div>
</ng-container>

<app-modal
  #emissionModal
  modalId="emissionModal"
  type="loading"
  (close)="onCloseModal($event)"
  [showFooter]="false"
>
</app-modal>
