import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalComponent, ModalData, ModalType } from '@app/components/modal/modal.component';
import { DikeService } from '@app/services/dike.service';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { updateCertificatesStatus } from '@app/store/actions/device.actions';
import { Certificate, Device } from '@app/store/models';
import { MainStep } from '@app/store/models/app.models';
import { PaymentModes } from '@app/store/models/certificate.model';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { NgxSmartModalService } from 'ngx-smart-modal';



const logger = new LoggerService("GoPaymentComponent");

@Component({
  selector: 'app-go-payment',
  templateUrl: './go-payment.component.html',
  styleUrls: ['./go-payment.component.scss']
})
export class GoPaymentComponent implements OnInit {

  @Input() device: Device;
  @Input() mainStep!: MainStep;

  @ViewChild('couponRaoModal') couponRaoModal: ModalComponent;
  @ViewChild('loadingModal') loadingModal: ModalComponent;
  loadingModalType: ModalType = "loading";

  @ViewChild(TemplateRef, { static: false }) couponInput: TemplateRef<any>;

  showCountDown: number = 0;

  constructor(
    private dikerWebService: DikerWebService,
    private dikeService: DikeService,
    public ngxSmartModalService: NgxSmartModalService,
    private store: Store<GlobalState>,
    private translateService: TranslateService
  ) {

  }

  ngOnInit(): void {


    //Preselezioni tutti i cert selezionabili
    // setTimeout(() => {
    //   this.showCountDown = 3;
    // })
  }


  _selectedCertificates: Certificate[] = [];
  get certificates(): Certificate[] {



    if (this.mainStep === "renew") {
      return this.device?.certificates;
    }
    else if (this.mainStep === "substitution") {
      return [this.device?.signCertificate]
    } else {

      return []
    }
  }

  get paymentRestriction() {

    if (this.selectedCertificates.length === 0) {
      return [];
    }

    let commonRestrictions: PaymentModes[] = [...this.selectedCertificates[0].paymentRestriction];

    for (let i = 1; i < this.selectedCertificates.length; i++) {
      let currRestrictions = this.selectedCertificates[i].paymentRestriction;
      commonRestrictions = commonRestrictions.filter((restriction) =>
        currRestrictions.includes(restriction)
      );
    }

    return commonRestrictions;
  }



  get payWithCOUPON() {
    return this.paymentRestriction.includes("COUPON")
  }

  get payWithRAO() {
    return this?.paymentRestriction.includes("RAO")
  }

  get payWithSHOP() {
    return this?.paymentRestriction.includes("SHOP")
  }

  get hasPaymentMethods() {

    return this.payWithCOUPON || this.payWithRAO || this.payWithSHOP;
  }


  onCertsSelected(certificates: Certificate[]) {
    //TODO: check che abbiano TUTTI lo stesso stato
    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ onCertSelected ~ certificates:", certificates)
    // filter duplicates
    certificates = uniqBy(certificates, 'certId')


    this._selectedCertificates = certificates;
  }

  get selectedCertificates() {
    return this._selectedCertificates;
  }

  get enabledPayButtons() {
    return this.selectedCertificates.length > 0;
  }


  async checkCertsP10() {
    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ proceedRenew ~ this.certsSelected:", this.selectedCertificates)

    return new Promise(async (resolve, reject) => {

      const certsWithoutP10 = this.selectedCertificates.filter(cert => !cert.hasP10);

      //Se ci sono certificati senza P10, li genero
      if (certsWithoutP10.length > 0) {

        // this.openLoadingModal({
        //   title: this.translateService.instant('RENEW.GEN_P10.MODAL_TITLE'),
        //   message: this.translateService.instant('RENEW.GEN_P10.MODAL_LOADING_MESSAGE'),
        //   type: "loading"
        // });

        //Controllo che tutit i certificati selezionati abbiano il P10
        //se non ce l'hanno,  generateP10

        this.loadingModal.setData({ message: `Genero P10 del certificato 1 di ${certsWithoutP10.length}` });

        const promiseFunctions = certsWithoutP10.map((cert, index) => {
          //http://localhost:42100/dike/web/990696FC0987C8DB37BD5F51B46EAD78.diker1:b12002a6-d276-48bd-842c-564b76ac0c45/GEN_P10?id=3&certId=RNDMSM63P28D708P:2023111114397:C7D229C231A9C3970542FA77FF39EC7B71A6E169:179901&ts=202306141802514
          return () => this.dikeService.DikeCommand("GEN_P10", { certId: cert.certId }).then((res) => {
            this.loadingModal.setData({ message: `Genero P10 del certificato ${(index % 2) + 1} di ${certsWithoutP10.length}` });
            return res;
          });
        });

        // Eseguo le promesse in sequenza
        const sequentialPromise = promiseFunctions.reduce((prevPromise, promiseFn) => {
          return prevPromise.then(promiseFn);
        }, Promise.resolve());

        sequentialPromise
          .then((res) => {
            logger.log("GEN_P10", res);
            resolve(true)
          })
          .catch(err => {
            logger.error(err)
            const message = err?.message || this.translateService.instant('ERRORS.GEN_P10_ERROR');
            this.loadingModal.setData({ message, type: "error" });
            reject(err)
          }).finally(() => {
            resolve(true)
          })



      } else {
        resolve(true)
      }
    })

  }

  /**
   * Invia il pagamento al server ecommerce e apre la pagina di pagamento
   */
  async sendEcommercePayment() {

    this.openLoadingModal({ title: "Sto creando il carrello...", type: "loading" });

    //Controllo che tutti i certificati selezionati abbiano il P10
    await this.checkCertsP10();

    const discountCode = ""

    //Prendo tutti i certId dei certificati selezionati
    const certsId = this.selectedCertificates.map(cert => cert.certId)
    debugger;
    //Creo il carrello
    this.dikerWebService.createGuestCart(certsId, this.device.atr, discountCode).then(({ shopRedirectUrl }) => {
      logger.log("🚀 ~ GoPaymentComponent ~ this.dikerWebService.createGuestCart ~ shopRedirectUrl:", shopRedirectUrl)
      debugger;


      const title = this.translateService.instant("GO_PAYMENT.ECOMMERCE.MODAL_TITLE")
      const message = this.translateService.instant("GO_PAYMENT.ECOMMERCE.MODAL_COUNTDOWN")

      //Apro modale in modalità COUNTDOWN con url di callback
      this.loadingModal.setData({ title, message, redirectUrl: shopRedirectUrl, type: "info" })

    }).catch((err) => {

      const errorMessage = err?.error?.message || err?.message || "Si è verificato un errore durante la creazione del carrello"
      this.loadingModal.setData({ title: "Errore", message: errorMessage, type: "error" })

    }).finally(() => {

    });

  }

  /**
   * Invia pagamento con COUPON per i certificati selezionati
   */
  sendCouponPayment(couponCode) {

    // Apro modale in modalità LOADING
    this.openLoadingModal({ title: "Sto pagando con COUPON", type: "loading" });
    debugger;
    // Creo un array di funzioni che ritornano le promesse per ogni certificato selezionato
    const promiseFunctions = this.selectedCertificates.map((cert, index) => {
      const isCns = cert.type === "CNS";
      return () => this.dikerWebService.couponPayment(cert.certId, couponCode, isCns).then((res) => {
        this.loadingModal.setData({ message: `Pagando Certificato ${index + 1} di ${this.selectedCertificates.length}` });
        return res;
      });
    });

    // Eseguo le promesse in sequenza
    const sequentialPromise = promiseFunctions.reduce((prevPromise, promiseFn) => {
      return prevPromise.then(promiseFn);
    }, Promise.resolve());

    // Aspetto che tutte le promesse siano risolte con successo
    sequentialPromise
      .then((res) => {
        logger.log("🚀 ~ sendCouponPayment ~ this.dikerWebService.couponPayment ~ res:", res);
        this.onCloseLoadingModal();
        // TUTTO OK PAGATO
        this.store.dispatch(updateCertificatesStatus());
      })
      .catch((err) => {
        this.loadingModal.setData({ title: "Errore", message: err.message, type: "error" });
      });
  }

  /**
 * Invia pagamento RAO per i certificati selezionati
 */
  sendRaoPayment(username, password) {
    debugger;
    // Apro modale in modalità LOADING
    this.openLoadingModal({ title: "Sto pagando RAO", type: "loading" });

    // Creo un array di funzioni che ritornano le promesse per ogni certificato selezionato
    const promiseFunctions = this.selectedCertificates.map((cert) => {
      return () => this.dikerWebService.RaoPayment(cert.certId, username, password);
    });

    // Eseguo le promesse in sequenza
    const sequentialPromise = promiseFunctions.reduce((prevPromise, promiseFn) => {
      return prevPromise.then(promiseFn);
    }, Promise.resolve());

    // Aspetto che tutte le promesse siano risolte con successo
    sequentialPromise
      .then((res) => {
        logger.log("🚀 ~ sendRaoPayment ~ this.dikerWebService.RaoPayment ~ res:", res);
        this.onCloseLoadingModal();
        // TUTTO OK PAGATO
        this.store.dispatch(updateCertificatesStatus());
      })
      .catch((err) => {
        this.loadingModal.setData({ title: "Errore", message: err.message, type: "error" });
      });
  }


  modalTitle: string = "Inserisci un coupon";
  modalMessage: string = "Inserisci un coupon di rinnovo fornito da un ufficio RAO autorizzato"


  public openCouponRaoModal(data: ModalData = {}) {
    this.couponRaoModal.openModal();
  }

  onCloseModal($event = undefined) {
    //logger.log("🚀 ~ onCloseModal ~ $event:" )


  }

  async onConfirmCouponRaoModal($event = undefined) {
    //logger.log("🚀 ~ onConfirmModal ~ $event:")
    //Controllo che tutti i certificati selezionati abbiano il P10
    await this.checkCertsP10();
    debugger;
    if ($event.data.coupon) {
      this.sendCouponPayment($event.data.coupon)
    } else if ($event.data.username && $event.data.password) {
      this.sendRaoPayment($event.data.username, $event.data.password)
    }
  }



  public openLoadingModal(data: ModalData = {}) {
    this.loadingModal.setData(data)
    this.loadingModal.openModal();
  }

  onCloseLoadingModal($event = undefined) {
    //logger.log("🚀 ~ onCloseModal ~ $event:", $event)

  }


}
