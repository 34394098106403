<div class="certificate-component ic1-my-20" [ngClass]="[viewMode]" [class.isOpen]="isOpen"
  [class.wrongStep]="!hasCurrentStep">
  <div *ngIf="certificate" class="certificate-wrapper">
    <div class="certificate-list-item" [ngClass]="[updateStatus]">

      <app-loading *ngIf="updateStatus === 'LOADING'" size="xs" [noMessage]="true"></app-loading>

      <!-- CHeckbox -->
      <div class="certificate-checkbox" *ngIf="showCheckbox && updateStatus !== 'LOADING'">
        <ng-ui-kit-checkbox icName="certificateChecked" dimension="small" [indeterminate]="false" [checked]="isChecked"
          [disabled]="disabledCheckbox" (onChange)="onChangeCheckbox($event, certificate)">
        </ng-ui-kit-checkbox>
      </div>

      <!-- Type -->
      <div class="certificate-type">{{ certificateType }}</div>

      <app-debugger title="certificate.appStep">
        <div>{{certificate?.appStep}}</div>
      </app-debugger>

      <!-- Expire Date -->
      <app-certificate-expire-date [certificate]="certificate"></app-certificate-expire-date>


      <ng-container>
        <!-- Status -->
        <div class="certificate-status">
          <ng-ui-kit-badge *ngIf="certificateStatusLabel" [color]="badgeColor" [label]="certificateStatusLabel"
            size="sm">
          </ng-ui-kit-badge>
        </div>

        <ng-ui-kit-icon [icon]="accordionIcon" class="accordion-arrow" (onClick)="onToggle()">
        </ng-ui-kit-icon>
      </ng-container>
    </div>

    <!-- CErtificate content -->
    <ng-container>

      <app-message *ngIf="viewMode === 'substitution'" status="warning"
        [text]="'GLOBALS.CERTIFICATES.SUBSTITUTION_MESSAGE' | translate" [hideClose]="true">
      </app-message>


      <div class="certificate-details">
        <div class="flex-column">
          <div class="ic1-text-label-1">Intestatario</div>
          <div class="ic1-text-body-2">{{ certificate.fullName }}</div>
          <div class="ic1-text-body-2">{{ certificate.cf }}</div>
        </div>

        <div class="flex-column">
          <div class="ic1-text-label-1">Stato</div>
          <div class="ic1-text-body-2"><span>{{certificateStatusLabel}}</span><span><app-certificate-expire-date
                [certificate]="certificate"></app-certificate-expire-date></span>
          </div>
        </div>

        <div class="flex-column">
          <div class="ic1-text-label-1">Dettagli del certificato</div>
          <div class="ic1-text-body-2 word-wrap">
            {{ certificateSerialNumber }}
          </div>
        </div>
      </div>
    </ng-container>
    <app-debugger [data]="certificate" title="certificate"></app-debugger>

  </div>
</div>
