import { createAction, props } from '@ngrx/store';
import { Device } from '../models';

export const getDevices = createAction(
  '[Device] Get Devices'
);


export const setDevices = createAction(
  '[Device] Set Devices',
  props<{ devices: Device[] }>()
);
export const removeDevices = createAction(
  '[Device] Remove Devices'
);

export const refreshDevices = createAction(
  '[Device] Refresh Devices'
);


