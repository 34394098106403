import { STUB_RANDO } from '@app/stubs/stub-devices';
import { GlobalState } from '../store';


export const selectDevices = (state: GlobalState) => {
  let devices = state.devices || [];

  if (window.dikeDebug.stubDevice) {
    devices = [STUB_RANDO()]
  }



  return devices;
}

