import { Component, OnInit } from '@angular/core';
import { getDeviceError, getLoadingMessage, isDeviceLoading } from '@app/store/selectors/app.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';


@Component({
  selector: 'app-device-loading',
  templateUrl: './device-loading.component.html',
  styleUrls: ['./device-loading.component.scss']
})
export class DeviceLoadingComponent implements OnInit {


  $deviceErrorMessage: Observable<string> = of("")
  $isDeviceLoading: Observable<boolean> = of(false);
  $loadingMessage: Observable<string> = of("")


  constructor(private store: Store<GlobalState>) { }



  ngOnInit(): void {

    this.$deviceErrorMessage = this.store.select(getDeviceError)
    this.$isDeviceLoading = this.store.select(isDeviceLoading)
    this.$loadingMessage = this.store.select(getLoadingMessage)

  }

}
