

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { downloadP7Mcertificate } from '@app/helpers/device.helpers';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { toggleMainLoading } from '@app/store/actions/app.actions';
import { Device } from '@app/store/models';
import { AppFlow, AppStep, MainStep } from '@app/store/models/app.models';
import { RenewTypeResponse } from '@app/store/models/certificate.model';
import { getCurrentFlowAndStep } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState, resetGlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';

const logger = new LoggerService("CertInstalledComponent");

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent {

  @Input() device!: Device;

  cosmoContacts: RenewTypeResponse = undefined; //StubCosmoContacts

  currFlow: {
    currentFlow: AppFlow | undefined;
    currentStep: AppStep | undefined;
    mainStep: MainStep | undefined;
  }

  constructor(private router: Router, private dikerWebService: DikerWebService, private store: Store<GlobalState>, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    const state = this.route?.snapshot?.params['state'] || history?.state;
    console.log("🚀 ~ CompletedComponent ~ ngOnInit ~ state:", state)
    debugger;
    if (state.cosmoContacts) {
      this.cosmoContacts = state.cosmoContacts;
    } else if (state.certId) {
      this.getRenewType(this.device.signCertificate.certId)
    } else {

      this.store.dispatch(toggleMainLoading({ showLoading: true }));

      this.store.select(getCurrentFlowAndStep).subscribe((currFlow) => {

        this.currFlow = currFlow;

        if (this.currFlow.mainStep === 'substitution') {
          if (this.device) {

            this.getRenewType(this.device.signCertificate.certId)
          } else {
            this.store.select(selectDevice).subscribe(device => {
              if (device) {
                this.device = device

                this.getRenewType(device.signCertificate.certId)

              } else {

                this.store.dispatch(resetGlobalState())
                this.router.navigate([ROUTE_PATHS.home]);
              }
            })
          }
        }
      })
    }


  }

  getRenewType(certId) {

    this.dikerWebService.checkRenewType(certId).subscribe((cosmoContacts) => {

      this.cosmoContacts = cosmoContacts
      logger.log("🚀 ~ CertInstalledComponent ~ this.dikerWebService.checkRenewType ~ cosmoContacts:", cosmoContacts)

      this.store.dispatch(toggleMainLoading({ showLoading: false }));
    })
  }

  downloadCertificate(base64Data = this.cosmoContacts.signedAssertion) {
    downloadP7Mcertificate(base64Data)
  };

}
