import { formatCertificate, getInfoByKey } from '@app/helpers/certificate.helpers';
import { CosmoV7Device, DeviceATRModels, GenericDeviceModel } from '@app/helpers/device.helpers';
import { Action, createReducer, on } from '@ngrx/store';
import { removeDevice, setDevice, setStubDevice, updateDevice, updateDeviceCertificate } from '../actions/device.actions';
import { Certificate, Device } from '../models';
import { DeviceModel, DisplayLabel } from '../models/device.model';


export const initialState: Device = undefined

export const deviceReducer = createReducer<
  Device,
  Action
>(
  initialState,

  on(setDevice, (state, device) => {

    const deviceFormatted = formatDevice(device);

    return { ...deviceFormatted };
  }),
  on(setStubDevice, (state, device) => {

    const deviceFormatted = formatDevice(device);

    return { ...deviceFormatted };
  }),

  on(removeDevice, (state) => {
    return undefined
  }),
  on(updateDevice, (state, device) => {

    return { ...state, ...device };
  }),
  on(updateDeviceCertificate, (state, { partialCertificate }) => {
    const certificates = state.certificates.map(cert => cert.certId === partialCertificate.certId ? { ...cert, ...partialCertificate } : cert);

    // const allCertUpdated = certificates.every(cert => cert.updateStatus === 'UPDATED');
    // const updateStatus: UpdateStatus = allCertUpdated ? 'UPDATED' : 'LOADING';
    const signCertificate = certificates.find(certificate => certificate.cert.nonrepudiation === 'true');

    return { ...state, certificates, signCertificate };
  })
);



export const formatDevice = (device: Device): Device => {

  const certificates = device?.certificates.map(certificate => formatCertificate(certificate, device)) || []

  const signCertificate = certificates.find(certificate => certificate.cert.nonrepudiation === 'true');


  //Controllo ATR
  const deviceModel = getDeviceModel(device);

  const displayLabel = getDisplayLabel(certificates);


  let formattedDevice = { ...device, deviceModel, certificates, signCertificate, displayLabel }

  const isHealthCard = getIsHealthCard(formattedDevice);

  formattedDevice.isHealthCard = isHealthCard;

  return formattedDevice;
}


const getDisplayLabel = (certificates: Certificate[]): DisplayLabel => {

  const certificate = certificates?.find(certificate => certificate?.cert?.nonrepudiation === 'true') || certificates?.[0];

  return {
    ownerFullName: certificate?.fullName,
    ownerCf: certificate?.cf,
  };

}

const getDeviceModel = (device: Device): DeviceModel => {

  let deviceModel: DeviceModel = GenericDeviceModel;

  if (window.dikeDebug.enableCosmo) {
    deviceModel = CosmoV7Device;
  } else {
    const foundDeviceType = DeviceATRModels.find(function (currDeviceAtr) {
      return new RegExp(currDeviceAtr.ATRRegex).test(
        window.dikeDebug.forceAtr ||
        device.atr.toLocaleLowerCase()
      );
    });
    deviceModel = foundDeviceType || GenericDeviceModel;
  }
  return deviceModel
}


const getIsHealthCard = (device: Device): boolean => {

  /*
    seriale che inizia per 6
    ATR JSIGN4
    "IssuerDN" del certificato che contiene "Infocert" secondo la regex sottto:
          add("INFOCERT SPA");
          add("InfoCert S.p.A.");
          add("infocert spa");
          add("infocert s.p.a.");
          add("infocert");
          add("Infocert");
          add("InfoCert");
          add("INFOCERT");
*/

  const deviceModel = getDeviceModel(device);
  if (deviceModel.name === "JSign v4") {

    const serialStartWith6 = device.serial.startsWith("6");
    const authCert = device?.certificates?.find(cert => cert?.ckaid === "CNS0");

    const IssuerDN = authCert ? getInfoByKey(authCert, 'icGetIssuerDN') : undefined;

    const issuerIsInfocert = IssuerDN?.match(/(INFOCERT|InfoCert|infocert|Infocert|InfoCert S.p.A.|INFOCERT SPA|infocert s.p.a.|infocert spa)/g) ? true : false;

    return serialStartWith6 && issuerIsInfocert;

  } else {
    return false;
  }

}
