

export interface Customization {
  owner: OwnersType;
}

export const allOwners = ['INFOCERT', 'OPENDOT', 'VISURA', 'SIXTEMA', 'LEXTEL', 'UNAPPA', 'ENI', 'CONSULENTI', 'CNDCEC', 'CGN', 'EUREKA', 'OTHERS', 'UNKN', 'TESSERA SANITARIA', 'COLDIRETTI', "THIRDPARTY"] as const;

export const allowedSubsOwners: OwnersType[] = ['INFOCERT', 'SIXTEMA', 'COLDIRETTI', 'THIRDPARTY']

export type OwnersType = typeof allOwners[number];
