import { cloneDeep, noop } from 'lodash';
import * as log from 'loglevel';
import * as prefix from 'loglevel-plugin-prefix';


export class LoggerService {
  logger: log.RootLogger;

  constructor(private name: string = "logger") {
    //console.log("🚀 ~ LoggerService ~ level:", this.level)
    //console.count("LoggerService");

    log.setDefaultLevel("DEBUG");
    prefix.reg(log);

    prefix.apply(log, {
      template: '[%t] (%n):',
      levelFormatter: (level) => {
        return level.toUpperCase();
      },
      nameFormatter: () => {
        return this.name;
      }
    });
    this.logger = cloneDeep(log);
  }

  public get level(): log.LogLevelDesc { return this.logger.getLevel() }
  public set level(value: log.LogLevelDesc) { this.logger.setLevel(value || "DEBUG") }

  get enabled() {
    return !!window?.dikeDebug?.isDebug
  }

  get log() { return this.enabled ? this.logger.log.bind(this) : noop }
  get trace() { return this.enabled ? this.logger.trace.bind(this) : noop }
  get debug() { return this.enabled ? this.logger.debug.bind(this) : noop }
  get info() { return this.enabled ? this.logger.info.bind(this) : noop }
  get warn() { return this.enabled ? this.logger.warn.bind(this) : noop }
  get error() { return this.enabled ? this.logger.error.bind(this) : noop }


  // private colorLog(args: IArguments) {
  //   return [`%c${args[0]}%c`, 'color: blue', '', ...args.slice(1)]
  // }
}

