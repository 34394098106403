import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) { }


  getComuni(provincia: string): Observable<City[]> {

    const url = "https://cap.openapi.it/cerca_comuni";


    return this.http.get<any>(url, {
      headers: {
        "Authorization": "Bearer 643d4df2b180b613505e26a2"
      },
      params: {
        provincia
      }
    }).pipe(
      map(response => response?.data?.result || []),
      map((response: City[]) => sortBy(response, ["comune"])),
    );
  }

}


export interface City {
  istat: string;
  comune: string;
  suppressed: boolean;
}
