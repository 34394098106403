import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-layout-full-screen',

  template: `
<div class="flex h-screen w-full items-center justify-center">

    <router-outlet></router-outlet>

</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFullScreenComponent { }
