import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';

import { ActionsManagerService } from '@app/services/actions-manager.service';
import { LoggerService } from '@app/services/logger.service';


import { AppFlow, AppStep, MainStep, SubstituteStepsTypes } from '@app/store/models/app.models';
import { getCurrentFlowAndStep } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';

import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

const logger = new LoggerService("checkFlowComponent");
@Component({
  selector: 'app-check-flow',
  templateUrl: './check-flow.component.html',
  styleUrls: ['./check-flow.component.scss']
})
export class checkFlowComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actionsManagerService: ActionsManagerService,
    private store: Store<GlobalState>
  ) { }


  currentUrl: any;
  stepParam!: SubstituteStepsTypes;
  currentFlow!: AppFlow
  currentStep!: AppStep
  mainStep!: MainStep

  ngOnInit(): void {
    //console.count('checkFlowComponent.ngOnInit()');

    combineLatest([
      this.store.select(getCurrentFlowAndStep),
      this.store.select(selectDevice)
    ]).subscribe(([{ currentFlow, currentStep, mainStep }, device]) => {

      this.mainStep = mainStep
      //Se sono in Renew o Substitution e non ho un device, torno alla home
      if (mainStep === "renew" || mainStep === "substitution") {

        if (!device) {
          this.router.navigate([ROUTE_PATHS.home]);
        }

      }
    })

    // this.store.select(getCurrentFlowAndStep).subscribe(({ currentFlow, currentStep, mainStep }) => {
    //   console.log("🚀 ~ checkFlowComponent ~ this.store.select ~ { currentFlow, currentStep, mainStep }:", { currentFlow, currentStep, mainStep })


    //   if (mainStep !== this.mainStep) {

    //     if (mainStep === 'renew') {
    //       this.router.navigate([ROUTE_PATHS.renew]);
    //     }
    //     if (mainStep === 'substitution') {
    //       this.router.navigate([ROUTE_PATHS.substitution]);
    //     }
    //   }


    // })
    // combineLatest([
    //   this.route.url,
    //   this.route.params,
    //   this.route.queryParams,
    //   this.store.select(selectDevice)
    // ]).subscribe(([url, params, queryParams, device]) => {
    //   console.log({ url, params, queryParams, device })


    // })

  }


}
