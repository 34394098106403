<app-main-card>
  <ng-container header>
    <div class="card-header">
      <h2 class="card-title" translate="GLOBALS.SELECT_CERTIFICATE.SECTION_TITLE"></h2>

      <app-steps *ngIf="mainStep!=='select_device'"></app-steps>
    </div>
  </ng-container>

  <router-outlet></router-outlet>
</app-main-card>

<app-debugger [data]="currentFlow" title="currentFlow"> </app-debugger>
