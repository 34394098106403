import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

interface customOption {
  value: string;
  label: string;
  price: string;

}
@Component({
  selector: 'formly-field-ic1-radio',
  template: `

    <div *ngFor="let option of props.options | formlySelectOptions:field | async; let i = index"  >

      <div [ngClass]="{'checked': selected === option.value }" class="radio-container ic1-border " (click)="setChecked(option.value)">
          <input type="radio"
                [id]="id + '_' + i"
                [name]="id"
                [value]="option.value"
                [formControl]="formControl"
                [formlyAttributes]="field"
              >
            <div class="flex-row space-between">
                <div class="title">{{ getLabel(option.value) }}</div>

            </div>
            <div class="flex-row space-between">
              <div class="description"  [innerHTML]="getDescription(option.value)"></div>
              <a class="select-button" translate="GLOBALS.SELECT"></a>
            </div>
      </div>

    </div>
  `,
  styleUrls: ['./form-styles.scss'],
  styles: [` `],
})
export class RadioCertType extends FieldType<FieldTypeConfig> {

  constructor(private translateService: TranslateService) {
    super();
  }
  setChecked(value: any) {
    this.formControl.setValue(value);
  }

  get selected() {
    return this.formControl.value;
  }


  getLabel(id) {

    return this.translateService.instant(`SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.OPTIONS.${id}.LABEL`);
  }



  getDescription(id) {
    return this.translateService.instant(`SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.OPTIONS.${id}.DESCRIPTION`);
  }


}
