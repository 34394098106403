import { createAction, props } from '@ngrx/store';
import { Certificate, Device } from '../models';



export const addDevice = createAction(
  '[Device] Add Device',
  props<Device>()
);

export const setDevice = createAction(
  '[Device] Set Device',
  props<Device>()
);

export const setStubDevice = createAction(
  '[Device] Set STUB Device',
  props<Device>()
);


export const updateDevice = createAction(
  '[Device] Update Device',
  props<Partial<Device>>()
);


export const updateDeviceCertificate = createAction(
  '[Device] Update Device Certificate',
  props<Partial<{ partialCertificate: Partial<Certificate> }>>()
);

export const updateCertificatesStatus = createAction(
  '[Device] Update Certificates Status',
  //props<Device>()
);

export const checkDevicePossibleActions = createAction(
  '[Device] Check Device Possible Actions'
);

export const removeDevice = createAction(
  '[Device] Remove Device'
);

