import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsManagerService } from '@app/services/actions-manager.service';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { setCurrentFlow } from '@app/store/actions/app.actions';
import { setDevice } from '@app/store/actions/device.actions';
import { Device } from '@app/store/models';
import { deviceLoaded } from '@app/store/selectors/app.selector';
import { selectDevices } from '@app/store/selectors/devices.selector';
import { GlobalState } from '@app/store/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

const logger = new LoggerService("DevicesSelectComponent");

@UntilDestroy()
@Component({
  selector: 'app-devices-select',
  templateUrl: './devices-select.component.html',
  styleUrls: ['./devices-select.component.scss']
})
export class DevicesSelectComponent implements OnInit {

  devices: Device[] = [];
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() autoSelectDeviceATR: string = "";

  loading = false;
  selectedDevice: Device | null = null;

  deviceInitilized = false;
  constructor(private store: Store<GlobalState>, private translateService: TranslateService, private dikerWebService: DikerWebService, private actionsManagerService: ActionsManagerService, private route: ActivatedRoute,) {

    const currentFlow = this.actionsManagerService.APP_STEPS.select_device;

    const currentStep = currentFlow.find(step => step.mainStep === "select_device");

    this.store.dispatch(setCurrentFlow({ currentFlow, currentStep, mainStep: "select_device" }));

    if (!this.placeholder) {
      this.placeholder = this.translateService.instant("GLOBALS.DEVICES.SELECT_DEVICE")
    }



  }


  ngOnInit(): void {

    combineLatest([
      this.store.select(selectDevices),
      this.store.select(deviceLoaded),
    ]).subscribe(([devices, loaded]) => {
      console.log({ devices, loaded })
      if (loaded) {
        //Se arriva il parametro deviceID, faccio il dispatch di getDevices e poi lo passo al componente app-device-select
        this.devices = devices;
        if (!this.deviceInitilized && this.autoSelectDeviceATR) {
          this.initDevices();
        }
      }
    })
  }



  initDevices(): void {
    console.count("initDevices")
    if (!this.deviceInitilized) {
      this.deviceInitilized = true;
      setTimeout(() => {
        //se autoSelectDeviceID è valorizzato lo seleziono autoamticamente
        if (this?.autoSelectDeviceATR) {

          const autoSelectDevice = this.devices?.find(device => device?.atr === this.autoSelectDeviceATR);
          if (autoSelectDevice) {
            this.loading = false;
            this.onChangeSelect(autoSelectDevice)
          }


        } else if (this.devices?.length === 1) {
          //se c'è un solo device lo seleziono
          this.loading = false;
          this.onChangeSelect(this.devices[0])
        }
      }, 3000)
    }
  }

  getDeviceInfo(device) {

    const label = `${device?.model} ${device?.manufacturerID}`;
    const subtitle = `atr:${device?.atr} - serial: ${device?.serial}`;
    return {

      label,
      atr: device.atr,
      subtitle
    };

  }

  onChangeSelect(foundDevice: Device) {

    if (!this.loading && foundDevice) {
      this.loading = true;
      logger.log("onChangeSelectDevice ~ device:", foundDevice)
      this.store.dispatch(setDevice(foundDevice))

    }
  }


  pialla(certId: string) {

    if (certId) {
      this.dikerWebService.pialla(certId).subscribe(res => {
        alert(certId + " piallato !")
      })
    }
  }


  setPagato(certId: string) {

    // if (certId) {
    //   this.dikerWebService.setPagato(certId).subscribe(res => {
    //     alert(certId + " pagato !")
    //   })
    // }
  }
}
