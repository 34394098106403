import * as moment from 'moment-timezone';

import jsonToFormDatas from '@ajoelp/json-to-formdata';
import { Moment } from "moment";
import { Observable, catchError, startWith } from 'rxjs';

export const getAllUrlParams = () => {
  // get query string from url (optional) or window
  var queryString = window.location.search.slice(1);
  // we'll store the parameters here
  var obj: any = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName?.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          //@ts-ignore
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  } else {
    const hashParams = window.location.hash.split('?')[1];
    if (hashParams) {
      obj = hashParams.split('&').reduce(function (params: any, item) {
        const [prop, value] = item.split('=');
        params[prop] = value;
        return params;
      }, {});
    }
  }

  return obj;
};

export const decodeHTMLEntities = (str: any = "") => {
  var element = document.createElement('div');

  if (str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
    element.remove();
  }

  return str;
};

export const isSafariBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
};
export const FixSafariCalendarIcon = () => {
  setTimeout(() => {
    //console.log('FixSafariCalendarIcon');
    if (isSafariBrowser()) {
      document.querySelectorAll("input[type='date']").forEach(el => {
        var wrapper = document.createElement('div');
        wrapper.className = 'date-wrapper';
        if (el?.parentNode) {
          // insert wrapper before el in the DOM tree
          el.parentNode.insertBefore(wrapper, el);
          // move el into wrapper
          wrapper.appendChild(el);

          var icon = document.createElement('img');
          icon.src = 'assets/images/calendar-icon.svg';
          icon.className = 'calendar-icon';
          wrapper.appendChild(icon);
        }
      });
    }
  }, 500);
};

export function isObject(x: any) {
  return x != null && typeof x === 'object';
}

export function isTranslateKey(key: string) {
  return key === key.toUpperCase() && key.includes('.');
}


export const getTimestamp = () => moment().format('YYYYMMDDHHmmSSS');
export const getExpirationDate = (validity: string) => moment(
  validity,
  'YYYYMMDDHHmmss'
);
export const getExpirationDateFromTimestamp = (ts: number) => moment(ts)
export const getSysTs = () => moment().format('YYYYMMDDHHmmss');
export const isExpiredDate = (expires: Moment) => moment().isAfter(expires);
export const isExpiredMoreThanXdays = (expires: Moment, days = 90) => moment().isAfter(expires.clone().add(days, 'days'));

export const objectToUrlParams = (obj: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          searchParams.append(key, val);
        });
      } else {
        searchParams.append(key, value);
      }
    }
  }

  return searchParams.toString();
}


export const jsonToFormData = (json: any): FormData => {
  let formData = jsonToFormDatas(json)
  return formData;
}


export const jsonSerialize = (obj: any): URLSearchParams => {

  let params: URLSearchParams = new URLSearchParams();


  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var element = obj[key];

      params.set(key, element);
    }
  }

  return params;

}

export const catchSwitchMapError =
  (errorAction: (error: any) => any) =>
    <T>(source: Observable<T>) =>
      source.pipe(catchError((error, innerSource) => innerSource.pipe(startWith(errorAction(error)))));


export const urlHasParam = <T>(param: string): T => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const debugParam = urlParams.get(param);

  return (debugParam || false) as T
}

export const Capitalize = (str: string = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const hasUrlParam = (param: string, value?: any) => {
  const params = new URLSearchParams(window.location.search);
  const hasParam = params.has(param);
  if (value) {
    const paramValue = params.get(param);
    return hasParam && paramValue === value;
  } else {
    return hasParam;
  }

}
