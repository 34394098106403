import { Component, OnInit } from '@angular/core';
import { AppFlow, AppStep, SubstituteStepsTypes } from '@app/store/models/app.models';

import { getCurrentFlowAndStep } from '@app/store/selectors/app.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {


  $currFlow!: Observable<{
    currentFlow: AppFlow | undefined;
    currentStep: AppStep | undefined;
    mainStep: string | undefined;
  }>
  currentUrl: any;
  stepParam!: SubstituteStepsTypes;



  constructor(private store: Store<GlobalState>) { }
  //currentStep: number = 0;
  //steps: string[] = ["seleziona il certificato", "step2", "step3"]

  ngOnInit(): void {
    this.$currFlow = this.store.select(getCurrentFlowAndStep)
  }

  onStepClick(step: any) {
    //console.log("🚀 ~ StepsComponent ~ onStepClick ~ step:", step)

  }




  get currentStep(): Observable<number> {
    return this.$currFlow.pipe(
      map(({ currentFlow, currentStep }) => {

        return currentStep?.stepIndex || 0;
      })
    );
  }

  get steps(): Observable<string[]> {
    return this.$currFlow.pipe(
      map(({ currentFlow, currentStep }) => {

        if (!currentFlow) return []

        return currentFlow.filter(step => step.stepIndex >= 0 && !step.hide).map((step) => step.label);
      })
    );
  }

}


