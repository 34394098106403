import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { cleanPayload, mobileValidatorFn, validEmailValidator } from '@app/components/forms/formly-validators';
import { ModalComponent, ModalData } from '@app/components/modal/modal.component';
import { ProvincesOptions, countryOptions } from '@app/helpers/address_helpers';
import { AddressService } from '@app/services/address.service';
import { DikeService } from '@app/services/dike.service';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { updateCertificatesStatus } from '@app/store/actions/device.actions';
import { Device } from '@app/store/models';
import { CNSsubs, CertTypeRequest, Contacts, DikeTransactionPayload, SignType } from '@app/store/models/certificate.model';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { iif, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

const logger = new LoggerService("SelectSubstituteComponent");
export type StepsIds = "productType" | "certType" | "contacts" | "privacy" | "cns" | "recap";

interface ModelForm {
  productType: string;
  email: string;
  privacy: boolean;

  street?: string;
  number?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  province?: string;

  mobile: string;
  terms: boolean;
  confirmModule: boolean;

  //se e un THIRDPARTY (ruba) il certificato
  certType?: CertTypeRequest,
  /**solo se certType==="CNS" */
  cns?: CNSsubs
}

@Component({
  selector: 'app-select-substitute',
  templateUrl: './select-substitute.component.html',
  styleUrls: ['./select-substitute.component.scss']
})
export class SelectSubstituteComponent implements OnInit, AfterViewInit {


  @Input() device: Device;

  deprecatedMessage: string = "";
  deprecatedMessageType: "warning" | "error" = "warning";

  contacts: Contacts;
  loading: boolean = false;

  initialMobile: string = "";


  get isThirdParty() {
    return this?.device?.owner === "THIRDPARTY"
  }

  get showRecapProductType() {
    return this.step > this.getStepFieldIndex("productType") && this.step <= this.totalSteps
  }

  get showRecapCertType() {
    return this.isThirdParty && this.step > this.getStepFieldIndex("certType") && this.step <= this.totalSteps
  }

  get showRecapCNS() {
    return this.isThirdParty && this?.model?.certType === "CNS" && this.step > this.getStepFieldIndex("cns") && this.step <= this.totalSteps
  }

  get showRecapContacts() {
    if (this.isThirdParty) {
      return this.step > this.getStepFieldIndex("contacts") && this.step <= this.totalSteps

    } else {
      return this.step > this.getStepFieldIndex("contacts") && this.step <= this.totalSteps
    }
  }

  goBackTo(backStep: StepsIds) {

    this.step = this.getStepFieldIndex(backStep);
  }

  getStepFieldIndex(backStep: StepsIds) {
    //@ts-ignore
    const StepId = this.stepsFields.findIndex(({ id }) => id === backStep);
    return StepId;
  }

  constructor(
    private store: Store<GlobalState>,
    private translateService: TranslateService,
    private dikeService: DikeService,
    private dikerWebService: DikerWebService,
    private addressService: AddressService,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  selectType(type: SignType) {

    this.formGroup.patchValue({ productType: type });
  }

  methodOptions: any[] = [];

  @ViewChild('subsModal') subsModal: ModalComponent;

  ngAfterViewInit(): void {
    //SE THIRD PARTY NON LA FACCIO
    if (this.device?.owner !== "THIRDPARTY") {
      this.getContacts();
    }
  }

  ngOnInit() {
    this.initForm();
    this.getWarningMessage();
  }

  getWarningMessage() {

    if (this.device) {
      const { deviceModel: { name }, owner, signCertificate } = this.device;

      let translateKey = `DEPRECATED_MESSAGES.DEPRECATED_WARNING.${name.toUpperCase()}`;
      if (name === "CNS v2" && owner === "INFOCERT") {

        this.deprecatedMessageType = "error";

        console.log("signCertificate.expires", signCertificate.expires.format('DD-MM-YYYY'));

        let name_key = "CNS2_BEFORE_JULY_2024"
        if (signCertificate.expires.isBefore('2024-07-01')) {
          name_key = `${name.toUpperCase()}_BEFORE_JULY_2024`

        } else {

          name_key = `${name.toUpperCase()}_AFTER_JULY_2024`
        }

        translateKey = `DEPRECATED_MESSAGES.DEPRECATED_ERROR.${name_key}`;

      }

      if (owner === "THIRDPARTY") {
        this.deprecatedMessage = ""
      } else {
        this.deprecatedMessage = this.translateService.instant(translateKey);

      }
    }
  }

  getContacts() {
    this.dikerWebService.defaultcontacts({ certId: this.device.signCertificate.certId }).then((contacts) => {
      logger.log("🚀 ~ SelectSubstituteComponent ~ this.dikerWebService.defaultcontacts ~ contacts:", contacts)

      let mobile = contacts?.mobile || "";
      console.log("🚀 ~ SelectSubstituteComponent ~ this.dikerWebService.defaultcontacts ~ mobile:", mobile)
      //se non ha il + davanti metto solo il prefisso
      if (!mobile.startsWith("+")) {
        this.initialMobile = mobile;
        mobile = "+39";



      }

      this.contacts = contacts;

      this.model = {
        ...this.model,
        ...contacts,
        mobile
      }

      this.loading = false;
    }).catch((error) => {

      if (!window.dikeDebug.skipContactsError) {
        let errorMessage = this.translateService.instant('ERRORS.CONTACTS.GENERIC_ERROR');

        if ([406, 409, 410].includes(error.status)) {
          errorMessage = this.translateService.instant('ERRORS.CONTACTS.STATUS_' + error.status);

        }
        else if (error.status === 412) {
          //Errore contatti mancanti, msg con COUPON e DECLINE
          const coupon = error?.error?.addInfo?.paramValue;
          errorMessage = this.translateService.instant('ERRORS.CONTACTS.STATUS_412', { coupon });

          this.dikerWebService.decline(this.device.signCertificate.certId);

          //this.store.dispatch(updateCertificatesStatus());
        }
        else if (error.status === 401) {
          //Errore Blocco per RAO su rinnovo

          errorMessage = this.translateService.instant('ERRORS.CONTACTS.STATUS_401');

          this.dikerWebService.decline(this.device.signCertificate.certId);

          //this.store.dispatch(updateCertificatesStatus());
        }
        this.openModal({
          title: "Errore",
          message: errorMessage,
          type: "error",
          onClose: () => {

            debugger;
            this.store.dispatch(updateCertificatesStatus());
          }
        })
      } else {


        this.loading = false;

      }


    })
  }

  get mobileErrors() {
    const errors = this.form?.get('mobile')?.errors || {}
    console.log("🚀 ~ SelectSubstituteComponent ~ getmobileErrors ~ errors:", errors)
    console.log("🚀 ~ SelectSubstituteComponent ~ get mobileErrors ~ errors:", this.form.get('mobile'))
    return errors
  }


  initForm() {
    //Prendo traduzioni per il form e lo inizializzo
    this.translateService.get([
      'GLOBALS.SELECT',
      'GLOBALS.PRICE',
      'GLOBALS.REMOTE_SIGN',
      'GLOBALS.SC',
      'GLOBALS.BK',
      'GLOBALS.CNS',
      'GLOBALS.CNS_LIKE',
      'GLOBALS.AUT',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CONTACTS_DATA',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CHOOSE_OPTION',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CHOOSE_CERT_TYPE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.SHIPPING_ADDRESS',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.RESIDENCE_ADDRESS',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.EMAIL',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.MOBILE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.INITIAL_MOBILE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.COUNTRY',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PROVINCE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CITY',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.ZIP_CODE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET_NUMBER',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_TITLE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_ACCEPTANCE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_PROCEED',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.GENERAL_CONDITIONS',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.PRIVACY',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.CONFIRM_MODULE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.GENERAL_CONDITIONS_URL',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.PRIVACY_URL',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.CONFIRM_MODULE_URL',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.INSERT_DATA',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_TYPE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.CI',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.PASSPORT',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DRIVING_LICENSE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_NUMBER',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_EXPIRATION_DATE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_RELASE_DATE',
      'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.COPY_FROM_RESIDENCE',


    ]).subscribe(translations => {

      this.translations = translations;
      this.stepsFields = this.formFields(translations, {
        productType: this.model.productType,
        country: this.model.country,
        certType: this.model.certType,

      });


    });
  }

  get productOptions() {

    const hasRemoteSign = this.device.owner !== "COLDIRETTI" && this.device.owner !== "THIRDPARTY";;
    return [
      //SE OWNER COLDIRETTI O THIRDPARTY NON HA REMOTE SIGN
      ...(hasRemoteSign ? [{ label: this.translations['GLOBALS.REMOTE_SIGN'], value: 'REMOTE_SIGN' }] : []),
      { label: this.translations['GLOBALS.SC'], value: 'SC' },
      { label: this.translations['GLOBALS.BK'], value: 'BK' },
    ]
  }

  formFields(translations, {
    productType = this.model.productType,
    country = this.model.country,
    certType = this.model.certType
  }) {
    console.log("🚀 ~ SelectSubstituteComponent ~ formFields ~ _model:", country, certType)
    if (!translations) return []
    const formFieldType = country !== 'IT' ? 'ic1-text' : 'formly-field-select';
    console.log("🚀 ~ SelectSubstituteComponent ~ getformFields ~ formFieldType:", formFieldType)

    return [
      //STEP 1 SELECT OPTION REMOTE SIGN, SC, BK
      {
        label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CHOOSE_OPTION'],
        id: "productType",
        fields: [

          {

            key: 'productType',
            type: 'ic1-radio',
            props: {
              label: 'Select an option',
              required: true,
              prices: this.prices,
              options: this.productOptions,
            },
            hooks: {
              onInit: (field) => {
                const productTypeControl = field.formControl;
                productTypeControl.valueChanges.subscribe((productTypeValue) => {
                  console.log("🚀 ~ SelectSubstituteComponent ~ productTypeControl.valueChanges.subscribe ~ countryValue:", productTypeValue)
                  this.stepsFields = this.formFields(translations, { productType: productTypeValue });

                });
              },

            },
          },

        ],
      },

      //SE OWNER THIRDPARTY MOSTRO I CAMPI PER LA SELEZIONE del CertTypeRequest "CNS" | "CNS_LIKE" | "AUT"
      ...((this.device.owner === "THIRDPARTY" && productType !== "REMOTE_SIGN") ? [
        {
          label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CHOOSE_CERT_TYPE'],
          id: "certType",
          fields: [
            {
              key: 'certType',
              type: 'ic1-radio-certType',
              props: {
                label: 'Select an option',
                required: true,

                options: [
                  { label: this.translations['GLOBALS.CNS'], value: 'CNS' },
                  //{ label: this.translations['GLOBALS.CNS_LIKE'], value: 'CNS_LIKE' },
                  { label: this.translations['GLOBALS.AUT'], value: 'AUT' }
                ]
              },
              hooks: {
                onInit: (field) => {
                  const certTypeControl = field.formControl;
                  certTypeControl.valueChanges.subscribe((certTypeValue) => {
                    console.log("🚀 ~ SelectSubstituteComponent ~ certTypeControl.valueChanges.subscribe ~ countryValue:", certTypeValue)
                    this.stepsFields = this.formFields(translations, { certType: certTypeValue });

                  });
                },

                // onChanges: (field) => {
                //   console.log("🚀 ~ SelectSubstituteComponent ~ formFields ~ field:", field)
                //   setTimeout(() => {
                //     this.stepsFields = this.formFields(translations);
                //     field.formControl.updateValueAndValidity();
                //   }, 100);
                // },
              },
            },

          ],
        },

      ] : []),

      ...(this.device.owner === "THIRDPARTY" && certType === "CNS" ? [
        {
          label: "",
          id: "cns",
          fields: [


            {
              key: "cns",
              type: 'formly-group',
              wrappers: ['custom-group'],
              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.INSERT_DATA'],
              },

              fieldGroup: [
                {
                  key: 'docType',
                  type: "formly-field-select",
                  className: 'ic1-col-12 ic1-col-lg-6  ',
                  props: {
                    label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_TYPE'],
                    placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_TYPE'],
                    options: [{
                      value: "CI",
                      label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.CI'],
                    }, {
                      value: "PASSPORT",
                      label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.PASSPORT'],
                    },
                    {
                      value: "DRIVING_LICENSE",
                      label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DRIVING_LICENSE'],
                    }
                    ],
                    attributes: {
                      class: "ic1-input"
                    }
                  },
                  validation: {
                    show: true,
                  }

                },
                {
                  key: 'docNumber',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',

                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_NUMBER'],
                    required: true
                  },
                },
                {
                  key: 'docExpirationDate',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',

                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_EXPIRATION_DATE'],
                    required: true,
                    type: 'date',
                  },
                },
                {
                  key: 'docReleaseDate',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',

                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_RELEASE_DATE'],
                    required: true,
                    type: 'date',
                  },
                },


                {
                  key: 'residence',
                  fieldGroup: this.getAddressFields(formFieldType, productType, certType, true)
                },
                {
                  key: 'releaseBy',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',
                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.DOC_DOC_RELEASE_BY'],
                    required: true
                  },
                }],

            }]
        }
      ] : []),

      //STEP 2 CONTACTS DATA
      {
        label: "",
        id: "contacts",
        fields: [{

          type: 'formly-group',
          // wrappers: ['custom-group'],


          fieldGroup: [
            {

              type: 'formly-group',
              wrappers: ['custom-group'],
              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CONTACTS_DATA'],
                placeholder: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CONTACTS_DATA'],
              },


              fieldGroup: [
                {
                  key: 'email',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',
                  validators: {
                    validation: [
                      Validators.required,
                      validEmailValidator
                      //  { name: 'PASSWORD_STRENGHT', options: { user } },
                    ],
                  },

                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.EMAIL'],
                    required: true,
                    type: 'email',
                  },
                },
                {
                  key: 'mobile',
                  type: 'ic1-text',
                  className: 'ic1-col-12 ic1-col-lg-6',

                  validators: {
                    validation: [
                      Validators.required,
                      mobileValidatorFn
                    ],
                  },
                  props: {
                    label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.MOBILE'] + (this.initialMobile ? ` ( ${translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.INITIAL_MOBILE']} ${this.initialMobile} )` : ""),
                    required: true,
                    type: 'tel',

                  },
                },
              ]
            },


            // SE OWNER COLDIRETTI NON MOSTRO O DISABILITO TUTTI GLI INDIRIZZI STEP 2
            ...(this.device.owner === "COLDIRETTI" ? [] : [{

              type: 'formly-group',

              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.SHIPPING_ADDRESS'],
              },

              fieldGroup: this.getAddressFields(formFieldType, productType, certType)
            }]),


          ],
        }
        ]
      },
      // STEP 3 PRIVACY
      {
        id: "privacy",
        label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_TITLE'],

        fields: [{
          wrappers: ['privacy-group'],
          type: 'formly-group',
          props: {
            label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_ACCEPTANCE'],
            description: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY_PROCEED']
          },

          fieldGroup: [
            {
              type: 'ic1-checkbox',
              key: 'terms',
              validators: {
                validation: [Validators.requiredTrue],
              },
              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.GENERAL_CONDITIONS'],
                required: true,
                url: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.GENERAL_CONDITIONS_URL'],
                attributes: {
                  class: "checkbox-privacy"
                },

              },
            },
            {
              type: 'ic1-checkbox',
              key: 'privacy',
              validators: {
                validation: [Validators.requiredTrue],
              },
              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.PRIVACY'],
                required: true,
                url: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.PRIVACY_URL'],
                attributes: {
                  class: "checkbox-privacy"
                },

              },
            },
            {
              type: 'ic1-checkbox',
              key: 'confirmModule',
              validators: {
                validation: [Validators.requiredTrue],
              },
              props: {
                label: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.CONFIRM_MODULE'],
                required: true,
                url: translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRIVACY.CONFIRM_MODULE_URL'],
                attributes: {
                  class: "checkbox-privacy",
                },

              },
            },

          ]
        }
        ],
      },
    ]
  }

  getAddressFields(formFieldType: 'ic1-text' | 'formly-field-select' = "ic1-text", productType = "", certType = "", isResidenceCNS = false) {

    const groupLabel = isResidenceCNS ? this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.RESIDENCE_ADDRESS'] : this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.SHIPPING_ADDRESS'];

    const shippingRequired = productType === 'SC' || productType === 'BK' || isResidenceCNS;
    return [{

      type: 'formly-group',
      wrappers: ['custom-group'],
      props: {
        label: groupLabel,
      },

      fieldGroup: [
        ...((certType === "CNS" && !isResidenceCNS) ? [
          {
            key: 'copyResidence',
            type: 'ic1-checkbox',
            className: 'ic1-col-12',
            templateOptions: {
              label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CNS.COPY_FROM_RESIDENCE'],
            },
            hooks: {

              onInit: (field) => {
                const copyControl = field.formControl;
                copyControl.valueChanges.subscribe((copyValue) => {
                  console.log("🚀 ~ SelectSubstituteComponent ~ copyControl.valueChanges.subscribe ~ copyValue:", copyValue)
                  if (copyValue) {
                    this.model = { ...this.model, ...this.model.cns.residence };
                  }

                });
              }
            }
          },
        ] : []),

        {
          key: 'country',
          type: 'formly-field-select',
          className: 'ic1-col-12 ic1-col-lg-6  ',
          props: {
            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.COUNTRY'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.COUNTRY'],
            options: countryOptions,

            attributes: {
              class: "ic1-input"
            }
          },

          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },
          hooks: {
            onInit: (field) => {
              const countryControl = field.formControl;
              countryControl.valueChanges.subscribe((countryValue) => {
                console.log("🚀 ~ SelectSubstituteComponent ~ countryControl.valueChanges.subscribe ~ countryValue:", countryValue)
                this.stepsFields = this.formFields(this.translations, { country: countryValue });

              });
            }
          }
        },

        {
          key: 'province',
          type: formFieldType,
          className: 'ic1-col-12 ic1-col-lg-6  ',
          props: {
            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PROVINCE'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PROVINCE'],
            options: ProvincesOptions,

            attributes: {
              class: "ic1-input"
            }

          },
          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },

        },

        {
          key: 'city',
          type: "ic1-text",// formFieldType,

          className: 'ic1-col-12 ic1-col-lg-6  ',
          props: {
            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CITY'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.CITY'],
            options: [],

            attributes: {
              class: "ic1-input"
            }
          },
          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },
          /* hooks: {
             onChanges: (field) => {
               const provinceControl = field.parent.get('province').formControl;


               provinceControl.valueChanges.pipe(
                 distinctUntilChanged(),
                 switchMap((province: string) => this.addressService.getComuni(province)),
                 map((cities: any[]) => {
                   return cities?.map((city) => ({ label: city.comune, value: city.comune, })) || [];
                 })
               ).subscribe((newOptions) => {

                 field.props.options = [...newOptions];
                 field.formControl.updateValueAndValidity();
               });
             },
           },*/
        },

        {
          key: 'zipCode',
          type: 'ic1-text',
          className: 'ic1-col-12 ic1-col-lg-6',
          props: {
            onlyNumbers: 5,

            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.ZIP_CODE'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.ZIP_CODE'],
          },
          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },
        },

        {
          key: 'street',
          type: 'ic1-text',
          className: 'ic1-col-8 ',
          props: {
            NoSpecialChars: true,
            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET'],
          },
          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },
        },

        {
          key: 'number',
          type: 'ic1-text',
          className: 'ic1-col-4 ',
          props: {
            NoSpecialChars: true,
            label: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET_NUMBER'],
            placeholder: this.translations['SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET_NUMBER'],
          },
          validation: {
            show: true,
          },
          expressions: {
            'props.required': (form) => {
              //const required = isCNS ? true :  productType === 'SC' ||  productType === 'BK'
              return shippingRequired
            }
          },
        },
      ]
    }]
  }

  get deviceOwnerName() {
    return this.device.displayLabel
  }

  step = 0;


  /**FORMS METHODS */
  form = new UntypedFormGroup({});
  formOptions: FormlyFormOptions = {};


  model: ModelForm = {
    productType: "",
    email: "",
    mobile: "",
    privacy: true,
    terms: true,
    confirmModule: true,
    certType: undefined,
    //TODO STUB
    /*country: "IT",
    city: "rome",
    state: "Italia",
    street: "dasdsa",
    number: "321",
    zipCode: "32123",
    province: "dsadas",
    "cns": {
      "docType": "CI",
      "docNumber": "232",
      "docExpirationDate": "2024-02-16",
      "docReleaseDate": "2024-02-16",
      "residence": {
        "street": "dadas",
        "number": "32132",
        "city": "Alessandria Della Rocca",
        "country": "IT",
        "zipCode": "12345",
        "province": "Agrigento"
      }
    },*/

  }




  stepsFields: { label: string, fields: FormlyFieldConfig[] }[] = []
  translations: any = {};

  formGroup: FormGroup;

  get currentFields(): FormlyFieldConfig[] {
    return this?.stepsFields[this.step]?.fields || [];
  }

  get currentLabel(): string {
    return this?.stepsFields[this.step]?.label || "";
  }




  next() {
    // Se lo step corrente è valido, procedi allo step successivo
    if (this.isCurrentStepValid) {
      if (this.step === 0) {
        //sto andando nello step dei contatti
        setTimeout(() => {
          if (this.initialMobile) {
            //forzo errore sul campo mobile
            this.setFieldError("mobile", { 'mobile': true, 'required': true })
          }
        }, 2000)
      }
      if (this.step + 1 >= this.stepsFields.length) {
        this.submit()
      } else {
        this.step++;
      }
    }

  }


  get isCurrentStepValid() {
    const allValid = this.currentFields.every(field => field.formControl?.valid);
    //console.log("🚀 ~ SelectSubstituteComponent ~ getisCurrentStepValid ~ allValid:", allValid)
    return allValid


  }

  prev() {
    this.step--;
  }


  get values() {
    return this.formGroup.value;
  }

  get totalSteps() {
    return this.stepsFields.length - 1
  }


  get shippingAddress() {
    //Se è COLDIRETTI non mostro l'indirizzo
    if (this.device.owner === "COLDIRETTI") {
      return "";
    }
    const { productType, city = "", country, state, number = "", street = "", zipCode } = this.model;
    if (productType === 'BK' || productType === 'SC') {
      return `${street} ${number}, ${city}, ${zipCode}, ${country}`;
    } else {

      return ""
    }
  }

  get residenceAddress() {
    if (!this.model?.cns?.residence) return "";
    const { country, city, zipCode, street, number } = this.model.cns?.residence || {};
    return `${street} ${number}, ${city}, ${zipCode}, ${country}`;

  }

  submit() {

    if (!this.device) {
      alert("device not found");
      return; // Assicurati di uscire dalla funzione se non c'è il dispositivo
    }
    this.openModal({
      title: this.translateService.instant('SUBSTITUTION.MODAL.TITLE'),
      type: "loading",
      message: this.translateService.instant('SUBSTITUTION.MODAL.MESSAGE'),
    });

    const { signCertificate: { certId, ...certificate }, owner } = this.device;


    const contactsPayload: DikeTransactionPayload = this.preparePayload();

    logger.log("🚀 ~ SelectSubstituteComponent ~ submit ~ contactsPayload:", contactsPayload);

    // Condizione per decidere se chiamare o meno updateContacts
    const shouldUpdateContacts = owner !== "THIRDPARTY";

    iif(
      //se l'owner è THIRDPARTY non chiamo updateContacts
      () => shouldUpdateContacts,
      this.dikerWebService.updateContacts(certId, this.model.email, this.model.mobile),
      of(null) // Se la condizione è falsa, passa a un observable che emette null e completa
    ).pipe(
      switchMap(() => this.dikerWebService.startDikesTransaction(certId, contactsPayload)),
      switchMap(dikeTransaction => this.dikeService.signatureProcess(dikeTransaction)),
      switchMap(() => this.dikeService.getResult()),
      switchMap(response => this.dikerWebService.getDikeResult(certId, response.ioSessionID)),
      catchError(error => {

        return throwError(() => error);
      })
    ).subscribe(response => {
      logger.log('Dike result:', response);
      this.store.dispatch(updateCertificatesStatus());
    }, error => {
      logger.log('Dike error:', error);
      debugger;

      const errorMessage = this.getErrorMessage(error);
      this.updateModal({
        type: "error",
        title: this.translateService.instant('SUBSTITUTION.MODAL.ERROR'),
        message: errorMessage
      });
    });
  }


  preparePayload() {
    const { signCertificate: { certId, ...certificate }, owner } = this.device;
    const { city, country, state, number, street, zipCode, email, mobile, privacy, productType, certType, cns, } = this.model


    let contactsPayload: DikeTransactionPayload = {
      firstName: certificate.given_name,
      lastName: certificate.surname,
      fiscalCode: certificate.cf,
      legalNotice: this.translateService.instant('SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.LEGAL_NOTICE'),

      productType,
      email,
      mobile,

      //Shipping
      street: owner === "COLDIRETTI" ? 'COLDIRETTI' : cleanPayload(street).trim(),
      number: cleanPayload(number).trim(),
      city: owner === "COLDIRETTI" ? 'COLDIRETTI' : cleanPayload(city).trim(),
      country: owner === "COLDIRETTI" ? 'COLDIRETTI' : country,
      zipCode: zipCode,


    };

    if (owner === "THIRDPARTY") {

      contactsPayload = {
        ...contactsPayload,
        certType: this.model.certType
      }

      if (certType === "CNS") {
        //@ts-ignore
        delete cns.residence.province;
        contactsPayload = {
          ...contactsPayload,
          cns
        }
      }

    }

    return contactsPayload
  }

  getErrorMessage(error) {
    let errorMessage = error.statusText || error?.message || error;



    errorMessage = this.translateService.instant('DIKE_ERRORS.' + errorMessage) || errorMessage


    return errorMessage
  }

  modalTitle: string = "";
  modalMessage: string = ""

  public openModal(data: ModalData = {}) {

    this.updateModal(data)
    //if (!this?.subsModal?.isOpen) {
    this.subsModal.openModal();
    //}
  }

  updateModal(data: ModalData) {
    this.modalTitle = data.title;
    this.modalMessage = data.message;
    this.subsModal.setData(data);
  }

  public closeModal() {

    this.subsModal.closeModal();

  }

  onCloseModal($event = undefined) {
    //console.log("🚀 ~ onCloseModal ~ $event:", $event)


  }
  onConfirmModal($event = undefined) {
    //console.log("🚀 ~ onConfirmModal ~ $event:", $event)
  }


  fieldValid(form) {
    const required = form.model.productType === 'SC' || form.model.productType === 'BK'
    return !!required
  }


  get prices() {
    //https://jira.infocert.it/browse/CADIKE-5272
    /*
Dinamicità si, ma limitata, solo due "batterie" di prezzi:

3 prezzi per "BK", "Smartcard" e "Remota" per utente CNSv2 con owner "Infocert" e certificato di firma che scade PRIMA di Giugno 2024
3 prezzi "BK", "Smartcard" e "Remota" per utente CNSv2 con owner "Infocert" e certificato di firma che scade DOPO Giugno 2024
Ovviamente ci sarà un piccolo testo dedicato al "caso CNSv2" da inserie in testa alla pagina bbello rosso fuoco visbile del tipo "Vi informiamo che il giorno 17 aprile 2024 il certificato di firma a bordo di questo dispositivo sarà revocato"

Rimane tutto uguale per i 3 prezzi relativi alla sostituzione delle altre carte deprecate già gestite (JSign3, JSign1, Cosmo, ecc ecc)
*/

    let priceRange: "STANDARD" | "CNS2_BEFORE_JULY_2024" | "CNS2_AFTER_JULY_2024" = 'STANDARD';

    let isPromo = false;
    const { owner, deviceModel, signCertificate } = this.device
    if (deviceModel.name === "CNS v2" && owner === "INFOCERT") {
      console.log("signCertificate.expires", signCertificate.expires.format('DD-MM-YYYY'));
      if (signCertificate.expires.isBefore('2024-07-01')) {
        priceRange = "CNS2_BEFORE_JULY_2024"
        isPromo = true;
      } else {
        isPromo = false;
        priceRange = "CNS2_AFTER_JULY_2024"
      }
    }
    if (owner === "COLDIRETTI") {
      isPromo = true;
    }
    if (owner === "THIRDPARTY") {
      //TODO: Gestire i prezzi per THIRDPARTY
      priceRange = "STANDARD";
    }
    return {
      'BK': this.translateService.instant(`SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRICES.${priceRange}.BK`),
      'SC': this.translateService.instant(`SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRICES.${priceRange}.SC`),
      'REMOTE_SIGN': this.translateService.instant(`SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.PRICES.${priceRange}.REMOTE_SIGN`),
      isPromo
    }
  }

  get ModelDebug() {
    return this.model
  }

  setFieldError(fieldName: string, errors: any = {}) {
    const fullNameField = this.form.get(fieldName);
    console.log("🚀 ~ SelectSubstituteComponent ~ setFieldError ~ fieldName:", fieldName)
    //fullNameField.setErrors({ 'mobile': true, 'required': true });
    fullNameField.setErrors(errors);
    fullNameField.markAsTouched();
    fullNameField.markAsDirty();
    this.form.updateValueAndValidity();
  }

}
