import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { LoggerService } from '@app/services/logger.service';

import { getDevices } from '@app/store/actions/devices.actions';

import { Device } from '@app/store/models';
import { deviceLoaded, getDeviceError, isDeviceLoading } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState, resetGlobalState } from '@app/store/store';

import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
const logger = new LoggerService("SelectDeviceComponent");
@Component({
  selector: 'app-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.scss']
})
export class SelectDeviceComponent implements OnInit, AfterViewInit {


  autoSelectDeviceATR: string = ""
  autodeviceStarted: boolean = false

  isDeviceLoading: boolean = false
  constructor(

    private store: Store<GlobalState>,
    private router: Router,
    private route: ActivatedRoute,

  ) {

    //this.store.dispatch(removeDevices())
    this.store.dispatch(resetGlobalState())


    combineLatest([
      this.route.queryParams,
      this.store.select(isDeviceLoading),
      this.store.select(deviceLoaded),

    ]).subscribe(([queryParams, isDeviceLoading, deviceLoaded]) => {
      this.isDeviceLoading = isDeviceLoading
      const deviceATR = localStorage.getItem("deviceATR") || undefined;
      //console.log({ queryParams, isDeviceLoading, deviceLoaded })
      if (!this.autodeviceStarted && !isDeviceLoading && !deviceLoaded && deviceATR) {
        //Se arriva il parametro deviceID, faccio il dispatch di getDevices e poi lo passo al componente app-device-select
        this.autoSelectDeviceATR = deviceATR

        localStorage.removeItem("deviceATR")
        if (this.autoSelectDeviceATR) {


          this.autodeviceStarted = true
          this.store.dispatch(getDevices())
        }
      }
    })

  }

  get disabledButton(): boolean {
    return this.isDeviceLoading
  }

  $deviceErrorMessage: Observable<string> = of("")


  $deviceLoaded: Observable<boolean> = of(false)




  $deviceSelected: Observable<Device> = of(undefined)

  $STATE: Observable<GlobalState> = of(undefined);

  ngOnInit(): void {

    this.$STATE = this.store.select(state => state)

    this.$deviceErrorMessage = this.store.select(getDeviceError)
    this.$deviceLoaded = this.store.select(deviceLoaded)
    this.$deviceSelected = this.store.select(selectDevice)



  }


  ngAfterViewInit(): void {


  }


  async getDevices() {


    this.store.dispatch(getDevices())


  }


  goBack() {
    this.router.navigate([ROUTE_PATHS.selectDevice], { relativeTo: this.route });
  }


}
