import { DikeSession } from '@app/models/dike.interface';
import { AppModels, Device } from '@app/store/models';
import { Customization } from '@app/store/models/customization.models';
import { customizationReducer } from '@app/store/reducers/customization.reducer';
import { deviceReducer } from '@app/store/reducers/device.reducer';
import { devicesReducer } from '@app/store/reducers/devices.reducer';
import { sessionReducer } from '@app/store/reducers/session.reducer';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { appReducer } from './reducers/app.reducers';

export interface GlobalState {
  session: DikeSession;
  devices: Device[];
  device: Device;
  customization: Customization;
  app: AppModels;
}

export const initialStore: GlobalState = {
  devices: [],
  session: undefined,
  device: undefined,
  customization: { owner: "INFOCERT" },
  app: {
    deviceLoading: false,
    deviceLoaded: false,
    mainLoading: false,
    loadingMessage: "",
    deviceError: "",
    currentFlow: undefined,
    currentStep: undefined,
    mainStep: 'select_device',
    deviceSelected: false,

  }
};

export const STORE: ActionReducerMap<GlobalState> = {
  session: sessionReducer,
  customization: customizationReducer,
  device: deviceReducer,
  devices: devicesReducer,
  app: appReducer
};

// Metariduttore per il reset dello stato
export function resetStateReducer(reducer: ActionReducer<GlobalState>): ActionReducer<GlobalState> {
  return (state, action) => {
    if (action.type === 'RESET_GLOBAL_STATE') {
      //console.log("RESET GLOBAL STATE")
      state = { ...initialStore, device: undefined };
    }
    //console.log("🚀 ~ return ~ state:", state)
    return reducer(state, action);
  };
}

export function resetGlobalState() {
  return { type: 'RESET_GLOBAL_STATE' };
}

export const metaReducers: MetaReducer<GlobalState>[] = [resetStateReducer];

export default STORE;
