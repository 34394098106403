import { Component } from '@angular/core';
import { Observable, filter, map, mergeMap } from 'rxjs';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ROUTE_PATHS } from './app-routing.module';
import { AppLayoutType } from './layout/routes.layouts';
import { OwnersType } from './store/models';
import { selectOwner } from './store/selectors/customization.selectors';
import { GlobalState } from './store/store';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  layout$ = this.getLayoutType$()

  currentUrl: string = '';
  $mainOwner!: Observable<OwnersType>


  readonly AppLayoutType = AppLayoutType

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<GlobalState>
  ) { }

  get isDebug(): boolean {
    return window.dikeDebug.isDebug;
  }

  deviceATR: string = ""

  // get classes(): string {
  //   return `${this.currentUrl} ${this.isDebug ? 'isDebug' : ''}`;
  // }

  get classes(): Observable<string> {
    return this.$mainOwner.pipe(
      map(mainOwner => `${this.currentUrl} ${this.isDebug ? 'isDebug' : ''} ${mainOwner}`)
    );
  }


  ngOnInit(): void {
    this.$mainOwner = this.store.select(selectOwner)

  }

  private getLayoutType$(): Observable<AppLayoutType> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {


        const curUrl = new URL(event.url, window.location.href);
        const params = new URLSearchParams(curUrl.search);

        const deviceATR = params.get("deviceATR") || undefined;

        if (deviceATR) {
          this.deviceATR = deviceATR;
          localStorage.setItem("deviceATR", deviceATR)

          setTimeout(() => {

            this.router.navigate([ROUTE_PATHS.checkDevice]);
          }, 1000)
        }
        const url = event.urlAfterRedirects;
        this.currentUrl = url.replace(/\//g, ' ').trim();

        return this.activatedRoute
      }),
      map((route) => {
        //console.log("🚀 ~ AppComponent ~ map ~ route:", route)
        while (route.firstChild) {
          route = route.firstChild
        }
        return route
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      map(({ layout }) => layout)

    );
  }



}
