<ngx-smart-modal #modal [identifier]="modalId" class="smart-modal" [ngClass]="type"
  (onAnyCloseEvent)="onAnyCloseEvent()" [dismissable]="false">
  <!-- <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
  </div> -->
  <div class="modal-body">
    <!-- ICON -->
    <div class="modalIcon" *ngIf="showIcon">
      <div class="icon-badge" [ngClass]="type">
        <ng-ui-kit-icon [iconStatus]="type" size="xl"> </ng-ui-kit-icon>
      </div>
    </div>

    <!-- LOADING -->
    <app-loading *ngIf="showLoading" size="xl">
    </app-loading>

    <h2 class="modal-title" [innerHTML]="title"></h2>

    <p class="modal-message" [innerHTML]="message"> </p>

    <!-- CUSTOM CONTENT -->
    <ng-content></ng-content>

    <formly-form *ngIf="hasForm" [model]="model" [fields]="formFields" [form]="form"
      [options]="formOptions"></formly-form>

    <!-- COUNTDOWN REDIRECT -->
    <div *ngIf="countdown && redirectUrl">
      <div class="ic1-my-20"> Se non vuoi attendere, <button type="button" class="ic1-btn ic1-btn-sm ic1-btn-secondary"
          (click)="goRedirect()"> Vai all'ecommerce </button>
      </div>
      <div class="ic1-my-20">
        <span translate="GO_PAYMENT.ECOMMERCE.MODAL_COUNTDOWN"></span>
        <div>tra {{seconds}} secondi</div>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="showFooter">
    <div class="buttons-row">
      <button type="button" class="ic1-btn ic1-btn-sm ic1-btn-secondary" (click)="closeModal()">
        Annulla
      </button>
      <button type="button" class="ic1-btn ic1-btn-sm ic1-btn-primary" (click)="confirmModal()"
        [disabled]="disabledProceed">
        Conferma
      </button>
    </div>
  </div>
</ngx-smart-modal>
