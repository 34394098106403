import { Component } from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { Certificate, Device } from '@app/store/models';
import { AppStep } from '@app/store/models/app.models';
import { getCurrentStep } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

const logger = new LoggerService("EolComponent");
@Component({
  selector: 'app-renew',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.scss']
})
export class RenewComponent {


  onCertsSelected(certificates: Certificate[]) {
    logger.log("🚀 ~ EolComponent ~ onCertSelected ~ certificates:", certificates)

  }


  $device: Observable<Device>;


  $currStep: Observable<AppStep | undefined> = of(undefined);

  constructor(private store: Store<GlobalState>) {

  }



  ngOnInit(): void {

    this.$device = this.store.select(selectDevice)
    this.$currStep = this.store.select(getCurrentStep)

  }

}
