import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ic1-checkbox',
  template: `
   <div class="formly-checkbox flex-row">
      <ng-ui-kit-checkbox
          [icId]="id"
          [formControl]="formControl"
          [formlyAttributes]="field" ></ng-ui-kit-checkbox>
      <div class="checkbox-label" (click)="openUrl(props['url'])">{{props.label}}</div>
    </div>
  `,
  styleUrls: ['./form-styles.scss'],
  styles: [`

  `],
})
export class CheckboxInputType extends FieldType<FieldTypeConfig> {

  openUrl(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }


}
