import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss']
})
export class StatusCardComponent {
  @Input() type: "success" | "error" | "warning" | "info" = "info";
  @Input() title: string | null = null;
  @Input() content: string | null = null;
}
