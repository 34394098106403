
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OwnersType } from '@app/store/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomizationTranslationService {

  constructor(private translateService: TranslateService, private http: HttpClient) { }

  public setTranslation(customization: OwnersType) {
    console.log("🚀 ~ CustomizationTranslationService ~ setTranslation ~ customization:", customization)
    // Effettua la richiesta HTTP per ottenere le traduzioni sovrascritte
    this.http.get<any>(`./assets/i18n/${customization}.json`).subscribe(
      translations => {
        // Sovrascrivi solo alcuni valori delle traduzioni
        this.translateService.setTranslation(customization, translations, true);
      },
      error => {
        console.error('Errore nel caricamento delle traduzioni sovrascritte', error);
      }
    );

    // Imposta la lingua corrente
    this.translateService.use(customization);
  }
}
