import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
@Component({
  selector: 'formly-field-ic1-input',
  template: `
    <ng-ui-kit-input
      [type]="type"
      [label]="this.props.label"
      [formControl]="formControl"
      [formlyAttributes]="field"></ng-ui-kit-input>
  `,
})
export class InputPassworddType extends FieldType<FieldTypeConfig> {
  get type(): any {
    return this.props.type || 'password';
  }
}
