import { Certificate, Device, OwnersType } from '@app/store/models';
import { CertStatus, CertStatusArray, CertType, RawCertificate, RenewStatusResponse, RenewableDetails, certTypes } from '@app/store/models/certificate.model';
import { find, pick } from 'lodash';

import * as moment from 'moment';
import { getExpirationDate, isExpiredDate, isExpiredMoreThanXdays } from './helpers';


export const formatCertificate = (rawCertificate: Certificate, device: Device): Certificate => {
  //console.log("🚀 ~ formatCertificate ~ rawCertificate:", rawCertificate)
  //console.count("formatCertificate")

  //const device = device;

  const certId = rawCertificate.id;
  const type: CertType = 'UNKN'; // inizialmente la tipologia non la conosco (viene chiesta in seguito al BE)
  const humanType = getHumanType(type);

  let validityTo = rawCertificate.cert.validity.to;

  if (window.dikeDebug.expiredDate) {
    const [year, month, day] = window.dikeDebug.expiredDate.split('-');
    validityTo = `${year}${month}${day}000000`;
    console.log("🚀 ~ formatCertificate ~ CUSTOM validityTo:", validityTo)
  }

  // la data che dike-desktop mi restituisce è in formato GMT0
  let expires = getExpirationDate(validityTo);
  console.log("🚀 ~ formatCertificate ~ expires:", expires)

  const isExpired = isExpiredDate(expires);
  const isExpiredMoreThan90days = isExpiredMoreThanXdays(expires, 90);
  const expireDate = expires.format("DD/MM/YYYY")
  const isExpiringIn3Months = !!expires.isBetween(moment(), moment().add(3, "months"))
  const renovableFromDate = expires.clone().subtract(90, 'days').format('DD/MM/YYYY');

  const cf = getInfoByKey(rawCertificate, 'icGetCodFisc');

  /*
Siccome nel caso di certificati AUT e CNS i valori di icGetFirstName e icGetLastName
possono risultare errati, vado ad estrarre il given name e surname dalla property icGetSubjectDN
per calcolare poi il fullName
 */
  //this.fullName = this.getInfoByKey("icGetFirstName")+' '+this.getInfoByKey("icGetLastName");
  var subjectDN = getInfoByKey(rawCertificate, 'icGetSubjectDN');
  const given_name = subjectDN
    .replace(/^.*(GN=)/, '')
    .replace(/\/.*$/, '');
  const surname = subjectDN.replace(/^.*(SN=)/, '').replace(/\/.*$/, '');
  const fullName = given_name + ' ' + surname;

  const policyId = getInfoByKey(rawCertificate, 'icGetCertificatePolicies');
  const title = getInfoByKey(rawCertificate, 'icGetTitle');

  const email = subjectDN
    .replace(/^.*(emailAddress=)/, '')
    .replace(/\/.*$/, '');
  const mobile = subjectDN
    .replace(/^.*(mobile=)/, '')
    .replace(/\/.*$/, '');

  const renewStatus = {
    data: null,
    status: '',
    err: null,
  };

  const isSignCertificate = rawCertificate.cert && rawCertificate.cert.nonrepudiation === 'true';

  const cns_pdata = getCnsData(device);


  const certificate: Certificate = {
    ...rawCertificate,
    certId,
    type,
    humanType,
    expires,
    isExpired,
    isExpiredMoreThan90days,
    isExpiringIn3Months,
    expireDate,
    renovableFromDate,
    cf,
    given_name,
    surname,
    fullName,
    policyId,
    title,
    email,
    mobile,
    renewStatus,
    isSignCertificate,
    cns_pdata,
    device: pick(device, ["serial", "atr", "isHealthCard", "deviceModel"]),
    status: undefined,
    hasP10: undefined,
  }


  return certificate;


}

export const getInfoByKey = function (certificate: RawCertificate, key: string) {
  var item;
  try {
    item = find(certificate.cert.info, function (item: any) {
      return item.k == key;
    });
  } catch (e) { }

  return item ? item.v : '-';
};

export function getHumanType(certType: CertType) {
  return certTypes[certType] || certTypes.UNKN;
}

const getCnsData = (device: Device) => {
  let cns_pdata = undefined;
  if (device.CNS_PDATA) {
    //Salto il campo lunghezza totale (6 caratteri)
    var pIndex = 6;
    //Salto il campo emettitore (variabile)
    pIndex +=
      2 +
      parseInt('0x' + device.CNS_PDATA.slice(pIndex, pIndex + 2));
    //Salto il campo data emissione (variabile)
    pIndex +=
      2 +
      parseInt('0x' + device.CNS_PDATA.slice(pIndex, pIndex + 2));

    var cns_pdata_len = parseInt(
      '0x' + device.CNS_PDATA.slice(pIndex, pIndex + 2)
    );
    pIndex += 2;

    if (cns_pdata_len == 8) {
      let _cns_pdata = device.CNS_PDATA.slice(
        pIndex,
        pIndex + cns_pdata_len
      );

      var day = parseInt(_cns_pdata.slice(0, 2));
      var month = parseInt(_cns_pdata.slice(2, 4));
      var year = parseInt(_cns_pdata.slice(4, 8));
      // attenzione perchè l'oggetto Date in javascript ha i mesi che vanno da 0 a 11
      cns_pdata = new Date(year, month - 1, day);
    } else {
      console.warn('No P_DATA found');
    }
  }

  return cns_pdata;
}


export const getOwnerFromCertificates = (certificates: Certificate[]): OwnersType => {

  if (window.dikeDebug?.owner) {
    return window.dikeDebug.owner as OwnersType;
  }
  const signCertificate = certificates?.find(cert => cert.cert.nonrepudiation === 'true')
  const owner: OwnersType = signCertificate?.owner || 'UNKN' as OwnersType;

  if (owner === "UNKN" && certificates?.length === 1 && certificates?.[0]?.device?.isHealthCard) {
    return "TESSERA SANITARIA"
  }

  return owner
}

export const getCertificateStatus = (renewResponse: RenewStatusResponse): CertStatus => {

  const { renewRequest: { status } } = renewResponse;

  const foundStatus = CertStatusArray.find(certStatus => status.includes(certStatus))

  return foundStatus || CertStatus.EMPTY

}


export const getTrueBooleanKeyFromCertificate = (certificate: Certificate) => {

  const { renewableDetails = {} } = certificate;
  /*
  roleCertificateAbilitationError: boolean;
  expiredWithoutP10Error: boolean;
  pDataCNSCheckError: boolean;
  only1024SmartCardError: boolean;
  carteCamerali2014Error: boolean;
  coungruentState: boolean;
 */

  //exclude congruentState and pDataCNSCheckError CADIKE-6074 from array
  const { coungruentState, pDataCNSCheckError, ...renewableDetailsErrors } = renewableDetails as RenewableDetails;


  // non è rinnovabile controllo gli altri
  const foundTrueBool = Object.keys(renewableDetailsErrors).find(key => renewableDetailsErrors[key] === true);



  /*
    roleCertificateAbilitationError: boolean;
    expiredWithoutP10Error: boolean;
    pDataCNSCheckError: boolean;
    only1024SmartCardError: boolean;
    carteCamerali2014Error: boolean;
    coungruentState: boolean;
  */

  return foundTrueBool || "";
}

