import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsManagerService } from '@app/services/actions-manager.service';
import { setCurrentFlow } from '@app/store/actions/app.actions';
import { checkDevicePossibleActions, setDevice } from '@app/store/actions/device.actions';
import { OwnersType, allOwners } from '@app/store/models';
import { AppStepsTypes, MainStep, SubstituteStepsArray } from '@app/store/models/app.models';
import { GlobalState } from '@app/store/store';
import { STUB_CUSTOM_DEVICE } from '@app/stubs/stub-devices';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-debug-menu',
  templateUrl: './debug-menu.component.html',
  styleUrls: ['./debug-menu.component.scss'],
  //standalone: true,

  //imports: [CdkDrag, FormsModule, NgxJsonViewerModule, AsyncPipe],
})
export class DebugMenuComponent implements OnInit, AfterViewInit {

  public showMenu: boolean = false;
  public closed: boolean = false;
  @ViewChild('debuMenu') debuMenu: ElementRef<HTMLDivElement>;

  $STATE: Observable<any> = of(undefined);

  mainStep: MainStep = "renew"
  constructor(private router: Router, private store: Store<GlobalState>, private actionsManagerService: ActionsManagerService) {

    this.$STATE = this.store.select((state) => state);


  }

  get isDebug(): boolean {
    return !!window.dikeDebug.isDebug;
  }

  set isDebug(value: boolean) {
    window.dikeDebug.isDebug = value;
  }

  get forceAtr(): string {
    return window.dikeDebug.forceAtr;
  }

  set forceAtr(value: string) {
    window.dikeDebug.forceAtr = value;
  }

  get owner(): OwnersType {
    return window.dikeDebug.owner;
  }

  set owner(value: OwnersType) {

    window.dikeDebug.isDebug = window.dikeDebug.enableCosmo = true;
    window.dikeDebug.owner = value as OwnersType;
  }

  get enableCosmo(): boolean {
    return !!window.dikeDebug.enableCosmo;
  }

  set enableCosmo(value: boolean) {
    window.dikeDebug.enableCosmo = value;
  }
  get expiredDate(): string {
    return window.dikeDebug.expiredDate;
  }

  set expiredDate(value: string) {
    window.dikeDebug.expiredDate = value
  }

  get stubDevice(): boolean {
    return !!window.dikeDebug.stubDevice;
  }
  set stubDevice(value: boolean) {
    window.dikeDebug.stubDevice = value;
  }

  get skipContactsError(): boolean {
    return !!window.dikeDebug.skipContactsError;
  }
  set skipContactsError(value: boolean) {
    window.dikeDebug.skipContactsError = value;
  }


  get currentStepType(): AppStepsTypes {
    return window.dikeDebug.currentStepType;
  }
  set currentStepType(nextStep: AppStepsTypes) {

    //window.dikeDebug.enableCosmo =
    window.dikeDebug.isDebug = true;

    window.dikeDebug.currentStepType = nextStep;

    const isRenew = this.mainStep === "renew";

    const currentFlow = this.actionsManagerService.APP_STEPS[this.mainStep];
    const currentStep = currentFlow.find(s => s.step === nextStep);
    this.store.dispatch(setCurrentFlow({ currentFlow, currentStep, mainStep: this.mainStep }));

    //this.router.navigate([ROUTE_PATHS[this.mainStep]]);

    let device = STUB_CUSTOM_DEVICE({
      appStep: nextStep,
      owner: this.owner,
      isRenewable: isRenew,
      renewableDetails: {
        roleCertificateAbilitationError: false,
        expiredWithoutP10Error: false,
        pDataCNSCheckError: false,
        only1024SmartCardError: false,
        carteCamerali2014Error: false,
        coungruentState: false,
      },
    });
    console.log("🚀 ~ DebugMenuComponent ~ setcurrentStepType ~ device:", device)

    this.store.dispatch(setDevice(device));

    setTimeout(() => {

      // this.store.dispatch(updateCertificatesStatus());
      this.store.dispatch(checkDevicePossibleActions());
    }, 500)
  }

  go(path: string) {

    this.router.navigate(['/substitution/' + path], { queryParamsHandling: 'preserve' });
  }
  stepsArray = SubstituteStepsArray
  ownersArray = allOwners

  count = 0;
  timer = null;

  ngOnInit() {

    window.addEventListener('keydown', (e) => {
      // check if is pressed shift + d
      if (e.shiftKey && e.key === 'D') {
        clearTimeout(this.timer);
        this.count++;
        this.timer = setTimeout(() => {
          this.count = 0;
        }, 300);

        if (this.count === 3) {
          console.log('Il tasto "Shift + d" è stato premuto tre volte consecutivamente!');
          this.count = 0;
          this.toggleMenu(true);
        }
      } else {
        this.count = 0;
      }
    });


  }

  ngAfterViewInit() {
    const debugMenu = this.debuMenu.nativeElement;


    let offsetX = 0;
    let offsetY = 0;

    // Gestore per l'evento di inizio del trascinamento
    debugMenu.addEventListener('dragstart', (event: DragEvent) => {
      //console.log("🚀 ~ DebugMenuComponent ~ dragstart:", event);
      event.dataTransfer.setData('text/plain', '');

      offsetX = event.clientX - debugMenu.offsetLeft;
      offsetY = event.clientY - debugMenu.offsetTop;
    });

    // Gestore per l'evento di fine del trascinamento
    debugMenu.addEventListener('dragend', (event: DragEvent) => {
      //console.log("🚀 ~ DebugMenuComponent ~ dragend:", event);

      const left = event.clientX - offsetX;
      const top = event.clientY - offsetY;
      debugMenu.style.left = left + 'px';
      debugMenu.style.top = top + 'px';
    });

    document.getElementById('appRoot').addEventListener('dragover', (event) => {
      //console.log("🚀 ~ DebugMenuComponent ~ appRoot ~ dragover:", event)
      event.preventDefault(); // Impedisce il comportamento predefinito (tornare alla posizione originale)
    });



  }

  toggleMenu(active: boolean = !this.showMenu) {
    this.showMenu = active
    this.closed = false;
  }



}
