import { GlobalState } from '../store';


export const selectApp = (state: GlobalState) => state.app;


export const isMainLoading = (state: GlobalState) => !!state?.app?.mainLoading;
export const isDebug = (state: GlobalState) => !!state?.app?.isDebug;

export const isDeviceLoading = (state: GlobalState) => !!state?.app?.deviceLoading;
export const deviceLoaded = (state: GlobalState) => !!state?.app?.deviceLoaded;

export const getLoadingMessage = (state: GlobalState) => state?.app?.loadingMessage || "";

export const getDeviceError = (state: GlobalState) => state?.app?.deviceError || "";

export const getCurrentFlow = (state: GlobalState) => state?.app?.currentFlow || [];

export const getCurrentStep = (state: GlobalState) => state?.app?.currentStep;

export const getCurrentFlowAndStep = (state: GlobalState) => {
  const { currentFlow, currentStep, mainStep = currentStep?.mainStep } = state?.app || {};
  return { currentFlow, currentStep, mainStep };
};

export const getDeviceATR = (state: GlobalState) => state?.app?.deviceATR || "";
