import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { toggleMainLoading } from '@app/store/actions/app.actions';
import { Device } from '@app/store/models';
import { MainStep } from '@app/store/models/app.models';
import { RenewTypeResponse } from '@app/store/models/certificate.model';
import { isMainLoading } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState, resetGlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

const logger = new LoggerService("CertInstalledComponent");

@Component({
  selector: 'app-cert-installed',
  templateUrl: './cert-installed.component.html',
  styleUrls: ['./cert-installed.component.scss']
})
export class CertInstalledComponent implements OnInit {
  @Input() device!: Device;

  @Input() mainStep!: MainStep;
  $mainLoading: Observable<boolean> = of(true)

  cosmoContacts: RenewTypeResponse = undefined

  warningMessage?: string = "";

  constructor(private router: Router, private dikerWebService: DikerWebService, private store: Store<GlobalState>, private translateService: TranslateService) {
  }

  ngOnInit(): void {

    this.$mainLoading = this.store.select(isMainLoading);


    if (this.mainStep === 'substitution') {
      this.store.dispatch(toggleMainLoading({ showLoading: true }));
      if (this.device) {

        this.getRenewType(this.device.signCertificate.certId)
      } else {
        this.store.select(selectDevice).subscribe(device => {
          if (device) {
            this.device = device

            this.getRenewType(device.signCertificate.certId)

          } else {

            this.store.dispatch(toggleMainLoading({ showLoading: false }));
            this.store.dispatch(resetGlobalState())
            this.router.navigate([ROUTE_PATHS.home]);
          }
        })
      }
    }

  }

  getRenewType(certId) {

    this.dikerWebService.checkRenewType(certId).subscribe(
      {
        next: (cosmoContacts) => {

          this.cosmoContacts = cosmoContacts
          //this.cosmoContacts.coupon = "FAKE_COUPON"
          logger.log("🚀 ~ CertInstalledComponent ~ this.dikerWebService.checkRenewType ~ cosmoContacts:", cosmoContacts)

          if (this.cosmoContacts?.coupon && this.cosmoContacts?.type === "DECLINED") {

            const errorMessage = this.translateService.instant('ERRORS.CONTACTS.STATUS_412', { coupon: this.cosmoContacts.coupon });
            this.warningMessage = errorMessage
          }
          this.store.dispatch(toggleMainLoading({ showLoading: false }));
        },
        error: (error) => {

          this.store.dispatch(toggleMainLoading({ showLoading: false }));
        },
        complete: () => {
          this.store.dispatch(toggleMainLoading({ showLoading: false }));
        }
      })
  }


}
