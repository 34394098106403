import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyConfig } from '@ngx-formly/core';
@Component({
  selector: 'formly-field-select',
  styleUrls: ['./form-styles.scss'],
  template: `
    <div  >
    <label class="formLabel">{{field.props.label}} <span *ngIf="field.props.required">*</span></label>
   <ng-select bindLabel="label" [items] = "items"  [multiple]="false" [clearable]="false"
   [formControl]="formControl" [formlyAttributes]="field" [placeholder]="field?.props?.placeholder || field.props.label">
   </ng-select>
    <div *ngIf="showError" class="ic1-input-disclaimer errorLabel">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  `,
})
export class SelectFieldType extends FieldType<FieldTypeConfig> {
  constructor(private config: FormlyConfig) {
    super();
  }

  get items(): any {

    return this?.field?.props?.options || [];
  }

  selectItem: any;
}
