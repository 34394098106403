import { DeviceModel } from '@app/store/models/device.model';


export const CosmoV7Device: DeviceModel = {
  name: 'Cosmo v7.0',
  //3bff1800008131fe45006b11050700012101434e531031804a Cosmo v7.0
  //3bff1800008131fe55006b11050700012101434e531031805a WirelessKey
  ATRRegex:
    '3bff1800008131fe[4,5]5006b11050700012101434e53103180[4,5]a',
  isRenewable: false,
  showWarning: true,
  isDeprecated: true,
};

export const DeviceATRModels: DeviceModel[] = [
  // {
  //   name: 'Magico Test',
  //   ATRRegex:
  //     '3bff1800008131fe55006b02091614010101434e5310318060',
  //   isRenewable: false,
  //   showWarning: true,
  //   isDeprecated: true,
  // },
  CosmoV7Device,
  {
    //Come comso v7
    name: 'CNS v2',
    //3bff1800008131fe45006b05052000012101434e5310318079
    ATRRegex: '3bff1800008131fe45006b05052000012101434e5310318079',
    isRenewable: false,
    showWarning: true,
    isDeprecated: true,
  },
  {
    name: 'JSign v4',
    //JSIGN4
    // 3bff1800ff8131fe55006b02091717011101434e531131808c
    // 3bff1800ff8131fe55006b02090717011101434e531131808c

    // JSIGN2 con  driver bit4id
    // 3bff1800008131fe55006b02090503010101434e5310318064

    // jsign2 con driver safedive
    // 3bff1800008131fe55006b02091503010101434e5310318074

    //ATR JSIGN4 + JSIGN2
    ATRRegex: '3bff1800.{2}8131fe55006b0209(15|17|05|07).{2}01.{2}01434e53.{2}3180.{2}',
    //ATR SOLO PER JSIGN4
    //ATRRegex:      '3bff1800.{2}8131fe55006b0209(17|07).{2}01.{2}01434e53.{2}3180.{2}',
    isRenewable: true,
    showWarning: false,
    isDeprecated: false
  },
  {
    name: 'JSign v3',
    //3bff1800008131fe55006b020906__010101434e53103180__
    //3bff1800008131fe55006b020916__010101434e53103180__
    ATRRegex: '3bff1800008131fe55006b020916.*010101434e53103180.*',
    isRenewable: false,
    showWarning: true,
    isDeprecated: true,
  },
  // {
  //   name: 'JSign v2',
  //   ATRRegex:
  //     '3bff1800.{2}8131fe55006b0209(15|05).{2}01.{2}01434e53.{2}3180.{2}',
  //   isRenewable: false,
  //   showWarning: true,
  //   isDeprecated: true,
  // }
];

export const GenericDeviceModel: DeviceModel = {
  name: 'GenericDevice',
  ATRRegex: '.*',
  isRenewable: false,
  showWarning: true,
  isDeprecated: true
};


export const downloadP7Mcertificate = (base64Data: string) => {
  const linkSource = `data:application/pkcs7-mime;base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = 'contratto.pdf.p7m';
  downloadLink.click();
};
