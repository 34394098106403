import { Device } from '@app/store/models/device.model';

export type DikeCommands = 'WAKEUP' | 'WAKEUP_S' | 'SYS_SNAPSHOT' | 'GEN_P10' | 'CERT_INSTALL' | 'COSMO_GET' | 'SIGN_HASH';


export interface DikeSession {
  ioSessionID: string;
  jDikeSessionID?: string
}


export interface DikeMessages {
  messages: DikeMessage[];
  remaining: number;
}

export interface DikeMessage {
  msgId: string;
  replyToId: string;
  msgName: string;
  msgBody: string;
  data?: DikeMessageData
}

export interface DikeMessageData {
  failCode: string;
  success: boolean;
  ts: string;
  SYS_SNAPSHOT?: SYS_SNAPSHOT

}

export interface SYS_SNAPSHOT {
  devices: Device[];
  dike: {
    version: string;
  }
  system: {
    platform: string;
  }
}


export enum dikesStatus {
  PENDING = 'PENDING',
  CERTS_READY = 'CERTS_READY',
  HASH_READY = 'HASH_READY',
  HASH_SENT = 'HASH_SENT',
  SIGN_READY = 'SIGN_READY',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR',
};



export const DikeErrors = {
  AUT001: 'OTP invalido',
  AUT002: 'Valore header non corrispondente',
  VAL001: 'Errore validazione',
  VER001: 'Verifica della firma fallita', // JDesign XML Report says o
  VER002: 'Utente non trovato nel certificato',
  VER003:
    "Le informazioni dell'utente nel certificato non corrispondono con quelle inserite nella richiesta",
  VER004: 'Il file originale e firmato non corrispondono',
  VER005: 'Errore nella firma del certificato',
  VER006: "Certificato dell'utente non trovato",
  VER007: "Impossibile leggere le informazioni dell'utente nel certificato",
  VER008: 'Tipo di certificato invalido',
  VER009: 'Impossibile codificare il certificato',
  DKE001:
    "Errore nell'inizializzazione della transazione DikeS per il certificato",
  DKE002: "Errore nell'inizializzazione della transazione DikeS",
  DKE003: 'Errore nel recupero dello stato di DikeS',
  DKE004: 'Errore nel recupero dello stato di DikeS',
  TRA001: 'Transazione inconsistente',
  TRA002: 'Stato della transazione invalido', // per errori relativi a stati errati della transazione per l'operazione che si sta richiedeno
  TRA003: 'Transazione scaduta',
  TRA004: 'Transazione non trovata',
  TRA005: 'Transazione già esistente',
  TRA006: 'Tipo di transazione non valido',
  TRA007: 'Motodo della tranzazione invalido',
  TRA008: 'Violazione della configurazione', // per errori dovuti a comportamenti non sonsentiti dettati dal flow configuratin
  FLS001: 'Error on transaction directory creation',
  FLS002: 'Errore nella creazione del PDF per la transazione',
  FLS003: 'Errore nella lettura del PDF per la transazione',
  FLS004: 'Errore nella lettura del PDF firmato per la transazione',
  FLS005: 'Transaction directory not found',
  FLS006: 'Errore nel salvataggio del PDf firmato per la transazione',
  ABT001: 'Transazione annullata',
  GEN001: 'Errore generico. Riprovare.',
  FE_DKS000: 'DikeS transaction ok',
  FE_DKS001: 'Errore durante la verifica della firma',
  FE_DKS002: 'Errore Timeout',
  FE_DKS003: 'Verifica che GoSign Desktop sia installato e in esecuzione',
  FE_DKS004: 'Transaction canceled by user',
  FE_DKS005: 'Server error',
  FE_DKS099: 'Unknown error',
  FE_QRY001: 'Invalid query parameters',
  FE_TRA001: 'Transaction not found in cache',
};
