
import { createAction, props } from '@ngrx/store';
import { AppFlow, AppModels, AppStep, AppStepsTypes, MainStep } from '../models/app.models';

export const NoopAction = createAction('Noop Action');

export const NoDispatch = { dispatch: false };


export const updateApp = createAction(
  '[App] Update App',
  props<AppModels>()
);

export const toggleMainLoading = createAction(
  '[App] Toggle Main Loading',
  props<{ showLoading: boolean, loadingMessage?: string }>()
);

export const toggleDeviceLoading = createAction(
  '[App] Toggle Main Loading',
  props<{ showLoading: boolean, loadingMessage?: string }>()
);

export const setDeviceError = createAction(
  '[App] Set Device Error',
  props<{ deviceError: string }>()
);


export const toggleDeviceLoaded = createAction(
  '[App] Toggle Main Loaded',
  props<{ deviceLoaded: boolean }>()
);


export const setCurrentFlow = createAction(
  '[App]  Set Current Flow',
  props<{ currentFlow: AppFlow, currentStep: AppStep, mainStep: MainStep }>()
);


export const setCurrentStepType = createAction(
  '[App]  Set Current StepType',
  props<{ currentStepType: AppStepsTypes }>()
);


export const setDeviceATR = createAction(
  '[App] Set Device ATR',
  props<{ deviceATR: string }>()
);
