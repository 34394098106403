<div id="debugMenu" [ngClass]="{showMenu:showMenu,closed:closed}" draggable="true" #debuMenu>
  <h4>Debug MENU</h4>
  <div class="buttons">
    <button class="closeBtn" (click)="closed = !closed">{{!closed ? 'chiudi' :'apri'}}</button>
    <button class="showBtn" (click)="showMenu = !showMenu">X</button>
  </div>

  <div class="content">
    <div class="form-row">
      <label for="isDebug">isDebug</label>
      <input type="checkbox" id="isDebug" [(ngModel)]="isDebug" />
    </div>

    <div class="form-row">
      <label for="enableCosmo">enableCosmo</label>
      <input type="checkbox" id="enableCosmo" [(ngModel)]="enableCosmo" />
    </div>

    <div class="form-row">
      <label for="expiredDate">expiredDate</label>
      <input type="date" id="expiredDate" [(ngModel)]="expiredDate" />
    </div>

    <div class="form-row">
      <label for="forceAtr">forceAtr</label>
      <input type="text" id="forceAtr" [(ngModel)]="forceAtr" />
    </div>

    <div class="form-row">
      <label for="owner">owner</label>
      <select id="owner" [(ngModel)]="owner">
        <option *ngFor="let ownerVal of ownersArray" [value]="ownerVal">{{ ownerVal }}</option>
      </select>

    </div>

    <div class="form-row">
      <label for="stubDevice">stubDevice</label>
      <input type="checkbox" id="stubDevice" [(ngModel)]="stubDevice" />
    </div>

    <div class="form-row">
      <label for="skipContactsError">skipContactsError</label>
      <input type="checkbox" id="skipContactsError" [(ngModel)]="skipContactsError" />
    </div>

    <div class="form-row">
      <label for="mainStep">mainStep</label>
      <select id="mainStep" [(ngModel)]="mainStep">
        <option value="renew">renew</option>
        <option value="substitution">substitution</option>
      </select>
    </div>

    <div class="form-row">
      <label for="currentStepType">currentStepType</label>
      <select id="currentStepType" [(ngModel)]="currentStepType">
        <option *ngFor="let step of stepsArray" [value]="step">{{ step }}</option>
      </select>
    </div>

    <div *ngIf="currentStepType">
      <a routerLink="/substitution/go_payment" queryParamsHandling="preserve"><span>go_payment</span>
      </a>

      <a routerLink="/substitution/order_paid" queryParamsHandling="preserve">
        <span>order_paid</span>
      </a>

      <a routerLink="/substitution/cert_installed" queryParamsHandling="preserve">
        <span>cert_installed</span>
      </a>
    </div>

    <ngx-json-viewer [json]="$STATE | async" [expanded]="false"></ngx-json-viewer>
  </div>
</div>
