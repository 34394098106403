import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <div class="myWrapper" [ngClass]="props.type" [class.has-error]="showError">
      <!-- <label *ngIf="props.label" [attr.for]="id" class="form-label">
        {{ props.label }}
      </label> -->

      <ng-template #fieldComponent></ng-template>

      <div *ngIf="showError" class="ic1-input-disclaimer">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <!-- <small *ngIf="props.description" class="form-text text-muted">{{
        props.description
      }}</small> -->
      <!-- <pre>{{ field | json }}</pre> -->
    </div>
  `,
})
export class UiKitFieldWrapper extends FieldWrapper {
  get pippo() {
    return true;
  }
}
