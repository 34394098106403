import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumbers]',
})
export class OnlyNumbersDirective {
  constructor(private _el: ElementRef) { }

  @Input() OnlyNumbers: number | boolean = false;

  @HostListener('input', ['$event']) onInputChange(event: any) {
    if (this.OnlyNumbers && typeof this?.OnlyNumbers === 'number') {
      const inputEl = this._el.nativeElement?.querySelector('input');

      const initalValue: string = inputEl.value;
      if (inputEl) {
        inputEl.value = initalValue.replace(/[^0-9|+]*/g, '');

        const limitChar =
          typeof this?.OnlyNumbers === 'number' ? +this.OnlyNumbers : 0;

        if (limitChar && initalValue.length > limitChar) {
          inputEl.value = initalValue.substring(0, limitChar);
          inputEl.dispatchEvent(new Event('input'));
        }
        if (initalValue !== inputEl.value) {
          event.stopPropagation();
        }
      }
    }
  }

  // @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
  //   if (this.OnlyNumbers && typeof this?.OnlyNumbers === 'number') {
  //     const inputEl = this._el.nativeElement?.querySelector('input');
  //     let pastedData = event.clipboardData.getData('text');

  //     const initalValue: string = inputEl.value;
  //     if (inputEl) {
  //       pastedData = initalValue.replace(/[^0-9]*/g, '');

  //       const limitChar =
  //         typeof this?.OnlyNumbers === 'number' ? +this.OnlyNumbers : 0;

  //       if (limitChar && initalValue.length > limitChar) {
  //         pastedData = initalValue.substring(0, limitChar);
  //       }
  //       if (initalValue !== pastedData) {
  //         event.stopPropagation();
  //       }
  //     }
  //   }
  // }
}
