import { DikeSession } from '@app/models/dike.interface';
import { createAction, props } from '@ngrx/store';


export const setSession = createAction(
  '[Session] Set Session',
  props<DikeSession>()
);

export const removeSession = createAction('[Session] Remove Session');
