import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() status: 'success' | 'warning' | 'error' | 'info';
  @Input() text: string
  @Input() hideClose: boolean = false;

  @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

  onClick(event: MouseEvent) {
    console.count("🚀 ~ MessageComponent ~ onClick ~ this.click")
    if (this.hasClickListener) {
      event.preventDefault();
      event.stopPropagation();
      // Trigger the EventEmitter when the component is clicked
      this.click.emit(event);
    }
  }
  get hasClickListener(): boolean {
    return this.click.hasOwnProperty('_handlers');
  }

}
