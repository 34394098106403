import { isTranslateKey } from '@app/helpers/helpers';
import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export class defaultBaseInputExtension implements FormlyExtension {
  prePopulate(field: any): void {
    if (!field.props?.label) {
      return;
    }
    field.props = {
      ...field.props,
      translate: true,
    };
  }

  // onPopulate(field): void {},

  // postPopulate(field): void {},
}

export class TranslateExtension implements FormlyExtension {
  constructor(private translate: TranslateService) { }
  prePopulate(field: FormlyFieldConfig) {
    const props: any = field.props || {};
    if (!props.translate || props._translated) {
      return;
    }
    props._translated = true;
    field.expressions = {
      ...(field.expressions || {}),
      'props.label': this.translate.stream(props?.label),
    };

    if (props.placeholder && isTranslateKey(props.placeholder)) {
      field.expressions = {
        ...(field.expressions || {}),
        'props.placeholder': this.translate.stream(props?.placeholder || ''),
      };
    }
  }
}
export function registerTranslateExtension(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.stream('FORMS.VALIDATORS.REQUIRED');
        },
      },
      {
        name: 'PIN_PUK_VALIDATOR',
        message() {
          return translate.stream('FORMS.VALIDATORS.PIN_PUK_VALIDATOR');
        },
      },
      {
        name: 'MAX_LENGTH',
        message() {
          return translate.stream('FORMS.VALIDATORS.MAX_LENGTH');
        },
      },
      {
        name: 'minLength',
        message() {
          return translate.stream('FORMS.VALIDATORS.MIN_LENGTH');
        },
      },
      {
        name: 'maxLength',
        message() {
          return translate.stream('FORMS.VALIDATORS.MIN_MAX_LENGTH');
        },
      },
      {
        name: 'REQUIRED',
        message() {
          return translate.stream('FORMS.VALIDATORS.REQUIRED');
        },
      },
      {
        name: 'SAME_PASSWORD',
        message() {
          return translate.stream('FORMS.VALIDATORS.SAME_PASSWORD');
        },
      },
      {
        name: 'SAME_PIN',
        message() {
          return translate.stream('FORMS.VALIDATORS.SAME_PASSWORD');
        },
      },
      {
        name: 'PASSWORD_STRENGHT',
        message() {
          return translate.stream('FORMS.VALIDATORS.PASSWORD_STRENGHT');
        },
      },
      {
        name: 'PASSWORD_STRENGHT_MINOSSE',
        message() {
          return translate.stream(
            'FORMS.VALIDATORS.PASSWORD_STRENGHT_MINOSSE'
          );
        },
      },
      {
        name: 'SAME_OLD_PASSWORD',
        message() {
          return translate.stream('FORMS.VALIDATORS.SAME_OLD_PASSWORD');
        },
      },
      {
        name: 'PASSWORD_SECURITY',
        message() {
          return translate.stream('FORMS.VALIDATORS.PASSWORD_SECURITY');
        },
      },
      {
        name: 'PASSWORD_DESCRIPTION',
        message() {
          return translate.stream('FORMS.VALIDATORS.PASSWORD_DESCRIPTION');
        },
      },
      {
        name: 'PASSWORD_DESCRIPTION_MINOSSE',
        message() {
          return translate.stream(
            'FORMS.VALIDATORS.PASSWORD_DESCRIPTION_MINOSSE'
          );
        },
      },
    ],
    extensions: [
      {
        name: 'setDefault',
        extension: new defaultBaseInputExtension(),
        priority: 1,
      },
      {
        name: 'translate',
        extension: new TranslateExtension(translate),
        priority: 2,
      },
    ],
  };
}
