<ng-select [items]="devices" [(ngModel)]="selectedDevice" [searchable]="false" [clearable]="false"
  (change)="onChangeSelect($event)" bindLabel="label" bindValue="atr">
  <ng-template ng-label-tmp let-item="item">
    <div class="flex-row">
      <b>{{ item?.displayLabel?.ownerFullName }} </b>
      <div>- {{ item.model }}</div>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="flex-row">
      <b>{{ item?.displayLabel?.ownerFullName }} </b>
      <div>- {{ item.model }}</div>
      <app-debugger>

        <ng-ui-kit-button *ngFor="let cert of item.certificates" [label]="'PIALLA '+cert.ckaid" type="button"
          class="btn btn-secondary" (onClick)="pialla(cert.certId)">
          PIALLA
        </ng-ui-kit-button>
        {{getDeviceInfo(item).atr}}
        {{getDeviceInfo(item).label}}
      </app-debugger>
    </div>
  </ng-template>
</ng-select>
