import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-debugger',
  templateUrl: './debugger.component.html',
  styleUrls: ['./debugger.component.scss']
})
export class DebuggerComponent {

  @Input() data: Object = {};
  @Input() title: string = 'Debugger';

  isOpen: boolean = false;


  onToggle(opened: any) {

    this.isOpen = opened
  }

  get isDebug() {
    return window.dikeDebug.isDebug;
  }

  get fullTitle() {
    return `(DEBUG) ${this.title}`
  }

  get datas() {
    return { ...this.data };
  }

}
