import { Component, OnInit } from '@angular/core';

import { Device } from '@app/store/models';
import { AppStep } from '@app/store/models/app.models';
import { getCurrentStep } from '@app/store/selectors/app.selector';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-substitution',
  templateUrl: './substitution.component.html',
  styleUrls: ['./substitution.component.scss']
})
export class SubstitutionComponent implements OnInit {


  $device: Observable<Device>;


  $currStep: Observable<AppStep | undefined> = of(undefined);

  constructor(private store: Store<GlobalState>) {

  }



  ngOnInit(): void {

    this.$device = this.store.select(selectDevice)
    this.$currStep = this.store.select(getCurrentStep)

  }


}
