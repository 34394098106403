import { Injectable } from '@angular/core';
import { getCertificateStatus, getHumanType, getOwnerFromCertificates } from '@app/helpers/certificate.helpers';
import { catchSwitchMapError, getExpirationDateFromTimestamp } from '@app/helpers/helpers';
import { SYS_SNAPSHOT } from '@app/models/dike.interface';
import { NoopAction, setDeviceError, toggleDeviceLoaded, toggleDeviceLoading } from '@app/store/actions/app.actions';
import { setOwner } from '@app/store/actions/customization.actions';
import { checkDevicePossibleActions, setDevice, updateCertificatesStatus, updateDevice } from '@app/store/actions/device.actions';
import { getDevices, refreshDevices, removeDevices, setDevices } from '@app/store/actions/devices.actions';
import { Certificate, Device } from '@app/store/models';
import { formatDevice } from '@app/store/reducers/device.reducer';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState, resetGlobalState } from '@app/store/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ActionsManagerService } from './actions-manager.service';
import { CustomizationTranslationService } from './customization-translation.service';
import { DikeService } from './dike.service';
import { DikerWebService } from './diker-web.service';
import { LoggerService } from './logger.service';


const logger = new LoggerService("StateEffects");
@Injectable()
export class StateEffects {

  setOwner$ = createEffect(() => this.actions$.pipe(
    ofType(setOwner),
    tap(({ owner }) => {
      logger.log("🚀 ~ StateEffects ~ tap ~ owner", owner)
      if (owner && owner !== "UNKN") {
        this.customizationTranslationService.setTranslation(owner)
      }
    }),
    map(() => NoopAction())
  ), { dispatch: false }
  );

  refreshDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshDevices),
      concatMap(() =>
        of(
          resetGlobalState,
          getDevices,
        )
      ),

      map(() => NoopAction())
    )
  }, { dispatch: true }
  );

  getDevices$ = createEffect(() => this.actions$.pipe(
    ofType(getDevices),
    tap(() => this.store.dispatch(toggleDeviceLoading({ showLoading: true, loadingMessage: this.translate.instant("GLOBALS.CHECKING_GOSIGN") }))),
    tap(() => this.store.dispatch(removeDevices())),
    delay(1000),
    switchMap(() => {
      return this.getDevices().catch(err => {

        throw err
      })
    }),
    map((devices) => {
      logger.log("🚀 ~ StateEffects ~ map ~ devices", devices)
      const filterdDevices = devices.filter(device => !!device.atr).filter(device => device.certificates.length > 0)
      this.store.dispatch(setDevices({ devices: filterdDevices }))
    }),
    catchSwitchMapError((error = "NO_DEVICES_FOUND") => {
      logger.log("🚀 ~ StateEffects ~ catchError ~ error", error)
      const errorMessage = this.translate.instant(`ERRORS.${error?.cause}`);
      this.store.dispatch(toggleDeviceLoading({ showLoading: false }))

      this.store.dispatch(setDeviceError({ deviceError: errorMessage }))
      return of(error);
    }),
    tap(() => {
      this.store.dispatch(toggleDeviceLoading({ showLoading: false }))
      this.store.dispatch(toggleDeviceLoaded({ deviceLoaded: true }))
    }),
    map(() => NoopAction())
  ), { dispatch: true }
  );

  //Quando viene settato il device, viene chiamato il metodo updateCertificates
  setDevice$ = createEffect(() => this.actions$.pipe(
    ofType(setDevice),

    map((device) => {
      logger.log("🚀 ~ StateEffects ~ map ~ device:", device)
      this.store.dispatch(updateCertificatesStatus());
    }),

    map(() => NoopAction())
  ), { dispatch: true }
  );



  updateCertificates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCertificatesStatus),
      withLatestFrom(this.store.select(selectDevice)),
      tap(([_, device]) => {
        const certificates = device.certificates.map(certificate => ({
          ...certificate,
          updateStatus: 'LOADING'
        }));
        this.store.dispatch(updateDevice({ updateStatus: 'LOADING', certificates } as Partial<Device>));
      }),
      switchMap(([_, device]) =>
        forkJoin(
          device.certificates.map(certificate =>
            this.checkRenewStatusObservable(certificate).pipe(
              catchError(() => {
                return of(null)
              }) // Cattura l'errore e restituisce un valore null
            )
          )
        )
      ),
      tap(async (certificatesUpdated) => {
        // Ignora i certificati null generati dagli errori
        const certificates = certificatesUpdated.filter(cert => cert !== null);

        const signCertificate = certificates.find(certificate => certificate.cert.nonrepudiation === 'true');

        if (certificates.length) {

          const owner = getOwnerFromCertificates(certificates);
          this.store.dispatch(updateDevice({ updateStatus: 'UPDATED', certificates, signCertificate, owner }));
          this.store.dispatch(setOwner({ owner }));
        } else {
          this.store.dispatch(updateDevice({ updateStatus: 'ERROR', certificates, signCertificate }));
        }
      }),
      delay(1000),
      tap(() => {
        this.store.select(selectDevice).subscribe(device => {
          // Ottieni il dispositivo corrente utilizzando il selettore selectDevice
          logger.log("device?.updateStatus", device?.updateStatus)

          if (!device) {
            this.store.dispatch(resetGlobalState())
          }
          else if (device?.updateStatus === "ERROR") {
            //problemi nel caricamento dei certificati refresho tutto
            const errorMessage = this.translate.instant(`ERRORS.NO_CERTIFICATES_FOUND`);

            this.store.dispatch(resetGlobalState())
            this.store.dispatch(setDeviceError({ deviceError: errorMessage }))
          }
          else if (device?.updateStatus === "UPDATED") {
            logger.log("DEVICE UPDATED procedo con le azioni", device?.updateStatus)
            this.store.dispatch(checkDevicePossibleActions())
          }
        })
      }),

      map(() => NoopAction()),
      catchError(() => {
        logger.error("🚀 ~ RenewStatus certificate error")
        return of(NoopAction())
      }) // Gestisci l'errore restituendo un'azione per interrompere l'esecuzione
    ), { dispatch: true }
  );


  checkDevicePossibleActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkDevicePossibleActions),
      withLatestFrom(this.store.select(selectDevice)),
      switchMap(([_, device]) => {
        return this.actionsManagerService.checkDevicePossibleActions(device)
      }),
      // tap((device) => {
      //   this.store.dispatch(updateDevice(device));
      // })
      map(() => NoopAction())
    ), { dispatch: true }
  );




  constructor(
    private actions$: Actions,
    private actionsManagerService: ActionsManagerService,
    private store: Store<GlobalState>,
    private dikerWebService: DikerWebService,
    private dikeService: DikeService,
    private translate: TranslateService,
    private customizationTranslationService: CustomizationTranslationService,

  ) { }


  public async getDevices(): Promise<Device[]> {

    let gettingDevice = false;
    return new Promise(async (resolve, reject) => {

      if (gettingDevice) return reject("Getting device")

      gettingDevice = true;

      let goSignOpened = false;
      try {
        await this.dikeService.getDikeSession(true).catch(err => {
          console.error("DikeSession", err)
          throw new Error("No Dike Session", { cause: 'DikeSession' })
        })


        await this.dikeService.DikeCommand("WAKEUP").then(() => {
          goSignOpened = true;
        })
          .catch(err => {
            console.error("WAKEUP", err)
            throw new Error("Can't connect to Gosign", { cause: "WAKEUP" })
          })
      } catch (err) {
        console.error(err)
        gettingDevice = false;
        reject(err)
      }

      if (!goSignOpened) return reject("Can't connect to Gosign")

      this.store.dispatch(toggleDeviceLoading({ showLoading: true, loadingMessage: this.translate.instant("GLOBALS.GETTING_DEVICES") }));

      await this.dikeService.DikeCommand<SYS_SNAPSHOT>("SYS_SNAPSHOT")
        .then(response => {
          logger.log("response", response);
          const devices = response?.devices || [];
          logger.log("🚀 ~ SelectCertificateComponent ~ go ~ devices:", devices)

          if (!devices.length) {
            reject(new Error("No device Found", { cause: "NO_DEVICES_FOUND" }))
          } else {
            const formattedDevices = devices.map(formatDevice)
            resolve(formattedDevices)
          }
        })
        .catch(err => {
          console.error(err)
          reject(err)
        }).finally(() => {
          //this.status = 'IDLE'
          this.store.dispatch(toggleDeviceLoading({ showLoading: false, }))

          gettingDevice = false;
        })

    })
  }

  public checkRenewStatusObservable(certificate: Certificate): Observable<Certificate> {
    console.log("🚀 ~ StateEffects ~ checkRenewStatusObservable ~ certificate:", certificate)
    //this.store.dispatch(updateDeviceCertificate({ certId: certificate.certId, certificate: { updateStatus: 'LOADING' } }))

    const { certId, cert: { content }, device: { atr, serial } } = certificate;


    return this.dikerWebService.checkRenewStatus({
      atr,
      certB64: content,
      certId,
      pData: this.getStringFormattedPData(certificate),
      serialSmartCard: serial,
    }).pipe(

      //delayWhen(() => interval(Math.random() * 5000)),
      map(renewStatusResponse => {

        logger.log("🚀 ~ file: certificate.component.ts ~ line 60 ~ CertificateComponent ~ .subscribe ~ res", renewStatusResponse)
        const renewStatus = {
          data: renewStatusResponse,
          status: '',
          err: null,
        };

        const type = renewStatusResponse.cert.type;
        const humanType = getHumanType(type);

        const owner = renewStatusResponse.cert.owner;

        const hasP10 = renewStatusResponse.renewRequest.hasP10;

        const status = getCertificateStatus(renewStatusResponse);

        const expirationDate = getExpirationDateFromTimestamp(renewStatusResponse.cert.expirationDate).format("DD/MM/YYYY");

        let paymentRestriction = renewStatusResponse.cert.paymentRestriction;
        //https://jira.infocert.it/browse/CADIKE-5272
        if (renewStatusResponse.cert.owner === "INFOCERT" && certificate.device.deviceModel.name === "CNS v2") {
          paymentRestriction = ["SHOP"];
        }

        const renewableDetails = renewStatusResponse?.cert?.renewableDetails;

        const isRenewable = renewStatusResponse?.cert?.renewable;

        const certificateUpdated: Certificate = {
          ...certificate, renewStatus, humanType, type, owner, hasP10, status, paymentRestriction, expireDateServer: expirationDate,
          renewableDetails,
          isRenewable, updateStatus: "UPDATED"
        }



        console.log("🚀 ~ StateEffects ~ checkRenewStatusObservable ~ certificateUpdated:", certificateUpdated)
        return certificateUpdated


      }),
      take(1), // Completa l'observable dopo la prima emissione
      catchError((err, caught) => {
        console.warn("🚀 ~ StateEffects ~ catchError ~ err:", err)

        throw caught
      }),


    )

  }







  private getStringFormattedPData(certificate: Certificate): string {
    const { cns_pdata } = certificate;
    if (cns_pdata) {
      // attenzione perchè l'oggetto Date in javascript ha i mesi che vanno da 0 a 11
      // e i giorni che vanno da 0 a 31
      // e in questo caso this.cns_pdata è un oggetto di tipo Date
      let day: number | string = cns_pdata.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      let month: number | string = cns_pdata.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let year = cns_pdata.getFullYear();
      return day + '/' + month + '/' + year;
    } else {
      return '01/01/2000';
    }
  };


}
