import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-formly-group',
  template: `
    <div class="custom-formly-group "   >
      <h4 class="ic1-my-20" >{{field.props.label}}</h4>
      <div class="formly-group ic1-row">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./form-styles.scss'],

})
export class CustomFormlyGroupComponent extends FieldWrapper implements OnInit {

  constructor() {
    super();


  }



  ngOnInit() {

  }


}
