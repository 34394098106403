import { Action, createReducer, on } from '@ngrx/store';
import { setCustomization, setOwner } from '../actions/customization.actions';
import { Customization } from '../models/customization.models';

export const initialState: any = {}

export const customizationReducer = createReducer<Customization, Action>(
  initialState,
  on(setCustomization, (state, customization) => {
    return { ...state, ...customization };
  }),
  on(setOwner, (state, owner) => {
    return Object.assign({}, owner);
  })
);
