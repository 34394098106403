import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { getTrueBooleanKeyFromCertificate } from '@app/helpers/certificate.helpers';
import { setCurrentFlow } from '@app/store/actions/app.actions';
import { updateDeviceCertificate } from '@app/store/actions/device.actions';
import { Device } from '@app/store/models';
import { AppStep, AppSteps, AppStepsTypes, MainStep, RenewStepsTypes, SubstituteStepsTypes } from '@app/store/models/app.models';
import { CertStatus, Certificate } from '@app/store/models/certificate.model';
import { allowedSubsOwners } from '@app/store/models/customization.models';
import { GlobalState } from '@app/store/store';
import { STUB_RANDO } from '@app/stubs/stub-devices';
import { Store } from '@ngrx/store';
import { LoggerService } from './logger.service';

const logger = new LoggerService("ActionsManagerService");

@Injectable({
  providedIn: 'root'
})
export class ActionsManagerService {


  public APP_STEPS: AppSteps = {
    select_device: [
      {
        mainStep: "select_device",
        step: "select_device",
        stepIndex: 0,
        label: "Seleziona il Dispositivo",
      },
    ],
    renew: [

      {
        mainStep: "renew",
        step: "select_cert",
        stepIndex: 0,
        label: "Seleziona il certificato",
      },
      {
        mainStep: "renew",
        step: "go_payment",
        stepIndex: 1,
        label: "Vai al pagamento",
      },
      {
        mainStep: "renew",
        step: "order_paid",
        stepIndex: 2,
        label: "Ordine pagato",
      },
      {
        mainStep: "renew",
        step: "cert_installed",
        stepIndex: 3,
        label: "Certificato installato",
      },
      {
        mainStep: "renew",
        step: "eol",
        stepIndex: 4,
        label: "Fine vita",
        hide: true
      },
    ],



    substitution: [

      {
        mainStep: "substitution",
        step: "select_cert",
        stepIndex: 0,
        label: "Seleziona il dispositivo",

      },
      {
        mainStep: "substitution",
        step: "select_cert",
        stepIndex: 1,
        label: "Dati intestatario",

      },
      {
        mainStep: "substitution",
        step: "go_payment",
        stepIndex: 2,
        label: "Procedi al pagamento",
      },
      {
        mainStep: "substitution",
        step: "order_paid",
        stepIndex: 3,
        label: "Completa la sostituzione",
      },
      {

        mainStep: "substitution",
        step: "cert_installed",
        stepIndex: 4,
        label: "Certificato installato",
        hide: true
      },
      {
        mainStep: "substitution",
        step: "eol",
        stepIndex: 5,
        label: "Fine vita",
        hide: true
      },


    ],


  }

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<GlobalState>) { }

  /**
 *  1 Controlla le azioni possibili per il device appena selezionato
 */
  public async checkDevicePossibleActions(device: Device) {
    //console.count("checkDevicePossibleActions")
    logger.log("🚀 ~ StateEffects ~ checkDevicePossibleActions ~ device:", device)
    const { atr, serial, signCertificate, deviceModel, certificates, owner, updateStatus } = device;
    //debugger;
    if (updateStatus !== "LOADING") {
      if (updateStatus === "UPDATED") {

        if (deviceModel?.isRenewable && device.owner !== "THIRDPARTY") {
          //Dispositivi rinnovabili non deprecati
          /**
          JSIGN v4 => RINNOVO PULITO (non deprecato)
          JSIGN v3 => RINNOVO (non deprecato) con messaggio aggiuntivo (fino al 30giugno 2022)
           */

          const currentFlow = this.APP_STEPS.renew;
          const nextStep = this.checkRenewNextStep(device)
          if (!nextStep) return;

          const currentStep = currentFlow.find(s => s.step === nextStep);
          logger.log("🚀 ~ Device RINNOVABILE => RENEW => " + currentStep.step, { step: currentStep.step, currentFlow, currentStep, mainStep: "substitution" })

          this.store.dispatch(setCurrentFlow({ currentFlow, currentStep, mainStep: "renew" }));
          this.router.navigate([ROUTE_PATHS.renew]);
        } else {

          //Dispositivi non rinnovabili, controllo se può essere sostituito

          // this.router.navigate([ROUTE_PATHS.substitution]);

          const currentFlow = this.APP_STEPS.substitution;
          const nextStep = this.checkSubstitutionNextStep(device)
          if (!nextStep) return;

          const currentStep = currentFlow.find(s => s.step === nextStep);
          logger.log("🚀 ~ Device NON rinnovabile => SUBSTITUTION => " + currentStep.step, { step: currentStep.step, currentFlow, currentStep, mainStep: "substitution" })

          this.store.dispatch(setCurrentFlow({ currentFlow, currentStep, mainStep: "substitution" }));
          this.router.navigate([ROUTE_PATHS.substitution]);

        }
      } else {
        logger.log("🚀 ~ Device NON AGGIORNATO => UPDATE ", device)

        //alert("Device non aggiornato o impossibile recuperare certificati!")
      }
    }
  }

  /**
   *  2A Controlla in che stato è per la SOSTITUZIONE
   */
  public checkSubstitutionNextStep(device: Device) {

    if (!device) {
      if (!!window?.dikeDebug?.stubDevice) {
        device = STUB_RANDO()
      } else {
        this.router.navigate([ROUTE_PATHS.home]);
      }
    };

    if (!device || device.updateStatus !== "UPDATED") return false;


    let currentStepType: SubstituteStepsTypes = "eol"

    if (window.dikeDebug.currentStepType) {

      currentStepType = window.dikeDebug.currentStepType as SubstituteStepsTypes;
    } else {
      logger.log("🚀 ~ CheckFlow checkSubstitutionNextStep  ~ device:", device)

      const { atr, serial, signCertificate, deviceModel, certificates, owner } = device;

      /*
      1. controllo owner  */
      logger.log('🚀 ~ checkSubstitutionNextStep ~ Controllo Owner se presente in ', { owner, allowedOwners: allowedSubsOwners })
      // B. se owner è presente in lista owner sostituzione (INFOCERT, SIXTEMA) => Proseguo
      if (!allowedSubsOwners.includes(owner)) {
        //A.se owner non è presente in lista owner sostiutzione => MESSAGGIO e mi fermo con msg customizzato in base a owner e tipo di device
        logger.log('🚀 ~ checkSubstitutionNextStep ~ owner not allowed', { owner, allowedOwners: allowedSubsOwners })
        currentStepType = "eol"

      }


      //2. Controllo Status del certificato
      else if (signCertificate) {
        currentStepType = this.setCertificateNextStep<SubstituteStepsTypes>(signCertificate, "substitution");

        logger.log('🚀 ~ checkSubstitutionNextStep ~  vado nella pagina', currentStepType)

      }

    }

    return currentStepType

  }


  /**
   * 2B Controlla in che stato è per il RINNOVO
   */
  public checkRenewNextStep(device: Device) {

    if (!device) {
      if (!!window?.dikeDebug?.stubDevice) {
        device = STUB_RANDO()
      } else {
        this.router.navigate([ROUTE_PATHS.home]);
      }
    };

    if (!device || device.updateStatus !== "UPDATED") return false;


    let currentStepType: RenewStepsTypes = "eol"

    if (window.dikeDebug.currentStepType) {

      currentStepType = window.dikeDebug.currentStepType as RenewStepsTypes;
    } else {
      logger.log("🚀 ~ CheckFlow Component ~ checkRenewNextStep ~ device:", device)

      const { atr, serial, signCertificate, deviceModel, certificates, owner } = device;

      // questa aprte unica per il rinnovo e la sostituzione

      // ciclo per tutti i certificati e non solo per quello di firma
      //impostato lo step con quello del certificato che sta "più avanti nel processo" e non è 'cert_installed'
      // gli altri certificati che hanno uno stato diverso (più indietro) non saranno selezionabili ma con messaggio tipo 'completa prima operazione sull'altro certiciao'

      // const Steps = [signCertificate].map(cert => this.setCertificateNextStep<RenewStepsTypes>(cert, "renew"));

      let Steps = certificates.map(cert => this.setCertificateNextStep<RenewStepsTypes>(cert, "renew"));
      logger.log("🚀 ~ checkRenewNextStep ~ Controllo gli sterp di tutti i certificati:", { Steps })

      //Se sono tutti cert_installed allora vado direttamente alla pagina di riepilogo
      if (Steps.every(s => s === "cert_installed")) {
        logger.log("🚀 ~ checkRenewNextStep ~ Tutti i certificati sono cert_installed:", { Steps })
        currentStepType = "cert_installed"
      } else {
        //Se non sono tutti cert_installed, tolgo cert_installed dalla lista e controllo se sono tutti allo stesso step
        Steps = Steps.filter(s => s !== "cert_installed");

        const allStepsEquals = Steps.every((step, index, array) => step === array[0]);

        // Controllo se tutti i certificati sono allo stesso step
        if (allStepsEquals) {
          logger.log("🚀 ~ checkRenewNextStep ~ Tutti i certificati sono allo stesso step:", { allStepsEquals, Steps })

          currentStepType = Steps[0]
        } else {

          const higherStep = this.findMostAdvancedStep(Steps);
          logger.log("🚀 ~ checkRenewNextStep ~ Tutti i certificati NON sono allo stesso step, prendo quello più avanti", { allStepsEquals, Steps, higherStep })

          currentStepType = higherStep.step;
        }
      }



    }

    return currentStepType

  }

  setCertificateNextStep<T = AppStepsTypes>(certificate: Certificate, mainStep: MainStep): T {

    const appStep = this.checkCertificateNextStep<T>(certificate, mainStep);

    if (certificate.appStep !== appStep) {
      this.store.dispatch(updateDeviceCertificate({ partialCertificate: { certId: certificate.certId, appStep: appStep as AppStepsTypes } }));
    }
    return appStep;
  }

  checkCertificateNextStep<T = AppStepsTypes>(certificate: Certificate, mainStep: MainStep): T {

    let currentStepType: T;

    const { status, isExpired, isExpiredMoreThan90days, isExpiringIn3Months, paymentRestriction, renewableDetails, isRenewable, owner, device: { deviceModel } } = certificate;


    //Controlli specifici per il rinnovo
    if (mainStep === "renew") {
      /*  1. controllo se ha payment methods e NON è una Tessera sanitaria  */
      if (!paymentRestriction.length && !certificate?.device?.isHealthCard) {
        logger.log('🚀 ~ checkRenewNextStep ~ Non ha payments methods quindi EOL ')
        currentStepType = "eol" as T;

        return currentStepType;
      }
      logger.log("🚀 ~ checkRenewNextStep ~ ha almeno un payment quindi continuo", { paymentRestriction })

      //2. Controllo i booleani della morte
      logger.log("🚀 ~ checkRenewNextStep ~ Controllo il booleano isRenewable", { isRenewable })
      if (!isRenewable) {
        // non è rinnovabile controllo gli altri
        logger.log("🚀 ~ checkRenewNextStep ~ Non è rinnovabile controllo i booleani della morte", { renewableDetails })
        const foundTrueBool = getTrueBooleanKeyFromCertificate(certificate);
        /*
          roleCertificateAbilitationError: boolean;
          expiredWithoutP10Error: boolean;
          pDataCNSCheckError: boolean;
          only1024SmartCardError: boolean;
          carteCamerali2014Error: boolean;
          coungruentState: boolean;
        */

        if (foundTrueBool) {

          logger.log("🚀 ~ checkRenewNextStep ~ Trovato il booleano true quindi EOL con messaggio", { foundTrueBool })

          currentStepType = "cert_installed" as T;

          return currentStepType;
        }

      }

    }


    //Controlli comuni per la sostituzione e il rinnovop

    if (!isExpiringIn3Months && !isExpired) {

      if (owner == "INFOCERT" && deviceModel?.name === "CNS v2" && status === CertStatus.EMPTY) {
        logger.log("🚀 ~ checkRenewNextStep ~ CNS v2 INFOCERT quindi vai a sostituzione forzata  ", { owner, deviceModel })

        currentStepType = "select_cert" as T;
        return currentStepType;
      } else {
        logger.log("🚀 ~ checkRenewNextStep ~ Scadrà tra più di 3 mesi quindi aspetta vai in certi_installed o EOL da capire", { isExpiringIn3Months })
        //TODO: se non va bene vista cert_installed mandare a EOL con vista solo lista certificati
        currentStepType = "cert_installed" as T;

      }

    }


    if (isExpiredMoreThan90days) {
      logger.log('🚀 ~ checkRenewNextStep ~  è scaduto da più di 90 giorni => EOL', { isExpiredMoreThan90days })
      //Pagina con certificati presenti e scaduti
      currentStepType = "eol" as T

      return currentStepType;

    }

    if (isExpired && status === CertStatus.EMPTY) {
      logger.log('🚀 ~ checkRenewNextStep ~ è scaduto ed è EMPTYT => EOL', { isExpired, status })
      currentStepType = "eol" as T;

      return currentStepType;

    }

    //Posso continuare con la sostituzione o rinnovo
    logger.log('🚀 ~ checkRenewNextStep ~ continuo e controllo lo status', { isExpiredMoreThan90days, isExpired, status })
    switch (status) {
      /*
      - EMPTY("empty"), //non è presente nessun record di rinnovo gestito da dike-server
         Check scadenza cert di firma,
      */
      case CertStatus.EMPTY:

        currentStepType = "select_cert" as T
        break;

      /*
       P10_GENERATED("p10_generated"), //la richiesta di rinnovo del certificato è stata creata
         - ON_CART("on_cart"), //popolato carrello #cartID con articolo di rinnovo (associato a questo cerId)
         - ORDER_CREATED("order_created"), //e' stato creato un ordine nel negozio per questo certId
           => Vai a Procedi al pagamento
      */
      case CertStatus.P10_GENERATED:
      case CertStatus.ON_CART:
      case CertStatus.ORDER_CREATED:

        currentStepType = "go_payment" as T
        break;

      case CertStatus.RENEW_ENABLED:
      case CertStatus.CERT_DOWNLOADED:
      case CertStatus.CERT_GENERATED:
        //- RENEW_ENABLED("renew_enabled"), //il negozio ha comunicato l'avvenuto pagamento
        currentStepType = "order_paid" as T
        break;

      case CertStatus.CERT_INSTALLED:
        //   - CERT_INSTALLED("cert_installed"); //il nuovo certificato è stato installato nel token
        // - => Riassunto sostituzione e contratto
        currentStepType = "cert_installed" as T
        break;



    }

    logger.log('🚀 ~ checkRenewNextStep ~ con lo status', status, 'vado nella pagina', currentStepType);

    return currentStepType;
  }



  findMostAdvancedStep = (steps): AppStep => {
    let highestIndex = -1;
    let matchedStep: AppStep;

    this.APP_STEPS.renew.forEach((item) => {
      if (item.stepIndex > highestIndex && steps.includes(item.step)) {
        highestIndex = item.stepIndex;
        matchedStep = item;
      }
    });

    return matchedStep;
  };



}
