import { Component } from '@angular/core';
import { getLoadingMessage, isMainLoading } from '@app/store/selectors/app.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-main-loading',
  templateUrl: './main-loading.component.html',
  styleUrls: ['./main-loading.component.scss']
})
export class MainLoadingComponent {

  $mainLoading: Observable<boolean> = of(true)
  $loadingMessage: Observable<string> = of("")

  constructor(private store: Store<GlobalState>,) {
    this.$mainLoading = this.store.select(isMainLoading);
    this.$loadingMessage = this.store.select(getLoadingMessage);

  }
}
