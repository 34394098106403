import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserInfo } from '@app/store/models/session.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const ONLY_8_NUMBERS_REGEX = /^\d{8}$/;
export const ONLY_6_NUMBERS_REGEX = /^\d{6}$/;

export const PASSWORD_CHARS_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!^.\1{1})(?=.*?[&%!"()?^+[.<>\],_]).{8,}/; // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const AVOID_REPEATED_CHARS_REGEX = /^(?!.*(.)\1{1,}).+$/; // Not allowed repead sequence chars

export const PASSWORD_MINOSSE_REGEX = /^[a-zA-Z0-9]{8,}$/; // Minimum eight characters,only letters and numbers, NO special chars
export const LETTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9]{8,}$/; // Minimum eight characters,only letters and numbers, NO special chars

// write a regex to check is string contains only alphanumeric characters and minimum 8 characters, not allowed special characters
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const ITALIAN_PHONE_REGEX = /^(?:\+39|0|00)?3[0-9]{8,10}$/;
export const INTERNATIONAL_PHONE_REGEX = /^(?:\+\d{1,3}|00\d{1,3})\d{8,15}$/;

export const NO_SPECIAL_CHARS_REGEX = /[!@#°§£$%^&ç*()_+\=;:'"\\|,<>\/?\-]+/g;
const CLEAN_ADDRESS_REGEX = /[^a-zA-Z0-9\s\u0027àèéìòùÀÈÌÒÙ()]/g;


export const mobileValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const mobileValue = control.value;
  if (!mobileValue) {
    return null;
  }
  const isValid = INTERNATIONAL_PHONE_REGEX.test(mobileValue);
  return !isValid ? { mobile: true } : null;
};

export const strenghtPasswordValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const passwordValue = control.value;
  if (!passwordValue) {
    return null;
  }

  const isStrenght = PASSWORD_CHARS_REGEX.test(passwordValue);
  if (!isStrenght) {
    //return { isNotStrenght: true };
  }
  const hasNotRepeatingChars = AVOID_REPEATED_CHARS_REGEX.test(passwordValue);

  if (!hasNotRepeatingChars) {
    //return { hasRepeatingChars: true };
  }

  // console.log('🚀 ~ return ~ isStrenght', {
  //   isStrenght,
  //   hasNotRepeatingChars,
  // });
  const isValid = isStrenght && hasNotRepeatingChars;
  return !isValid ? { PASSWORD_STRENGHT: true } : null;
};

export const strenghtPasswordMinosse = (
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: { user: UserInfo }
): ValidationErrors | null => {
  //console.log('🚀 ~ options', options);
  const passwordValue: string = control.value;
  if (!passwordValue) {
    return null;
  }

  if (options?.user) {
    if (
      passwordValue.toLowerCase().includes(options.user.username.toLowerCase())
    ) {
      return { PASSWORD_STRENGHT_MINOSSE: true };
    }
  }

  const isStrenght = PASSWORD_MINOSSE_REGEX.test(passwordValue);

  return !isStrenght ? { PASSWORD_STRENGHT_MINOSSE: true } : null;
};

export const pinPukValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const pinPuk = control.value;
  if (!pinPuk) {
    return null;
  }

  const is8digits = ONLY_8_NUMBERS_REGEX.test(pinPuk);

  return !is8digits ? { PIN_PUK_VALIDATOR: true } : null;
};

export const otpValidator = (
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: { lenght: number } = { lenght: 8 }
): ValidationErrors | null => {
  const pinPuk = control.value;
  if (!pinPuk) {
    return null;
  }

  const { lenght = 8 } = options;
  //console.log('🚀 ~ options', options);
  if (lenght == 6) {
    const is6digits = ONLY_6_NUMBERS_REGEX.test(pinPuk);
    return !is6digits ? { PIN_PUK_VALIDATOR: true } : null;
  } else if (lenght == 8) {
    const is8digits = ONLY_8_NUMBERS_REGEX.test(pinPuk);
    return !is8digits ? { PIN_PUK_VALIDATOR: true } : null;
  }

  return null;
};

export function strenghtPasswordValidator(): ValidatorFn {
  return strenghtPasswordValidatorFn;
}

export function validEmailValidator(control: AbstractControl) {
  const email = control.value;
  if (!email) {
    return null;
  }
  //console.log('🚀 ~ email', email);
  const isValid = EMAIL_REGEX.test(email);
  return !isValid ? { VALID_EMAIL: true } : null;
}

export function validPassphraseValidator(control: AbstractControl) {
  const pass = control.value;
  if (!pass) {
    return null;
  }
  //console.log('🚀 ~ pass', pass);
  const isValid = LETTERS_AND_NUMBERS_REGEX.test(pass);
  return !isValid ? { VALID_PASSPHRASE: true } : null;
}

export function sameFieldsValidator(
  control1: string,
  control2: string
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const p1 = control.get(control1)?.value;

    const p2 = control.get(control2)?.value;

    if (!!p1 && !!p2) {
      if (p1 !== p2) {
        return { samePassword: true };
      }
    }

    return null;
  };
}

export function differentPinValidator(
  control1: string,
  control2: string
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const p1 = control.get(control1)?.value;

    const p2 = control.get(control2)?.value;

    if (!!p1 && !!p2) {
      if (p1 == p2) {
        return { DIFFERENT_PIN: true };
      }
    }

    return null;
  };
}

export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

//controlla che la nuova password sia diversa dalla precedente
export function sameOldPassword(control: AbstractControl) {
  const { oldPassword, newPassword } = control.value;

  if (!oldPassword || !newPassword) {
    return null;
  }

  //Errore Se la nuova password è uguale alla precedente
  if (oldPassword == newPassword) {
    return { SAME_OLD_PASSWORD: true };
  } else {
    return null;
  }
}

//controlla che le nuove password inserite siano identiche
export function SameNewAndConfirmPassword(control: AbstractControl) {
  const { newPassword, confirmPassword } = control.value;

  // avoid displaying the message error when values are empty
  if (!newPassword || !confirmPassword) {
    return null;
  }

  //Se la nuove password non sono uguali
  if (newPassword != confirmPassword) {
    return { SAME_PASSWORD: true };
  }
  return null;
}

export function SamePinAndConfirmPin(control: AbstractControl) {
  const { newPin, confirmPin } = control.value;

  // avoid displaying the message error when values are empty
  if (!newPin || !confirmPin) {
    return null;
  }

  if (newPin != confirmPin) {
    return { SAME_PIN: true };
  }
  return null;
}


export const cleanPayload = (text: string = "") => {

  return text
    //non permettere di inserire caratteri speciali
    .replace(CLEAN_ADDRESS_REGEX, '')
    //permetti solo questi caratteri: a-z, A-Z, 0-9, spazio, apostrofo
    //.replace(/[^a-zA-Z0-9\sàèìòù\u0020\u0027]/g, '')
    //sostituisci gli apostrofi con il singolo apice ' (unicode \u0027)
    .replaceAll('’', '\u0027')


}
