<!-- RENEW / SELECT-CERTIFICATE -->
<div class="card">
  <div class="card-body">

    <!-- WARNING MESSAGE -->

    <app-message *ngIf="deprecatedWarning" status="warning" [text]="deprecatedWarning" [hideClose]="true">
    </app-message>

    <app-certificates-list [selectable]="true" (certSelected)="onCertsSelected($event)"
      [autoSelect]="true"></app-certificates-list>
  </div>



  <div class="buttons-row" *ngIf="showProceed ">


    <ng-ui-kit-button (onClick)="proceedRenew()" [disabled]="!selectedCertificates.length"
      [label]="'GLOBALS.PROCEED' | translate" color="primary"></ng-ui-kit-button>
  </div>


</div>

<!-- MODALE LOADING GENERICA -->
<app-modal #loadingModal modalId="loadingModal" [showFooter]="false">

</app-modal>
