import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfig } from '@app/models/config.interface';

import { OwnersType } from '@app/store/models';
import { AppStepsTypes } from '@app/store/models/app.models';
import { GlobalState } from '@app/store/store';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { LoggerService } from './logger.service';


declare global {
  interface Window {
    dikeDebug: {
      isDebug: boolean;
      forceAtr: string;
      enableCosmo: boolean;
      stubDevice: boolean;
      currentStepType: AppStepsTypes;
      expiredDate?: string;
      owner?: OwnersType;
      skipContactsError?: boolean;
    }

  }
}

window.dikeDebug = {
  enableCosmo: false,
  forceAtr: "",
  isDebug: false,
  stubDevice: false,
  currentStepType: undefined,
  expiredDate: "",
  skipContactsError: false,
  owner: undefined
}


/*window.dikeDebug = {
  enableCosmo: false,
  forceAtr: "",
  isDebug: true,
  stubDevice: true,
  currentStepType: undefined,
  expiredDate: "",
  skipContactsError: false,
  owner: "THIRDPARTY"
}*/

const logger = new LoggerService("ConfigService");

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config!: IConfig;
  public get isDebug(): boolean { return !!window.dikeDebug.isDebug; }
  public get forceAtr(): string { return window.dikeDebug.forceAtr }
  public get owner(): OwnersType { return window.dikeDebug.owner }
  public get enableCosmo(): boolean { return window.dikeDebug.enableCosmo }
  public get expiredDate(): string { return window.dikeDebug.expiredDate }
  public get stubDevice(): boolean { return window.dikeDebug.stubDevice }
  public get currentStepType(): AppStepsTypes { return window.dikeDebug.currentStepType }
  constructor(private _httpClient: HttpClient, private store: Store<GlobalState>) {


    console.count('ConfigService');

  }

  loadAppConfig() {
    this.config = {
      ...this.config,
      ...environment,
      env: this.env,
    };
    console.log("🚀 ~ ConfigService ~ loadAppConfig ~ config:", this.config)
    this.config;
  }


  public get config(): IConfig {
    return this._config;
  }

  public set config(value: IConfig) {
    this._config = value;
  }


  public get env(): "cl" | "prod" {

    const isLocalhost = window.location.hostname === "localhost";
    const isCl = window.location.hostname === "rinnovofirmacl.infocert.it";

    return !isLocalhost && !isCl ? "prod" : "cl";
  }

}
