import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgUiKitModule } from '@infocert/ng-ui-kit';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { DebuggerComponent } from './components/debugger/debugger.component';

import { HttpHeaderInterceptor } from './interceptors/http.header.interceptor';
import { LoadingInterceptor } from './interceptors/http.loading.interceptor';
import { HttpPayloadInterceptor } from './interceptors/http.payload.interceptor';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainCardComponent } from './layout/main-card/main-card.component';
import { StepsComponent } from './layout/steps/steps.component';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { LogoComponent } from './components/logo/logo.component';
import { LayoutDefaultComponent } from './layout/LayoutDefault';
import { LayoutFullScreenComponent } from './layout/LayoutFullScreen';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { DeviceLoadingComponent } from './components/device-loading/device-loading.component';
import { DevicesSelectComponent } from './components/devices-select/devices-select.component';
import { registerTranslateExtension } from './components/forms/formly-extension';
import { SameNewAndConfirmPassword, SamePinAndConfirmPin, pinPukValidator, sameOldPassword, strenghtPasswordValidatorFn } from './components/forms/formly-validators';
import { UiKitFieldWrapper } from './components/forms/formly-wrapper-panel';
import { InputFieldType } from './components/forms/input-field-type';


import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { DebugMenuComponent } from './components/debug-menu/debug-menu.component';
import { DeviceTitleComponent } from './components/device-title/device-title.component';
import { UikitRowWrapper } from './components/forms/formly-wrapper-row';
import { CheckboxInputType } from './components/forms/input-checkbox-type';
import { InputPassworddType } from './components/forms/input-password-type';
import { RadioInputType } from './components/forms/input-radio-type';
import { SelectFieldType } from './components/forms/input-select-type';
import { FormlyFieldStepper } from './components/forms/stepper-type';
import { ModalComponent } from './components/modal/modal.component';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { GoPaymentComponent } from './components/substitution/go-payment/go-payment.component';
import { OrderPaidComponent } from './components/substitution/order-paid/order-paid.component';
import { SelectSubstituteComponent } from './components/substitution/select-substitute/select-substitute.component';
import { TranslateHtmlDirective } from './directives/html-translate.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { CertInstalledComponent } from './routes/cert-installed/cert-installed.component';
import { checkFlowComponent } from './routes/check-flow/check-flow.component';
import { RenewComponent } from './routes/renew/renew.component';

import { SelectDeviceComponent } from './routes/select-device/select-device.component';
import { SubstitutionComponent } from './routes/substitution/substitution.component';
import { WelcomePageComponent } from './routes/welcome/welcome.component';
import { ConfigService } from './services/config.service';
import { StateEffects } from './services/state.effects';
import { STORE, metaReducers } from './store/store';

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { CertificateExpireDateComponent } from './components/certificate-expire-date/certificate-expire-date.component';
import { CertificatesListComponent } from './components/certificates-list/certificates-list.component';
import { CustomFormlyGroupComponent } from './components/forms/formly-custom-group';
import { PrivacyFormlyGroupComponent } from './components/forms/formly-privacy-group';
import { RadioCertType } from './components/forms/input-radio-certType';
import { MainLoadingComponent } from './components/main-loading/main-loading.component';
import { MessageComponent } from './components/message/message.component';
import { SelectCertificatesComponent } from './components/renew/select-certificates/select-certificates.component';
import { SubsitutionCompleteTitleComponent } from './components/substitution/complete-title/complete-title.component';
import { EolComponent } from './components/substitution/eol/eol.component';
import { NoSpecialCharsDirective } from './directives/no-special-chars.directive';
import { ThirdPartyPipe } from './directives/third-party.pipe';
import { hasUrlParam } from './helpers/helpers';
import { LoadingComponent } from './layout/loading/loading.component';
import { CompletedComponent } from './routes/completed/completed.component';


export const LANGUAGES_API_URL =
  'https://lcert-lokalise-pr-bucket.s3.eu-west-1.amazonaws.com/rennovation/';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient, htppBackend: HttpBackend) {
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');

  return new MultiTranslateHttpLoader(htppBackend, [
    //Load Local translations
    new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'),
    //Try to load Online S3 lokalise translations
    new TranslateHttpLoader(
      httpClient,
      `${LANGUAGES_API_URL}`,
      '.json?cb=' + new Date().getTime() //skip cache
    ),
  ]);
}


export function getBaseHref(platformLocation: PlatformLocation): string {
  const href = platformLocation.getBaseHrefFromDOM();
  console.log("baes href", href);
  return href
}

// @Injectable()
// export class MyMissingTranslationHandler implements MissingTranslationHandler {
//   handle(params: MissingTranslationHandlerParams) {
//     return this.nts.instant(params.key);
//   }

//   constructor(private nts: NotTranslatedService) { }
// }

const PAGES_COMPONENTS = [
  WelcomePageComponent,


  checkFlowComponent,
  RenewComponent,

  DeviceLoadingComponent,
  DevicesSelectComponent,
  HeaderComponent,
  FooterComponent,
  LayoutDefaultComponent,
  LayoutFullScreenComponent
];

const SUBSTITUTION_COMPONENTS = [
  SubstitutionComponent,
  SelectSubstituteComponent,
  GoPaymentComponent,
  OrderPaidComponent,
  CertInstalledComponent,
  CompletedComponent,
  EolComponent,
]

const FORMLY_COMPONENTS = [
  InputFieldType,
  CheckboxInputType,
  InputPassworddType,
  RadioInputType,
  RadioCertType,
  FormlyFieldStepper,
  UiKitFieldWrapper,
  UikitRowWrapper,
  SelectFieldType,
  OnlyNumbersDirective,
  NoSpecialCharsDirective
];


const COMMONS_COMPONENTS = [
  SelectDeviceComponent,
  CertificateComponent,
  MainCardComponent,
  StepsComponent,
  ModalComponent,
  StatusCardComponent,
  StatusIconComponent,
  DeviceTitleComponent,
  DebugMenuComponent,
  DebuggerComponent,
  LoadingComponent,
  MessageComponent,
  LogoComponent,
  StatusIconComponent,
  StatusCardComponent,
  CertificatesListComponent,
  ThirdPartyPipe

];

const Interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpPayloadInterceptor,
    multi: true,
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: HttpResponseInterceptor,
  //   multi: true,
  // },
]

const Providers = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ConfigService],
    useFactory: (configService: ConfigService) => () => configService.loadAppConfig()
  },
  {
    provide: APP_BASE_HREF,
    useFactory: getBaseHref,
    deps: [PlatformLocation]
  },
  {
    provide: FORMLY_CONFIG,
    multi: true,
    useFactory: registerTranslateExtension,
    deps: [TranslateService],
  },

  ...Interceptors
]


@NgModule({
  declarations: [
    AppComponent,
    TranslateHtmlDirective,
    ...PAGES_COMPONENTS,
    ...SUBSTITUTION_COMPONENTS,
    ...FORMLY_COMPONENTS,
    ...COMMONS_COMPONENTS,
    CertificateExpireDateComponent,
    SelectCertificatesComponent,
    MainLoadingComponent,
    SubsitutionCompleteTitleComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: hasUrlParam('THIRDPARTY') ? 'THIRDPARTY' : 'INFOCERT',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        //useClass: HttpTranslationLoader,
        deps: [HttpClient, HttpBackend],
      },
      // missingTranslationHandler: {
      //   provide: MissingTranslationHandler,
      //   useClass: MyMissingTranslationHandler,
      //   deps: [NotTranslatedService],
      // },
    }),
    NgUiKitModule,

    EffectsModule.forRoot([StateEffects]),

    NgxSmartModalModule.forRoot(),
    StoreModule.forRoot(STORE, { metaReducers }),
    NgxJsonViewerModule,

    FormlySelectModule,
    FormlyModule.forRoot({
      extras: { renderFormlyFieldElement: false },
      // validationMessages: [
      //   { name: 'required', message: 'This field is required' },
      // ],
      // extensions: [
      //   {
      //     name: 'default-base-input',
      //     extension: new defaultBaseInputExtension(),
      //   },
      // ],

      validators: [
        { name: 'PASSWORD_STRENGHT', validation: strenghtPasswordValidatorFn },

        { name: 'PIN_PUK_VALIDATOR', validation: pinPukValidator },
        { name: 'SAME_PASSWORD', validation: SameNewAndConfirmPassword },
        { name: 'SAME_PIN', validation: SamePinAndConfirmPin },
        { name: 'SAME_OLD_PASSWORD', validation: sameOldPassword },
      ],

      wrappers: [
        { name: 'UiKitWrapper', component: UiKitFieldWrapper },
        { name: 'formly-wrapper-row', component: UikitRowWrapper },
        { name: 'custom-group', component: CustomFormlyGroupComponent },
        { name: 'privacy-group', component: PrivacyFormlyGroupComponent },
      ],
      types: [
        {
          name: 'ic1-text',
          component: InputFieldType,
          // wrappers: ['UiKitWrapper'],

          defaultOptions: {
            props: {
              //translate: true,
            },
            // className: 'ic1-form-group ',
          },
        },
        {
          name: 'ic1-password',
          component: InputPassworddType,
          extends: 'ic1-text',
        },
        {
          name: 'ic1-radio',
          component: RadioInputType
        },
        {
          name: 'ic1-radio-certType',
          component: RadioCertType
        },
        {
          name: 'ic1-checkbox',
          component: CheckboxInputType
        },
        {
          name: 'formly-field-select',
          component: SelectFieldType
        },
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },


      ],
      presets: [],
    }),
    FormlyBootstrapModule,
  ],
  providers: [...Providers],
  bootstrap: [AppComponent]
})
export class AppModule {


  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nessuna voce trovata';
    this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.config.bindValue = 'value';

  }
}
