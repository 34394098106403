import { Component, OnInit } from '@angular/core';
import { Device } from '@app/store/models';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-device-title',
  templateUrl: './device-title.component.html',
  styleUrls: ['./device-title.component.scss']
})
export class DeviceTitleComponent implements OnInit {

  $deviceSelected: Observable<Device>

  constructor(private store: Store<GlobalState>,) { }

  ngOnInit(): void {
    this.$deviceSelected = this.store.select(selectDevice)
  }

}
