// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { IConfig } from '@app/models/config.interface';

export const environment: IConfig = {
  isProduction: false,
  env: 'cl',
  defaultLanguage: 'INFOCERT',
  prod: {
    apiHost: 'https://rinnovofirma.infocert.it/',
    shopDomain: 'https://ecommerce.infocert.it',
  },
  cl: {
    apiHost: 'https://rinnovofirmacl.infocert.it/',
    shopDomain: 'https://ecommercetempcl.infocert.it',
  },
  dikeUrls: {
    dikeClientRest: "/dike-client-rest",
    dikeIOSimple: "/dike-io-basic",
    dikeWebRest: "/dike-web-rest",
    shopParams: "/web/portale/carrello?p_p_id=payment_WAR_infocertecommercecarrello10&p_p_lifecycle=0&guestUserId={guestUserId}&guestCartAppId={guestCartId}&guestCartRedirectUrl={redirectUrl}"
  }
};

export default environment;
