import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log('HTTP', req.method, req.urlWithParams);
    const contentType =
      req.headers.get('Content-Type') || 'application/x-www-form-urlencoded';
    //https://stackoverflow.com/a/48683560
    const request = req.clone({
      setHeaders: {
        'Content-Type': contentType,
      },
    });

    return next.handle(request);
  }
}
