import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[translate-html]'
})
export class TranslateHtmlDirective implements OnInit {
  @Input('translate-html') key!: string;

  constructor(private el: ElementRef, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(this.key).subscribe((translation: string) => {
      this.el.nativeElement.innerHTML = translation;
    });
  }
}
