
export interface AppModels {
  isDebug?: boolean;
  mainLoading?: boolean;
  deviceLoading?: boolean;
  deviceLoaded?: boolean;
  deviceSelected?: boolean;
  loadingMessage?: string;
  deviceError?: string;
  mainStep?: MainStep;
  currentFlow?: AppFlow
  currentStep?: AppStep;
  deviceATR?: string;
}



export type AppSteps = Record<MainStep, AppStep[]>

export type AppFlow = AppStep[];

export type MainStep = "renew" | "substitution" | "select_device";
export interface AppStep {
  mainStep: MainStep;
  step: AppStepsTypes;
  stepIndex: number;
  label: string;
  hide?: boolean
}


export const SubstituteStepsArray = ["select_device", "select_cert", "go_payment", "order_paid", "cert_installed", "eol"] as const

export type SubstituteStepsTypes = typeof SubstituteStepsArray[number]

export const RenewStepsArray = ["select_device", "select_cert", "go_payment", "order_paid", "cert_installed", "eol"] as const
export type RenewStepsTypes = typeof RenewStepsArray[number]

export type AppStepsTypes = SubstituteStepsTypes | RenewStepsTypes;

export type SubstituteStep = Record<SubstituteStepsTypes, any>

export type Sizes = "small" | "medium" | "large"

export type BadgeColor = "success" | "error" | "warning" | "info"
