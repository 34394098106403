import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { cleanPayload } from '@app/components/forms/formly-validators';

@Directive({
  selector: '[NoSpecialChars]'
})

export class NoSpecialCharsDirective {
  constructor(private _el: ElementRef) { }

  @Input() NoSpecialChars: number | boolean = false;

  @HostListener('input', ['$event']) onInputChange(event: any) {
    if (this.NoSpecialChars) {
      const inputEl = this._el.nativeElement?.querySelector('input');

      const initalValue: string = inputEl.value + "";
      if (inputEl) {
        inputEl.value = cleanPayload(initalValue)

        if (initalValue !== inputEl.value) {
          event.stopPropagation();
        }
      }
    }
  }
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (this.NoSpecialChars) {
      // Accedi ai dati del clipboard tramite ClipboardEvent
      const pastedData = event.clipboardData?.getData('text/plain');

      if (pastedData) {
        // Rimuovi i caratteri speciali dalla stringa incollata
        const cleanedData = cleanPayload(pastedData)

        // Imposta il valore pulito nell'input
        const inputEl = this._el.nativeElement?.querySelector('input');
        if (inputEl) {
          inputEl.value = cleanedData;

          event.preventDefault(); // Impedisci l'operazione di incolla predefinita

          // Forza il controllo del form per segnalare manualmente la modifica
          const form = inputEl?.form;
          if (form) {
            setTimeout(() => {
              form.dispatchEvent(new Event('change'));
              inputEl.dispatchEvent(new Event('input'));
            }, 500)
          }
        }
      }
    }
  }
}
