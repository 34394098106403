import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-row',
  template: `
  <div class="ic1-row"><div class="ic1-col-6 form-group"><ng-container #fieldComponent></ng-container></div></div>
  `,
})
export class UikitRowWrapper extends FieldWrapper {

}
