import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jsonSerialize } from '@app/helpers/helpers';
import { Contacts, DikeTransactionPayload, RenewStatusResponse, RenewTypeResponse } from '@app/store/models/certificate.model';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Observable, catchError, firstValueFrom, tap, throwError } from 'rxjs';
import { ConfigService } from './config.service';
import { DikeService } from './dike.service';
import { LoggerService } from './logger.service';
import { DikeTransaction, GuestCartResponse } from './service.models';


const logger = new LoggerService("DikerWebService");
@Injectable({
  providedIn: 'root'
})
export class DikerWebService {




  constructor(private configService: ConfigService, private http: HttpClient, private translateService: TranslateService, private dikeService: DikeService, private router: Router, @Inject(APP_BASE_HREF) public baseHref: string) {
  }

  get basePathUrl() {

    return this?.configService?.config?.dikeUrls?.dikeWebRest + '/dike/api/web/renew/';
  }


  get defaultHeadersFormUrlencoded(): HttpHeaders {

    return new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .set('X-Requested-By', 'angular');
  }


  get defaultHeadersApplicationJson(): HttpHeaders {

    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("accept", "*/*")
      .set('X-Requested-By', 'angular');
  }

  /*
   * dike/api/web/renew/<certId>/renew-status
   * */
  checkRenewStatus({ certId, certB64, pData, serialSmartCard, atr }: { certId: string, certB64: any, pData: any, serialSmartCard: string, atr: string }): Observable<RenewStatusResponse> {

    var url = this.basePathUrl + 'cert/' + certId + '/renew-status';

    var data = {
      certB64: certB64,
      pdata: pData,
      tokenId: serialSmartCard + ':' + atr
    };
    //const body = jsonToFormData(data)
    const body: URLSearchParams = jsonSerialize(data)

    let headers = cloneDeep(this.defaultHeadersFormUrlencoded).append('isFormData', '1');

    return this.http.post<RenewStatusResponse>(url, body, { headers });
  }

  async defaultcontacts({ certId }: { certId: string }): Promise<Contacts> {

    var url = this.basePathUrl + 'cert/' + certId + '/defaultcontacts';


    let headers = cloneDeep(this.defaultHeadersApplicationJson)


    try {
      const response = await firstValueFrom(this.http.get<Contacts>(url, { headers }).pipe(
        catchError(reason => {
          throw reason
        })
      ));

      return response;
    } catch (error) {
      throw error;
    }

  }

  updateContacts(certId: string, email: string, mobile: string) {

    var url = this.basePathUrl + 'cert/' + certId + '/contacts';

    var raw = 'email=' + email + '&mobile=' + mobile.replace('+', '%2B');

    //const body = jsonToFormData(data)
    const body = raw

    let headers = this.defaultHeadersFormUrlencoded;

    return this.http.post<any>(url, body, { headers }).pipe(
      tap((response: HttpResponse<any>) => {
        return response

      }),
      catchError((response: HttpResponse<any>) => {
        const statusCode = response.status;

        let errorMessage = '';
        switch (statusCode) {

          case 401:
            errorMessage = this.translateService.instant('ERRORS.CONTACTS.STATUS_401');
            this.decline(certId);
            break;

          //406 invalid email & mobile
          case 406:
            errorMessage = this.translateService.instant(
              'ERRORS.CONTACTS.STATUS_406'
            );

            break;

          //410 invalid email
          case 410:
            errorMessage = this.translateService.instant(
              'ERRORS.CONTACTS.STATUS_410'
            );

            break;

          //409 invalid mobile
          case 409:
            errorMessage = this.translateService.instant(
              'ERRORS.CONTACTS.STATUS_409'
            );

            break;

          //200 TUTTO ok
          case 200:
            errorMessage = '';

            break;

          default:
            errorMessage = this.translateService.instant(
              'ERRORS.CONTACTS.GENERIC_ERROR'
            );
            break;
        }


        logger.error(errorMessage);
        return throwError(() => new Error(errorMessage)); // Utilizza throwError per lanciare l'errore

      })
    )

  }



  /*
   * dike/api/web/renew/<certId>/renew-type
   * */
  checkRenewType(certId: string): Observable<RenewTypeResponse> {

    var url = this.basePathUrl + 'cert/' + certId + '/renew-type';

    let headers = this.defaultHeadersApplicationJson;

    //return of(StubCosmoContacts)
    return this.http.get<RenewTypeResponse>(url, { headers })
  }



  startDikesTransaction(certId, payload: DikeTransactionPayload) {
    const stringified = JSON.stringify(payload);
    const utf8Str = unescape(encodeURIComponent(stringified));

    const base64data = window.btoa(utf8Str);
    const excaped = window.atob(base64data);
    console.log("🚀 ~ DikerWebService ~ startDikesTransaction :", { payload, stringified, base64data, excaped })

    const url = this.basePathUrl + 'cert/' + certId + '/startDikesTransaction';

    const body = 'data=' + base64data;

    let headers = this.defaultHeadersFormUrlencoded;

    return this.http.post<DikeTransaction>(url, body, { headers });

  };

  getDikeResult(certId: string, ioSessionID: string): Observable<string> {
    const url = this.basePathUrl + 'cert/' + certId + `/getDikesResult?sessionId=${ioSessionID}`;

    return this.http.get(url, {
      responseType: 'text',
      headers: {
        'X-Requested-By': 'angular'
      }
    });
  }

  pialla(certId: string = 'RNDMSM63P28D708P:2023111114397:C7D229C231A9C3970542FA77FF39EC7B71A6E169:179901') {

    const headers = new HttpHeaders({
      'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:91.0) Gecko/20100101 Firefox/91.0',
      Accept: 'application/json, text/plain, */*',
      'Accept-Language': 'it-IT,it;q=0.8,en;q=0.5,en-US;q=0.3',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-By': 'angular',
      Origin: 'https://rinnovofirmacl.infocert.it',
    });

    const requestOptions = {
      headers,
      responseType: 'text' as const,
      observe: 'response' as const,
    };

    const url = `${this.basePathUrl}cert/${certId}/pialla`;

    return this.http.get(url, requestOptions)
  }

  payShop(certId: string) {

    const headers = new HttpHeaders({
      'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:91.0) Gecko/20100101 Firefox/91.0',
      Accept: 'application/json, text/plain, */*',
      'Accept-Language': 'it-IT,it;q=0.8,en;q=0.5,en-US;q=0.3',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-By': 'angular',
      Origin: 'https://rinnovofirmacl.infocert.it',
    });

    const requestOptions = {
      headers,
      responseType: 'text' as const,
      observe: 'response' as const,
    };

    const url = `${this.basePathUrl}renew/shop/payment-update?provincia=CO&emailNotifica=antonioporta@legalmail.it&fART=BK-PVPD-R-SITO&fQTA=0&fQTAlotto=null&userid=MK9758&fep=IC3&idOrdine=55-86614-010444116&cliBaan=null&valoreAss=PRTNTN72D20D416B:20205063901532:0CD3AFD29F41F9321B7BC5DD3A106868E9BEE23A:19027759`;
    return this.http.get(url, requestOptions)
  }

  renew(certId: string) {

    /*
    payload = {
                firstName: certificate.given_name,
                lastName: certificate.surname,
                fiscalCode: certificate.cf,
                legalNotice: $scope.getTranslation('cosmov7.legalNotice'),

                productType: $scope.cosmoForm.productType,
                email: $scope.cosmoForm.email,
                mobile: $scope.cosmoForm.mobile,
                street: '',
                number: '',
                city: '',
                country: '',
                zipCode: '',
              };
              console.log('🚀 ~ payload', payload);


    */

    //return this.http.post<RenewStatusResponse>(url, body, { headers });

  }

  /*
  * dike/api/web/renew/guest-cart
  * */
  async createGuestCart(certIds: string[], deviceATR: string, discountCode: any = ""): Promise<{ shopRedirectUrl: string }> {


    const url = this.basePathUrl + 'guest-cart';

    let data: any = {
      'certs': certIds.map(certId => ({ certId })),
      //discountCode
    };


    const headers = new HttpHeaders({
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json;charset=UTF-8',
      'x-requested-by': 'angular'
    });


    try {
      const response = await firstValueFrom(this.http.post<GuestCartResponse>(url, data, { headers }).pipe(
        catchError(reason => {
          throw reason
        })
      ));

      const { guestCartId, guestUserId } = response;
      const shopRedirectUrl = this.getShopDestinationUrl(guestUserId, "DIKER", deviceATR);

      return { shopRedirectUrl };
    } catch (error) {
      throw error;
    }

  }


  getShopDestinationUrl(guestUserId, guestCartID = "DIKER", deviceATR = "1") {

    const shopDomain = this?.configService?.config[this.configService.env].shopDomain;
    const shopRedirectUrl = shopDomain + this.configService.config.dikeUrls.shopParams;

    //http://localhost:4200/dike-web-renew-ui/renew/checkDevice/selectDevice?deviceATR=3bff1800008131fe55006b02091505010101434e5310318072
    const redirectUrl = `${location.origin}${this.baseHref}?deviceATR=${deviceATR}`

    const targetUrl = shopRedirectUrl.replace('{guestCartId}', guestCartID).replace('{guestUserId}', guestUserId).replace('{redirectUrl}', redirectUrl);


    logger.log("🚀 ~ DikerWebService ~ getShopDestinationUrl ~ shopRedirectUrl:", { guestCartID, guestUserId, shopRedirectUrl, targetUrl, redirectUrl })

    return targetUrl;
  }


  async couponPayment(certId: string, coupon: string, isCns = false) {
    const data = {
      coupon,
      thereIsCnsOnTk: isCns //Se di tipo cns
    };
    logger.log('couponPayment data:', data);

    const url = `${this.basePathUrl}cert/${certId}/coupon-payment`;

    const headers = this.defaultHeadersFormUrlencoded;

    const params = new HttpParams({ fromObject: data });

    try {
      const response = await firstValueFrom(this.http.post(url, params.toString(), { headers }).pipe(
        catchError(reason => {
          if (reason.status === 400) {
            throw new Error('Codice coupon non valido');
          } else {
            throw new Error('Errore sconosciuto');
          }
        })
      ));

      return response;
    } catch (error) {
      throw error;
    }
  }

  async emitTRISS(certId: string, pin = "") {

    const url = `${this.basePathUrl}cert/${certId}/emit`;
    const body = 'pin=' + pin;
    const headers = this.defaultHeadersFormUrlencoded;
    try {
      const response = await firstValueFrom(this.http.post(url, body, { headers }).pipe(
        catchError(reason => {
          throw reason
        })
      ));

      return response;
    } catch (error) {
      throw error;
    }
  }


  async emitCARA(certId: string) {

    const url = `${this.basePathUrl}cert/${certId}/carareq`;

    const headers = this.defaultHeadersFormUrlencoded;
    try {
      const response = await firstValueFrom(this.http.post(url, {}, { headers }).pipe(
        catchError(reason => {
          throw reason
        })
      ));


      return response;
    } catch (error) {
      throw error;
    }
  }


  async decline(certId: string) {

    const url = `${this.basePathUrl}cert/${certId}/declinecosmo`;

    const headers = this.defaultHeadersFormUrlencoded;
    try {
      const response = await firstValueFrom(this.http.get(url, { headers }).pipe(
        catchError(reason => {
          throw reason
        })
      ));

      return response;
    } catch (error) {
      throw error;
    }
  }

  /*
  * dike/api/web/renew/<certId>/rao-payment
  *
 */
  async RaoPayment(certId: string, username: string, password: string,) {

    const data = {
      username,
      password
    };
    logger.log('couponPayment data:', data);

    const url = `${this.basePathUrl}cert/${certId}/rao-payment`;

    const headers = this.defaultHeadersFormUrlencoded;

    const params = new HttpParams({ fromObject: data });

    try {
      const response = await firstValueFrom(this.http.post(url, params.toString(), { headers }).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            throw new Error('Credenziali RAO non valide')
          } else if (error.status === 403) {
            throw new Error('L\'utenza RAO non dispone dei profili necessari al rinnovo dei certificati')
          } else {
            throw new Error(error?.message || 'Errore sconosciuto')
          }
        })
      ));

      return response;
    } catch (error) {
      throw error;
    }

  }

  /*
   * dike/api/web/renew/<certId>/coupon-payment
   *
   * certId: identificativo del certificato che si vuole 'pagare'
   * spec: {code :valore coupon}
   * */
  // couponPayment (certId: string, spec: { code: any; thereIsCnsOnToken: any; }) {
  //   var data = {
  //     coupon: spec.code,
  //     thereIsCnsOnTk: spec.thereIsCnsOnToken
  //   };


  //   var url = basePath + 'cert/' + certId + '/coupon-payment';

  //   var call = $http({
  //     method: 'POST',
  //     url: url,
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //       'X-Requested-By': 'angular'
  //     },
  //     data: $.param(data)
  //   });

  //   var defer = $q.defer();

  //   $q.when(call).then(
  //     function (result: any) {
  //       defer.resolve(result);
  //     }, function (reason: { status: string; }) {
  //       if (reason.status == '400') {
  //         defer.reject({ message: 'Codice coupon non valido' });
  //       } else {
  //         defer.reject({ message: 'Errore sconosciuto' });
  //       }
  //     }
  //   )

  //   return defer.promise;
  // }



}

