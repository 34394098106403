import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpPayloadInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let body = req.body;

    if (
      req.body &&
      req.headers.get('Content-Type') === 'application/x-www-form-urlencoded' &&
      !req.headers.has('isFormData')

    ) {
      body = new HttpParams({
        fromObject: req.body,
      }).toString();
    }

    //console.log('HTTP', req.method, req.urlWithParams);

    const request = req.clone({
      body,
    });

    return next.handle(request);
  }
}
