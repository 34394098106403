<div class="main-card">
  <div class="card-header">
    <ng-content select="[header]"> </ng-content>
  </div>

  <ng-ui-kit-separator class="full ic1-my-20"></ng-ui-kit-separator>

  <div class="card-content">
    <ng-content></ng-content>
  </div>

  <div class="card-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
