<div class="certificate-list">

  <div class="ic1-text-body-1">
    <span translate>GLOBALS.CERTIFICATES.FOUND_ON_DEVICE</span>
    <span> ({{($device | async)?.certificates?.length}})</span>
  </div>


  <app-certificate *ngFor="let certificate of ($device | async)?.certificates" [viewMode]="viewMode"
    (certSelected)="onChangeCheckbox($event)" [certificate]="certificate" [selectable]="selectable"
    [autoSelect]="autoSelect"></app-certificate>

</div>
