import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { hasUrlParam } from '@app/helpers/helpers';
import { DikerWebService } from '@app/services/diker-web.service';
import { GlobalState, resetGlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomePageComponent implements OnInit {


  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store<GlobalState>,
    private dikerWebService: DikerWebService
  ) { }

  ngOnInit(): void {

  }

  get hasChangedFromParam() {
    return hasUrlParam('THIRDPARTY');
  }

  goSelectDevice() {
    this.store.dispatch(resetGlobalState());
    this.router.navigate([ROUTE_PATHS.checkDevice]);
  }


}
