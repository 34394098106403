import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Capitalize } from '@app/helpers/helpers';
import { selectOwner } from '@app/store/selectors/customization.selectors';
import { GlobalState } from '@app/store/store';
import { Brand, BreadcrumbItem, DropdownItem } from '@infocert/ng-ui-kit/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { Observable, of, tap } from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit {
  constructor(

    private router: Router,
    private store: Store<GlobalState>

  ) { }

  brandName: string = 'Infocert';
  brand: Brand = {
    name: "InfoCert",
    product: 'Firma digitale',
    style: 'default',
  }


  //breadcrumbData: BreadcrumbItem[] = [{ label: "InfoCert++" }, { label: "Rinnovo certificati di Firma Digitale++" }]

  currentUrl: string = '';
  /* avatar$: Observable<string>; */
  languages$: Observable<DropdownItem[]> = of([]);


  @ViewChild('ChangePwdModal') _ChangePwdModalRef: TemplateRef<any> | undefined;

  ngOnInit(): void {

    this.store.select(selectOwner)
      .pipe(
        untilDestroyed(this),
        tap(owner => {
          this.brandName = Capitalize(owner);
        })
      )
      .subscribe();

  }


  onTitleClicked(): void {

    this.router.navigate(['/homepage']);
  }

  get breadcrumbData(): BreadcrumbItem[] {

    return [{ label: this.brandName }, { label: "Rinnovo certificati di Firma Digitale" }]
  }
}
