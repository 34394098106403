import { map } from 'lodash';

export const Provinces = [
  {
    "sigla": "AG",
    "provincia": "Agrigento",
    "superficie": 3052.59,
    "residenti": 446081,
    "num_comuni": 43,
    "istat": "084",
    "regione": "Sicilia"
  },
  {
    "sigla": "AL",
    "provincia": "Alessandria",
    "superficie": 3558.83,
    "residenti": 427354,
    "num_comuni": 187,
    "istat": "006",
    "regione": "Piemonte"
  },
  {
    "sigla": "AN",
    "provincia": "Ancona",
    "superficie": 1963.22,
    "residenti": 475495,
    "num_comuni": 47,
    "istat": "042",
    "regione": "Marche"
  },
  {
    "sigla": "AP",
    "provincia": "Ascoli Piceno",
    "superficie": 1228.23,
    "residenti": 210711,
    "num_comuni": 33,
    "istat": "044",
    "regione": "Marche"
  },
  {
    "sigla": "AQ",
    "provincia": "L'Aquila",
    "superficie": 5034.46,
    "residenti": 300774,
    "num_comuni": 108,
    "istat": "066",
    "regione": "Abruzzo"
  },
  {
    "sigla": "AO",
    "provincia": "Aosta",
    "superficie": 3260.9,
    "residenti": 127844,
    "num_comuni": 74,
    "istat": "02",
    "regione": "Valle D'aosta"
  },
  {
    "sigla": "AR",
    "provincia": "Arezzo",
    "superficie": 3233.08,
    "residenti": 344437,
    "num_comuni": 36,
    "istat": "051",
    "regione": "Toscana"
  },
  {
    "sigla": "AT",
    "provincia": "Asti",
    "superficie": 1510.78,
    "residenti": 217978,
    "num_comuni": 118,
    "istat": "005",
    "regione": "Piemonte"
  },
  {
    "sigla": "AV",
    "provincia": "Avellino",
    "superficie": 2791.64,
    "residenti": 428523,
    "num_comuni": 118,
    "istat": "064",
    "regione": "Campania"
  },
  {
    "sigla": "BA",
    "provincia": "Bari",
    "superficie": 3825.41,
    "residenti": 1246297,
    "num_comuni": 41,
    "istat": "072",
    "regione": "Puglia"
  },
  {
    "sigla": "BG",
    "provincia": "Bergamo",
    "superficie": 2722.86,
    "residenti": 1094062,
    "num_comuni": 243,
    "istat": "016",
    "regione": "Lombardia"
  },
  {
    "sigla": "BI",
    "provincia": "Biella",
    "superficie": 917.3,
    "residenti": 181426,
    "num_comuni": 74,
    "istat": "096",
    "regione": "Piemonte"
  },
  {
    "sigla": "BL",
    "provincia": "Belluno",
    "superficie": 3678.02,
    "residenti": 209364,
    "num_comuni": 61,
    "istat": "025",
    "regione": "Veneto"
  },
  {
    "sigla": "BN",
    "provincia": "Benevento",
    "superficie": 2070.63,
    "residenti": 283651,
    "num_comuni": 78,
    "istat": "062",
    "regione": "Campania"
  },
  {
    "sigla": "BO",
    "provincia": "Bologna",
    "superficie": 3702.41,
    "residenti": 990681,
    "num_comuni": 55,
    "istat": "037",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "BR",
    "provincia": "Brindisi",
    "superficie": 1839.53,
    "residenti": 399835,
    "num_comuni": 20,
    "istat": "074",
    "regione": "Puglia"
  },
  {
    "sigla": "BS",
    "provincia": "Brescia",
    "superficie": 4784.36,
    "residenti": 1247192,
    "num_comuni": 205,
    "istat": "017",
    "regione": "Lombardia"
  },
  {
    "sigla": "BT",
    "provincia": "Barletta-Andria-Trani",
    "superficie": 1538.68,
    "residenti": 392446,
    "num_comuni": 10,
    "istat": "110",
    "regione": "Puglia"
  },
  {
    "sigla": "BZ",
    "provincia": "Bolzano",
    "superficie": 7399.92,
    "residenti": 509626,
    "num_comuni": 116,
    "istat": "021",
    "regione": "Trentino Alto Adige"
  },
  {
    "sigla": "CA",
    "provincia": "Cagliari",
    "superficie": 1248.68,
    "residenti": 431657,
    "num_comuni": 17,
    "istat": "092",
    "regione": "Sardegna"
  },
  {
    "sigla": "CB",
    "provincia": "Campobasso",
    "superficie": 2908.8,
    "residenti": 226217,
    "num_comuni": 84,
    "istat": "070",
    "regione": "Molise"
  },
  {
    "sigla": "CE",
    "provincia": "Caserta",
    "superficie": 2639.38,
    "residenti": 908784,
    "num_comuni": 104,
    "istat": "061",
    "regione": "Campania"
  },
  {
    "sigla": "CH",
    "provincia": "Chieti",
    "superficie": 2588.35,
    "residenti": 389053,
    "num_comuni": 104,
    "istat": "069",
    "regione": "Abruzzo"
  },
  {
    "sigla": "CL",
    "provincia": "Caltanissetta",
    "superficie": 2128.45,
    "residenti": 272458,
    "num_comuni": 22,
    "istat": "085",
    "regione": "Sicilia"
  },
  {
    "sigla": "CN",
    "provincia": "Cuneo",
    "superficie": 6902.68,
    "residenti": 589102,
    "num_comuni": 247,
    "istat": "004",
    "regione": "Piemonte"
  },
  {
    "sigla": "CO",
    "provincia": "Como",
    "superficie": 1308.96,
    "residenti": 592504,
    "num_comuni": 148,
    "istat": "013",
    "regione": "Lombardia"
  },
  {
    "sigla": "CR",
    "provincia": "Cremona",
    "superficie": 1770.57,
    "residenti": 361812,
    "num_comuni": 113,
    "istat": "019",
    "regione": "Lombardia"
  },
  {
    "sigla": "CS",
    "provincia": "Cosenza",
    "superficie": 6649.96,
    "residenti": 714281,
    "num_comuni": 150,
    "istat": "078",
    "regione": "Calabria"
  },
  {
    "sigla": "CT",
    "provincia": "Catania",
    "superficie": 3552.2,
    "residenti": 1077113,
    "num_comuni": 58,
    "istat": "087",
    "regione": "Sicilia"
  },
  {
    "sigla": "CZ",
    "provincia": "Catanzaro",
    "superficie": 2391.35,
    "residenti": 359716,
    "num_comuni": 80,
    "istat": "079",
    "regione": "Calabria"
  },
  {
    "sigla": "EN",
    "provincia": "Enna",
    "superficie": 2561.86,
    "residenti": 172413,
    "num_comuni": 20,
    "istat": "086",
    "regione": "Sicilia"
  },
  {
    "sigla": "FC",
    "provincia": "Forlì-Cesena",
    "superficie": 2376.8,
    "residenti": 392817,
    "num_comuni": 30,
    "istat": "040",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "FE",
    "provincia": "Ferrara",
    "superficie": 2631.82,
    "residenti": 352723,
    "num_comuni": 21,
    "istat": "038",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "FG",
    "provincia": "Foggia",
    "superficie": 6966.17,
    "residenti": 628221,
    "num_comuni": 61,
    "istat": "071",
    "regione": "Puglia"
  },
  {
    "sigla": "FI",
    "provincia": "Firenze",
    "superficie": 3514.38,
    "residenti": 987354,
    "num_comuni": 41,
    "istat": "048",
    "regione": "Toscana"
  },
  {
    "sigla": "FM",
    "provincia": "Fermo",
    "superficie": 859.5,
    "residenti": 175143,
    "num_comuni": 40,
    "istat": "109",
    "regione": "Marche"
  },
  {
    "sigla": "FR",
    "provincia": "Frosinone",
    "superficie": 3243.89,
    "residenti": 493229,
    "num_comuni": 91,
    "istat": "060",
    "regione": "Lazio"
  },
  {
    "sigla": "GE",
    "provincia": "Genova",
    "superficie": 1838.47,
    "residenti": 851283,
    "num_comuni": 67,
    "istat": "010",
    "regione": "Liguria"
  },
  {
    "sigla": "GO",
    "provincia": "Gorizia",
    "superficie": 466.02,
    "residenti": 140650,
    "num_comuni": 25,
    "istat": "031",
    "regione": "Friuli Venezia Giulia"
  },
  {
    "sigla": "GR",
    "provincia": "Grosseto",
    "superficie": 4504.29,
    "residenti": 220982,
    "num_comuni": 28,
    "istat": "053",
    "regione": "Toscana"
  },
  {
    "sigla": "IM",
    "provincia": "Imperia",
    "superficie": 1155.91,
    "residenti": 214290,
    "num_comuni": 66,
    "istat": "008",
    "regione": "Liguria"
  },
  {
    "sigla": "IS",
    "provincia": "Isernia",
    "superficie": 1528.88,
    "residenti": 87124,
    "num_comuni": 52,
    "istat": "094",
    "regione": "Molise"
  },
  {
    "sigla": "KR",
    "provincia": "Crotone",
    "superficie": 1716.58,
    "residenti": 171666,
    "num_comuni": 27,
    "istat": "101",
    "regione": "Calabria"
  },
  {
    "sigla": "LC",
    "provincia": "Lecco",
    "superficie": 816.17,
    "residenti": 338425,
    "num_comuni": 84,
    "istat": "097",
    "regione": "Lombardia"
  },
  {
    "sigla": "LE",
    "provincia": "Lecce",
    "superficie": 2759.4,
    "residenti": 798891,
    "num_comuni": 96,
    "istat": "075",
    "regione": "Puglia"
  },
  {
    "sigla": "LI",
    "provincia": "Livorno",
    "superficie": 1212.43,
    "residenti": 335631,
    "num_comuni": 19,
    "istat": "049",
    "regione": "Toscana"
  },
  {
    "sigla": "LO",
    "provincia": "Lodi",
    "superficie": 782.2,
    "residenti": 225798,
    "num_comuni": 60,
    "istat": "098",
    "regione": "Lombardia"
  },
  {
    "sigla": "LT",
    "provincia": "Latina",
    "superficie": 2250.44,
    "residenti": 552090,
    "num_comuni": 33,
    "istat": "059",
    "regione": "Lazio"
  },
  {
    "sigla": "LU",
    "provincia": "Lucca",
    "superficie": 1772.81,
    "residenti": 388555,
    "num_comuni": 33,
    "istat": "046",
    "regione": "Toscana"
  },
  {
    "sigla": "MB",
    "provincia": "Monza e della Brianza",
    "superficie": 405.49,
    "residenti": 850684,
    "num_comuni": 55,
    "istat": "108",
    "regione": "Lombardia"
  },
  {
    "sigla": "MC",
    "provincia": "Macerata",
    "superficie": 2773.75,
    "residenti": 320418,
    "num_comuni": 55,
    "istat": "043",
    "regione": "Marche"
  },
  {
    "sigla": "ME",
    "provincia": "Messina",
    "superficie": 3247.34,
    "residenti": 648062,
    "num_comuni": 108,
    "istat": "083",
    "regione": "Sicilia"
  },
  {
    "sigla": "MI",
    "provincia": "Milano",
    "superficie": 1578.9,
    "residenti": 3075083,
    "num_comuni": 133,
    "istat": "015",
    "regione": "Lombardia"
  },
  {
    "sigla": "MN",
    "provincia": "Mantova",
    "superficie": 2338.84,
    "residenti": 411335,
    "num_comuni": 64,
    "istat": "020",
    "regione": "Lombardia"
  },
  {
    "sigla": "MO",
    "provincia": "Modena",
    "superficie": 2682.86,
    "residenti": 688376,
    "num_comuni": 47,
    "istat": "036",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "MS",
    "provincia": "Massa e Carrara",
    "superficie": 1156.44,
    "residenti": 199330,
    "num_comuni": 17,
    "istat": "045",
    "regione": "Toscana"
  },
  {
    "sigla": "MT",
    "provincia": "Matera",
    "superficie": 3446.12,
    "residenti": 200012,
    "num_comuni": 31,
    "istat": "077",
    "regione": "Basilicata"
  },
  {
    "sigla": "NA",
    "provincia": "Napoli",
    "superficie": 1171.13,
    "residenti": 3055339,
    "num_comuni": 92,
    "istat": "063",
    "regione": "Campania"
  },
  {
    "sigla": "NO",
    "provincia": "Novara",
    "superficie": 1338.12,
    "residenti": 367022,
    "num_comuni": 87,
    "istat": "003",
    "regione": "Piemonte"
  },
  {
    "sigla": "NU",
    "provincia": "Nuoro",
    "superficie": 5638.02,
    "residenti": 213086,
    "num_comuni": 74,
    "istat": "091",
    "regione": "Sardegna"
  },
  {
    "sigla": "OR",
    "provincia": "Oristano",
    "superficie": 2990.45,
    "residenti": 160766,
    "num_comuni": 87,
    "istat": "095",
    "regione": "Sardegna"
  },
  {
    "sigla": "PA",
    "provincia": "Palermo",
    "superficie": 4992.23,
    "residenti": 1243638,
    "num_comuni": 82,
    "istat": "082",
    "regione": "Sicilia"
  },
  {
    "sigla": "PC",
    "provincia": "Piacenza",
    "superficie": 2589.47,
    "residenti": 286336,
    "num_comuni": 46,
    "istat": "033",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "PD",
    "provincia": "Padova",
    "superficie": 2141.59,
    "residenti": 927848,
    "num_comuni": 102,
    "istat": "028",
    "regione": "Veneto"
  },
  {
    "sigla": "PE",
    "provincia": "Pescara",
    "superficie": 1189.29,
    "residenti": 315725,
    "num_comuni": 46,
    "istat": "068",
    "regione": "Abruzzo"
  },
  {
    "sigla": "PG",
    "provincia": "Perugia",
    "superficie": 6334.09,
    "residenti": 657873,
    "num_comuni": 59,
    "istat": "054",
    "regione": "Umbria"
  },
  {
    "sigla": "PI",
    "provincia": "Pisa",
    "superficie": 2445.82,
    "residenti": 413602,
    "num_comuni": 37,
    "istat": "050",
    "regione": "Toscana"
  },
  {
    "sigla": "PN",
    "provincia": "Pordenone",
    "superficie": 2276.3,
    "residenti": 312911,
    "num_comuni": 50,
    "istat": "093",
    "regione": "Friuli Venezia Giulia"
  },
  {
    "sigla": "PO",
    "provincia": "Prato",
    "superficie": 365.26,
    "residenti": 248292,
    "num_comuni": 7,
    "istat": "100",
    "regione": "Toscana"
  },
  {
    "sigla": "PR",
    "provincia": "Parma",
    "superficie": 3449.32,
    "residenti": 431049,
    "num_comuni": 44,
    "istat": "034",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "PT",
    "provincia": "Pistoia",
    "superficie": 964.98,
    "residenti": 287445,
    "num_comuni": 20,
    "istat": "047",
    "regione": "Toscana"
  },
  {
    "sigla": "PU",
    "provincia": "Pesaro e Urbino",
    "superficie": 2563.49,
    "residenti": 363388,
    "num_comuni": 53,
    "istat": "041",
    "regione": "Marche"
  },
  {
    "sigla": "PV",
    "provincia": "Pavia",
    "superficie": 2964.73,
    "residenti": 539569,
    "num_comuni": 186,
    "istat": "018",
    "regione": "Lombardia"
  },
  {
    "sigla": "PZ",
    "provincia": "Potenza",
    "superficie": 6548.49,
    "residenti": 376182,
    "num_comuni": 100,
    "istat": "076",
    "regione": "Basilicata"
  },
  {
    "sigla": "RA",
    "provincia": "Ravenna",
    "superficie": 1858.49,
    "residenti": 386111,
    "num_comuni": 18,
    "istat": "039",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "RC",
    "provincia": "Reggio Calabria",
    "superficie": 3183.19,
    "residenti": 550323,
    "num_comuni": 97,
    "istat": "080",
    "regione": "Calabria"
  },
  {
    "sigla": "RE",
    "provincia": "Reggio Emilia",
    "superficie": 2292.89,
    "residenti": 522468,
    "num_comuni": 42,
    "istat": "035",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "RG",
    "provincia": "Ragusa",
    "superficie": 1614.02,
    "residenti": 310220,
    "num_comuni": 12,
    "istat": "088",
    "regione": "Sicilia"
  },
  {
    "sigla": "RI",
    "provincia": "Rieti",
    "superficie": 2749.16,
    "residenti": 156521,
    "num_comuni": 73,
    "istat": "057",
    "regione": "Lazio"
  },
  {
    "sigla": "RM",
    "provincia": "Roma",
    "superficie": 5380.95,
    "residenti": 4039813,
    "num_comuni": 121,
    "istat": "058",
    "regione": "Lazio"
  },
  {
    "sigla": "RN",
    "provincia": "Rimini",
    "superficie": 861.48,
    "residenti": 326926,
    "num_comuni": 25,
    "istat": "099",
    "regione": "Emilia Romagna"
  },
  {
    "sigla": "RO",
    "provincia": "Rovigo",
    "superficie": 1790.01,
    "residenti": 242543,
    "num_comuni": 50,
    "istat": "029",
    "regione": "Veneto"
  },
  {
    "sigla": "SA",
    "provincia": "Salerno",
    "superficie": 4917.46,
    "residenti": 1093453,
    "num_comuni": 158,
    "istat": "065",
    "regione": "Campania"
  },
  {
    "sigla": "SI",
    "provincia": "Siena",
    "superficie": 3821.22,
    "residenti": 267200,
    "num_comuni": 35,
    "istat": "052",
    "regione": "Toscana"
  },
  {
    "sigla": "SO",
    "provincia": "Sondrio",
    "superficie": 3211.9,
    "residenti": 181101,
    "num_comuni": 77,
    "istat": "014",
    "regione": "Lombardia"
  },
  {
    "sigla": "SP",
    "provincia": "La spezia",
    "superficie": 882.4,
    "residenti": 218717,
    "num_comuni": 32,
    "istat": "011",
    "regione": "Liguria"
  },
  {
    "sigla": "SR",
    "provincia": "Siracusa",
    "superficie": 2108.8,
    "residenti": 399469,
    "num_comuni": 21,
    "istat": "089",
    "regione": "Sicilia"
  },
  {
    "sigla": "SS",
    "provincia": "Sassari",
    "superficie": 7692.09,
    "residenti": 494471,
    "num_comuni": 92,
    "istat": "090",
    "regione": "Sardegna"
  },
  {
    "sigla": "SU",
    "provincia": "Sud Sardegna",
    "superficie": 6530.78,
    "residenti": 358158,
    "num_comuni": 107,
    "istat": "111",
    "regione": "Sardegna"
  },
  {
    "sigla": "SV",
    "provincia": "Savona",
    "superficie": 1544.77,
    "residenti": 280837,
    "num_comuni": 69,
    "istat": "009",
    "regione": "Liguria"
  },
  {
    "sigla": "TA",
    "provincia": "Taranto",
    "superficie": 2428.71,
    "residenti": 582814,
    "num_comuni": 29,
    "istat": "073",
    "regione": "Puglia"
  },
  {
    "sigla": "TE",
    "provincia": "Teramo",
    "superficie": 1950.61,
    "residenti": 306955,
    "num_comuni": 47,
    "istat": "067",
    "regione": "Abruzzo"
  },
  {
    "sigla": "TN",
    "provincia": "Trento",
    "superficie": 6206.9,
    "residenti": 530308,
    "num_comuni": 166,
    "istat": "022",
    "regione": "Trentino Alto Adige"
  },
  {
    "sigla": "TO",
    "provincia": "Torino",
    "superficie": 6821.96,
    "residenti": 2254720,
    "num_comuni": 312,
    "istat": "001",
    "regione": "Piemonte"
  },
  {
    "sigla": "TP",
    "provincia": "Trapani",
    "superficie": 2461.65,
    "residenti": 430478,
    "num_comuni": 25,
    "istat": "081",
    "regione": "Sicilia"
  },
  {
    "sigla": "TR",
    "provincia": "Terni",
    "superficie": 2121.95,
    "residenti": 228366,
    "num_comuni": 33,
    "istat": "055",
    "regione": "Umbria"
  },
  {
    "sigla": "TS",
    "provincia": "Trieste",
    "superficie": 211.82,
    "residenti": 231677,
    "num_comuni": 6,
    "istat": "032",
    "regione": "Friuli Venezia Giulia"
  },
  {
    "sigla": "TV",
    "provincia": "Treviso",
    "superficie": 2476.68,
    "residenti": 881245,
    "num_comuni": 94,
    "istat": "026",
    "regione": "Veneto"
  },
  {
    "sigla": "UD",
    "provincia": "Udine",
    "superficie": 4904.25,
    "residenti": 536622,
    "num_comuni": 134,
    "istat": "030",
    "regione": "Friuli Venezia Giulia"
  },
  {
    "sigla": "VA",
    "provincia": "Varese",
    "superficie": 1198.71,
    "residenti": 876960,
    "num_comuni": 138,
    "istat": "012",
    "regione": "Lombardia"
  },
  {
    "sigla": "VB",
    "provincia": "Verbano Cusio Ossola",
    "superficie": 2254.83,
    "residenti": 160143,
    "num_comuni": 74,
    "istat": "103",
    "regione": "Piemonte"
  },
  {
    "sigla": "VC",
    "provincia": "Vercelli",
    "superficie": 2088.08,
    "residenti": 176307,
    "num_comuni": 82,
    "istat": "002",
    "regione": "Piemonte"
  },
  {
    "sigla": "VE",
    "provincia": "Venezia",
    "superficie": 2466.49,
    "residenti": 847983,
    "num_comuni": 44,
    "istat": "027",
    "regione": "Veneto"
  },
  {
    "sigla": "VI",
    "provincia": "Vicenza",
    "superficie": 2725.09,
    "residenti": 865421,
    "num_comuni": 114,
    "istat": "024",
    "regione": "Veneto"
  },
  {
    "sigla": "VR",
    "provincia": "Verona",
    "superficie": 3120.97,
    "residenti": 907352,
    "num_comuni": 98,
    "istat": "023",
    "regione": "Veneto"
  },
  {
    "sigla": "VT",
    "provincia": "Viterbo",
    "superficie": 3611.53,
    "residenti": 315623,
    "num_comuni": 60,
    "istat": "056",
    "regione": "Lazio"
  },
  {
    "sigla": "VV",
    "provincia": "Vibo Valentia",
    "superficie": 1139.47,
    "residenti": 162252,
    "num_comuni": 50,
    "istat": "102",
    "regione": "Calabria"
  }
]

export const ProvincesOptions = map(Provinces, ({ sigla, provincia }, key) => {
  return { label: provincia, value: provincia };
});



const countries = {
  'IT': 'Italia',
  'AF': 'Afghanistan',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antartide',
  'AG': 'Antigua e Barbuda',
  'AN': 'Antille Olandesi',
  'SA': 'Arabia Saudita',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaigian',
  'BS': 'Bahamas',
  'BH': 'Bahrein',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BE': 'Belgio',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BY': 'Bielorussia',
  'BO': 'Bolivia',
  'BA': 'Bosnia Erzegovina',
  'BW': 'Botswana',
  'BR': 'Brasile',
  'BN': 'Brunei',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambogia',
  'CM': 'Camerun',
  'CA': 'Canada',
  'CV': 'Capo Verde',
  'TD': 'Ciad',
  'CL': 'Cile',
  'CN': 'Cina',
  'CY': 'Cipro',
  'CO': 'Colombia',
  'KM': 'Comore',
  'CG': 'Congo',
  'KP': 'Corea del Nord',
  'KR': 'Corea del Sud',
  'CR': 'Costa Rica',
  'CI': 'Costa d’Avorio',
  'HR': 'Croazia',
  'CU': 'Cuba',
  'DK': 'Danimarca',
  'DM': 'Dominica',
  'EC': 'Ecuador',
  'EG': 'Egitto',
  'SV': 'El Salvador',
  'AE': 'Emirati Arabi Uniti',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Etiopia',
  'RU': 'Federazione Russa',
  'FJ': 'Figi',
  'PH': 'Filippine',
  'FI': 'Finlandia',
  'FR': 'Francia',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'GS': 'Georgia del Sud e Isole Sandwich del Sud',
  'DE': 'Germania',
  'GH': 'Ghana',
  'JM': 'Giamaica',
  'JP': 'Giappone',
  'GI': 'Gibilterra',
  'DJ': 'Gibuti',
  'JO': 'Giordania',
  'GR': 'Grecia',
  'GD': 'Grenada',
  'GL': 'Groenlandia',
  'GP': 'Guadalupa',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GF': 'Guiana Francese',
  'GN': 'Guinea',
  'GQ': 'Guinea Equatoriale',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HN': 'Honduras',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran',
  'IQ': 'Iraq',
  'IE': 'Irlanda',
  'IS': 'Islanda',
  'BV': 'Isola Bouvet',
  'NF': 'Isola Norfolk',
  'CX': 'Isola di Christmas',
  'IM': 'Isola di Man',
  'AX': 'Isole Aland',
  'KY': 'Isole Cayman',
  'CC': 'Isole Cocos',
  'CK': 'Isole Cook',
  'FK': 'Isole Falkland',
  'FO': 'Isole Faroe',
  'HM': 'Isole Heard ed Isole McDonald',
  'MP': 'Isole Marianne Settentrionali',
  'MH': 'Isole Marshall',
  'UM': 'Isole Minori lontane dagli Stati Uniti',
  'SB': 'Isole Solomon',
  'TC': 'Isole Turks e Caicos',
  'VI': 'Isole Vergini Americane',
  'VG': 'Isole Vergini Britanniche',
  'IL': 'Israele',
  'JE': 'Jersey',
  'KZ': 'Kazakistan',
  'KE': 'Kenya',
  'KG': 'Kirghizistan',
  'KI': 'Kiribati',
  'KW': 'Kuwait',
  'LA': 'Laos',
  'LS': 'Lesotho',
  'LV': 'Lettonia',
  'LB': 'Libano',
  'LR': 'Liberia',
  'LY': 'Libia',
  'LI': 'Liechtenstein',
  'LT': 'Lituania',
  'LU': 'Lussemburgo',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MV': 'Maldive',
  'MY': 'Malesia',
  'ML': 'Mali',
  'MT': 'Malta',
  'MA': 'Marocco',
  'MQ': 'Martinica',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Messico',
  'FM': 'Micronesia',
  'MD': 'Moldavia',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MZ': 'Mozambico',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NO': 'Norvegia',
  'NC': 'Nuova Caledonia',
  'NZ': 'Nuova Zelanda',
  'OM': 'Oman',
  'NL': 'Paesi Bassi',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestina',
  'PA': 'Panama',
  'PG': 'Papua Nuova Guinea',
  'PY': 'Paraguay',
  'PE': 'Perù',
  'PN': 'Pitcairn',
  'PF': 'Polinesia Francese',
  'PL': 'Polonia',
  'PT': 'Portogallo',
  'PR': 'Portorico',
  'QA': 'Qatar',
  'HK': 'Regione Amministrativa Speciale di Hong Kong della Repubblica Popolare Cinese',
  'MO': 'Regione Amministrativa Speciale di Macao della Repubblica Popolare Cinese',
  'GB': 'Regno Unito',
  'CZ': 'Repubblica Ceca',
  'CF': 'Repubblica Centrafricana',
  'CD': 'Repubblica Democratica del Congo',
  'DO': 'Repubblica Dominicana',
  'MK': 'Repubblica di Macedonia',
  'RO': 'Romania',
  'RW': 'Ruanda',
  'RE': 'Réunion',
  'EH': 'Sahara Occidentale',
  'KN': 'Saint Kitts e Nevis',
  'LC': 'Saint Lucia',
  'PM': 'Saint Pierre e Miquelon',
  'VC': 'Saint Vincent e Grenadines',
  'WS': 'Samoa',
  'AS': 'Samoa Americane',
  'BL': 'San Bartolomeo',
  'SM': 'San Marino',
  'SH': 'Sant’Elena',
  'ST': 'Sao Tomé e Príncipe',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'CS': 'Serbia e Montenegro',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SY': 'Siria',
  'SK': 'Slovacchia',
  'SI': 'Slovenia',
  'SO': 'Somalia',
  'ES': 'Spagna',
  'LK': 'Sri Lanka',
  'US': 'Stati Uniti',
  'ZA': 'Sudafrica',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard e Jan Mayen',
  'SE': 'Svezia',
  'CH': 'Svizzera',
  'SZ': 'Swaziland',
  'TJ': 'Tagikistan',
  'TH': 'Tailandia',
  'TW': 'Taiwan',
  'TZ': 'Tanzania',
  'TF': 'Territori australi francesi',
  'IO': 'Territorio Britannico dell\’Oceano Indiano',
  'TL': 'Timor Est',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad e Tobago',
  'TN': 'Tunisia',
  'TR': 'Turchia',
  'TM': 'Turkmenistan',
  'TV': 'Tuvalu',
  'UA': 'Ucraina',
  'UG': 'Uganda',
  'HU': 'Ungheria',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VA': 'Vaticano',
  'VE': 'Venezuela',
  'VN': 'Vietnam',
  'WF': 'Wallis e Futuna',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'ZZ': 'regione non valida o sconosciuta',
}

export const countryOptions = Object.keys(countries).map(code => ({
  value: code,
  label: countries[code],
}))
