import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { Certificate, Device } from '@app/store/models';
import { MainStep } from '@app/store/models/app.models';
import { allowedSubsOwners } from '@app/store/models/customization.models';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

const logger = new LoggerService("EolComponent");
@Component({
  selector: 'app-eol',
  templateUrl: './eol.component.html',
  styleUrls: ['./eol.component.scss']
})
export class EolComponent implements OnInit {
  @Input() device!: Device;

  @Input() mainStep!: MainStep;



  constructor(private store: Store<GlobalState>, private translateService: TranslateService) { }

  ngOnInit(): void {
    // const { deviceModel: { name, isDeprecated }, owner } = this.device;
    // //Se è in sostituzione e l'owner non è INFOCERT o SIXTEMA e
    // //se il device è deprecato
    // if (this.mainStep === "substitution" && isDeprecated && !allowedSubsOwners.includes(owner)) {
    //   // prendo la traduzione  in base al model del device
    //   const translateKey = `DEPRECATED_MESSAGES.DEPRECATED_ERROR.${name.toUpperCase()}`;
    //   this.deprecatedError = this.translateService.instant(translateKey);
    // } else if (this.mainStep === "renew") {
    //   //Se è Sostituzione e il device  non è rinnovabile, mostro il messaggio di errore in base ai booleani della morte
    // }
  }

  get deprecatedError(): string {
    if (!this.device) return "";

    const { deviceModel: { name, isDeprecated }, owner } = this.device;
    //Se è in sostituzione e l'owner non è INFOCERT o SIXTEMA e
    //se il device è deprecato
    if (this.mainStep === "substitution" && isDeprecated && !allowedSubsOwners.includes(owner)) {
      // prendo la traduzione  in base al model del device
      const translateKey = `DEPRECATED_MESSAGES.DEPRECATED_ERROR.${name.toUpperCase()}`;
      return this.translateService.instant(translateKey);
    } else {
      return "";
    }
  }



  get certificates() {
    return this.device?.certificates;
  }

  onCertsSelected(certificates: Certificate[]) {
    logger.log("🚀 ~ EolComponent ~ onCertSelected ~ certificates:", certificates)

  }

}
