<app-debugger [data]="$currStep | async" title="CurrStep"></app-debugger>

<ng-container *ngIf="($device | async)" [ngSwitch]=" ($currStep |async)?.step">

  <app-device-title></app-device-title>

  <div *ngSwitchCase="'select_cert'">
    <app-select-substitute [device]="$device | async"></app-select-substitute>
  </div>

  <div *ngSwitchCase="'go_payment'">
    <app-go-payment [device]="$device | async" mainStep="substitution"></app-go-payment>
  </div>
  <div *ngSwitchCase="'order_paid'">
    <app-order-paid [device]="$device | async" mainStep="substitution"></app-order-paid>
  </div>
  <div *ngSwitchCase="'cert_installed'">
    <app-cert-installed [device]="$device | async" mainStep="substitution"></app-cert-installed>
  </div>
  <div *ngSwitchCase="'eol'">
    <app-eol [device]="$device | async" mainStep="substitution"></app-eol>
  </div>

  <div *ngSwitchDefault>
    <app-eol [device]="$device | async" mainStep="substitution"></app-eol>
  </div>
</ng-container>

<app-debugger [data]="$device | async" title="Device"></app-debugger>
