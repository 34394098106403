<div class="completed-page">
  <div class="completed-card">
    <h1 [translate-html]="'COMPLETED.TITLE'"></h1>
    <ng-ui-kit-separator> </ng-ui-kit-separator>

    <div class="flex-row ic1-my-8">
      <app-status-icon type="success" [size]="'medium'"></app-status-icon>
      <h4 [translate-html]="'COMPLETED.SUBTITLE'"></h4>
    </div>

    <p [translate-html]="'COMPLETED.DESCRIPTION'"></p>

    <div *ngIf="cosmoContacts">
      <app-substitution-complete-title [cosmoContacts]="cosmoContacts">
      </app-substitution-complete-title>
      <!-- <ng-ui-kit-button
        (onClick)="downloadCertificate()"
        [label]="'GLOBALS.CERTIFICATES.BTN_DOWNLOAD_CONTRACT' | translate"
        color="primary"
      ></ng-ui-kit-button> -->
    </div>

    <div class="buttons">
      <a
        routerLink="/checkDevice"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        queryParamsHandling="preserve"
        class="ic1-btn ic1-btn-md ic1-btn-primary"
      >
        <span translate="GLOBALS.BACK_HOME"></span>
      </a>
    </div>
  </div>
  <div class="white-circle">
    <img src="assets/images/success_man.png" alt="Logo" class="man-completed" />
  </div>
</div>
