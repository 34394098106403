<div class="title-component flex-column space-between" *ngIf="showComponent">
  <div class="ic1-text-body-1 ic1-my-20">
    <div [innerHTML]="substituteTitle"></div>
    <div [innerHTML]="substituteIUT"></div>
  </div>
  <ng-ui-kit-button
    class="downloadCertificate"
    (onClick)="downloadCertificate()"
    [label]="'GLOBALS.CERTIFICATES.BTN_DOWNLOAD_CONTRACT' | translate"
    color="secondary"
  ></ng-ui-kit-button>
</div>
