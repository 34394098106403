import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Certificate, Device } from '@app/store/models';
import { MainStep } from '@app/store/models/app.models';
import { selectDevice } from '@app/store/selectors/device.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { uniqBy } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-certificates-list',
  templateUrl: './certificates-list.component.html',
  styleUrls: ['./certificates-list.component.scss']
})
export class CertificatesListComponent {
  $device: Observable<Device>

  @Input() selectable: boolean = false;
  @Input() autoSelect: boolean = false;
  @Input() viewMode: MainStep = "renew";
  @Output() certSelected = new EventEmitter<Certificate[]>();

  selectedCertificates: Certificate[] = [];

  constructor(private store: Store<GlobalState>,) { }

  ngOnInit(): void {
    this.$device = this.store.select(selectDevice)
  }

  onChangeCheckbox({ certificate, selected }: { certificate: Certificate, selected: boolean }) {

    if (selected) {
      this.selectedCertificates.push(certificate)
    } else {
      this.selectedCertificates = this.selectedCertificates.filter(cert => cert.certId !== certificate.certId)
    }

    const removeDuplicates = uniqBy(this.selectedCertificates, 'certId')
    this.selectedCertificates = removeDuplicates;

    this.certSelected.emit(this.selectedCertificates)


  }

}
