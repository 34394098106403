import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSysTs, getTimestamp, objectToUrlParams } from '@app/helpers/helpers';
import { DikeCommands, DikeMessage, DikeMessageData, DikeMessages, DikeSession, dikesStatus } from '@app/models/dike.interface';
import { ConfigService } from './config.service';

import { Observable } from 'rxjs';


import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { LoggerService } from './logger.service';
import { DikeTransaction, GeStatus } from './service.models';

const logger = new LoggerService("DikeService");
@Injectable({
  providedIn: 'root'
})
export class DikeService {

  lastSession!: DikeSession;
  lastStatus!: GeStatus;

  id = 0;
  private portFound: number = 0;
  private lastMsgID: number = 0;


  pollingTimeout = 30 * 1000;
  pollingInterval = 3 * 1000;

  dikeCommandDefaultOpts = {
    host: 'localhost',
    initialPort: 42100,
    endPort: 42110,
    appendTimestampParam: true,
    timestampParamName: 'ts',
  };

  currentTransaction: DikeTransaction;
  serviceUrl = "https://dikescl.infocert.it"


  constructor(
    private configservice: ConfigService,
    private translate: TranslateService,
    private http: HttpClient) { }


  get basePath(): string {

    return `${this.configservice.config.dikeUrls.dikeIOSimple}/api/web/`;
  }


  async getDikeSession(force?: boolean): Promise<DikeSession> {
    if (!this.lastSession || force) {
      //var basePath = this.configservice.config.dikeUrls.dikeIOSimple + '/api/web';

      return new Promise((resolve, reject) => {
        this.http.post<DikeSession>(`${this.basePath}session`, {}).subscribe(
          (data: DikeSession) => {
            this.lastSession = data;
            resolve(this.lastSession);
          }
        );
      });
    } else {
      return Promise.resolve(this.lastSession);
    }
  }


  async getMessages(): Promise<DikeMessages> {


    return new Promise((resolve, reject) => {
      const url = `${this.basePath}${this.lastSession.ioSessionID}/message`;

      logger.log("returnnewPromise ~ url:", url)
      this.http.get<DikeMessages>(url, {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      }).subscribe(
        (messages: DikeMessages) => {
          resolve(messages);
        }
      )
    }
    );
  }

  callUrlFromImage(url: string): Promise<void> {
    logger.log("callUrlFromImage ~ url:", url)
    return new Promise(async (resolve, reject) => {
      var img = new Image();
      const promise = new Promise<void>((resolv) => {
        img.onload = () => {
          resolv();
        };
        img.onerror = () => {
          // Do nothing - continue to next port
          resolv();
        };
      });
      img.src = url;
      await promise;
      if (img.complete && img.naturalWidth !== 0) {
        logger.log(`Successfully loaded ${url}`);
        resolve()
        return;
      } else {
        logger.log(`Failed to load ${url}`);
        reject()
      }
    })
  }

  get token(): string {
    if (!this.lastSession) return "";
    return this.lastSession.jDikeSessionID + ':' + this.lastSession.ioSessionID;
  }

  formatMessageResponse(message: DikeMessage, command: DikeCommands): DikeMessageData {

    return JSON.parse(message.msgBody)?.[command] as DikeMessageData;
  }


  pollMessageResponse(msgId: string, command: DikeCommands, timeoutMs: number = 30 * 1000): Promise<DikeMessageData | undefined> {

    return new Promise(async (resolve, reject) => {

      const startTimestamp = Date.now();

      const pollingMessages = setInterval(() => {


        this.getMessages().then((messages) => {

          if (messages?.remaining === 0) {

            const responseMessage = messages.messages.find((message) => message.replyToId == msgId && message.msgName == command);

            clearInterval(pollingMessages)

            if (responseMessage) {
              resolve(this.formatMessageResponse(responseMessage, command))
            } else {
              resolve(undefined)
            }
          }
        })

        // Verifica se il timeout è scaduto
        if (Date.now() - startTimestamp > timeoutMs) {
          clearInterval(pollingMessages);
          reject(new Error('Timeout scaduto'));
          // console.error(new Error('Timeout scaduto'));
          // resolve(undefined)
        }

      }, 3000);

    })

  }

  async DikeCommand<T = DikeMessageData | undefined>(type: DikeCommands, params: any = {}): Promise<T> {


    let timeout = 30 * 1000;

    return new Promise(async (resolve, reject) => {

      if (type === "GEN_P10" || type === "CERT_INSTALL") {
        timeout = 120 * 1000;
        await this.getDikeSession(true);
      }
      this.lastMsgID++;
      if (type !== "WAKEUP_S" && type !== "SIGN_HASH") {
        this.pollMessageResponse(`${this.lastMsgID}`, type, timeout)
          .then(response => {
            logger.log("response", response);
            resolve(response as T)
          }).catch(err => {
            reject(err)
          });
      } else {
        resolve(undefined)
      }
      this.tryDikeCommandOnPorts(type, params, this.lastMsgID)


    });
  }


  async tryDikeCommandOnPorts(type: DikeCommands, params: any = {}, msgId: number): Promise<void> {

    return new Promise(async (resolve, reject) => {

      const img = new Image();

      const initialPort = this.dikeCommandDefaultOpts.initialPort;
      const endPort = this.dikeCommandDefaultOpts.endPort;

      let port = initialPort;

      while (port < endPort) {

        const urlParams = {
          id: msgId,
          ts: getTimestamp(),
          ...params,
        }

        let url = `http://${this.dikeCommandDefaultOpts.host}:${port}/dike/web/${this.token}/${type}?${objectToUrlParams(urlParams)}`;
        if (type === "WAKEUP_S" || type === "SIGN_HASH") {
          url = `http://${this.dikeCommandDefaultOpts.host}:${port}/dike/web/${type}?${objectToUrlParams(urlParams)}`;

        }


        const promise = new Promise<void>((res) => {
          img.onload = () => {
            res();
          };
          img.onerror = () => {
            // Do nothing - continue to next port
            res();
          };
        });
        img.src = url;
        await promise;
        if (img.complete && img.naturalWidth !== 0) {
          logger.log(`Successfully loaded ${url}`);
          return resolve()
        } else {
          logger.log(`Failed to load ${url}`);
        }
        port++;
      }
      reject("Unable to load URL on any of the specified ports");
    })
  }

  getStatus(ioSessionID = this.lastSession.ioSessionID): Observable<GeStatus> {


    const url = `/api/web/getStatus?ioSessionID=${ioSessionID}&sysTs=${getSysTs()}`;
    //    const url = `https://rinnovofirmacl.infocert.it/api/web/getStatus?ioSessionID=${ioSessionID}&sysTs=${getSysTs()}`;

    const headers = new HttpHeaders({
      'X-Requested-By': 'angular'
    });

    const options = { headers: headers };

    return this.http.get<GeStatus>(url, options)
  }

  getResult(ioSessionID = this.lastSession.ioSessionID): Observable<any> {
    const url = `/api/web/getResult?ioSessionID=${ioSessionID}&sysTs=${getSysTs()}`;

    const headers = new HttpHeaders({
      'X-Requested-By': 'angular'
    });

    const options = { headers: headers };

    return this.http.get(url, options);
  }



  async signatureProcess(dikeTransaction: DikeTransaction) {
    this.currentTransaction = dikeTransaction;

    if (!this.lastSession) {
      await this.getDikeSession(true);
    }
    this.lastSession.ioSessionID = dikeTransaction.ioSessionID

    try {
      const status = await this.pollingUntil(dikesStatus.PENDING);
      logger.log('Status PENDING..');
      logger.log(`hmac: ${status.hmac}`);
      this.lastSession = { ...this.lastSession, ...status, ioSessionID: dikeTransaction.ioSessionID };

      const params = {
        id: this.id++,
        ts: getSysTs(),
        ioSessionID: this.lastSession.ioSessionID,
        u: `${this.currentTransaction.serviceUrl}/api/io/importSnapshot/`,
        f: this.currentTransaction.numSignatures,
        CNS: '0', //TODO check CNS
        msg: window.btoa(this.currentTransaction.userMessage),
        hmac: this.lastStatus.hmac
      };

      await this.DikeCommand("WAKEUP_S", params);
      const hashReadyStatus = await this.pollingUntil(dikesStatus.HASH_READY);

      logger.log('signatureProcess pollingUntil HASH_READY end');

      if (!Array.isArray(hashReadyStatus.sigData.files) || hashReadyStatus.sigData.files.length === 0) {
        throw new Error('Nessun file da firmare');
      }

      logger.log(hashReadyStatus);
      var self: any = {}
      self.certSelected = hashReadyStatus.sigData.cert;
      self.fileToHash = sortBy(hashReadyStatus.sigData.files, ['id']);
      self.hmac = hashReadyStatus.hmac;
      self.isCns = hashReadyStatus.sigData.cert.type === 'CNS' ? '1' : '0';

      const hashParams = {
        ts: getSysTs(),
        ioSessionID: this.lastSession.ioSessionID,
        u: `${this.currentTransaction.serviceUrl}/api/io/closeTransaction/`,
        CNS: self.isCns,
        files: self.fileToHash.map(file => `${file.id}:${file.hash}`),
        certId: self.certSelected.id,
        msg: window.btoa(this.currentTransaction.userMessage),
        hmac: self.hmac,
      };

      await this.DikeCommand("SIGN_HASH", hashParams);

      const hashStatus = await this.pollingUntil(dikesStatus.SIGN_READY, this.pollingInterval);
      logger.log('Dike hash transaction closed');
      logger.log(hashStatus);
      return hashStatus;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }




  pollingUntil(stateTo, timeout = 3 * 1000): Promise<any> {
    logger.log(`startPolling ${stateTo} with timeout ${timeout}`);

    const maxAttempt = 10;

    return this.doThePolling(stateTo, timeout, 0, maxAttempt);
  }

  doThePolling(stateTo, timeout, actualAttempt, maxAttempt): Promise<any> {
    logger.log(`pollingUntil ${stateTo} with timeout ${timeout}`);

    return new Promise((resolve, reject) => {
      this.getStatus().subscribe((status) => {
        this.lastStatus = status;
        logger.log("this.getStatus ~ status:", status)
        const currentStatus = status?.currentStatus

        if (!currentStatus || status.error ||
          (currentStatus === dikesStatus.ERROR) ||
          (dikesStatus.CLOSED !== stateTo && currentStatus === dikesStatus.CLOSED) ||
          (dikesStatus.CANCELED !== stateTo && currentStatus === dikesStatus.CANCELED)) {
          const errorMessage = status?.errorMessage;
          logger.error("🚀 ~ DikeService ~ this.getStatus ~ errorMessage:", errorMessage)
          reject(new Error(errorMessage || currentStatus));
        } else if (status.currentStatus === stateTo) {
          //TUTTO OK
          resolve(status);
        }

        else if (timeout) {
          logger.log(`Re-checking status in ${timeout} ms...`);

          const timeoutErrorMessage = this.translate.instant('DIKE_ERRORS.FE_DKS002');
          if (actualAttempt >= maxAttempt) {
            reject(new Error(timeoutErrorMessage));
          } else if (actualAttempt * timeout >= this.pollingTimeout) {
            reject(new Error(timeoutErrorMessage));
          } else {
            setTimeout(() => {
              actualAttempt += 1;
              this.doThePolling(stateTo, timeout, actualAttempt, maxAttempt).then(resolve, reject);
            }, timeout);
          }
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  wakeUp() {
    const params = {
      name: 'WAKEUP',
      ioResponseTimeout: 30 * 1000 // 10sec
    };
  }

  getSnapshot() {
    const params = {
      name: 'SYS_SNAPSHOT',
      ioResponseTimeout: 60 * 1000 // 60sec
    };
  }

  generateP10(certId: string) {
    const params = {
      name: 'GEN_P10',
      params: {
        crtId: certId
      }
    }
  }

  installCert(certId: string) {
    const params = {
      name: 'CERT_INSTALL',
      params: {
        crtId: certId
      }
    }
  }

  cosmov7(certId: string) {
    const params = {
      name: 'COSMO_GET',
      params: {
        crtId: certId
      }
    }
  }


  // this.createFirstCommand = this.commands.wakeUp;

}
