import { ApplicationRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/app-routing.module';
import { ModalComponent, ModalData } from '@app/components/modal/modal.component';
import { DikeService } from '@app/services/dike.service';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { toggleMainLoading } from '@app/store/actions/app.actions';
import { Device } from '@app/store/models';
import { MainStep } from '@app/store/models/app.models';
import { Certificate, RenewTypeResponse, SignType } from '@app/store/models/certificate.model';
import { isMainLoading } from '@app/store/selectors/app.selector';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Observable, of } from 'rxjs';

const logger = new LoggerService("OrderPaidComponent");


@Component({
  selector: 'app-order-paid',
  templateUrl: './order-paid.component.html',
  styleUrls: ['./order-paid.component.scss']
})
export class OrderPaidComponent implements OnInit {

  @Input() device!: Device;
  @Input() mainStep!: MainStep;

  status: 'IDLE' | 'LOADING' | 'LOADED' | 'ERROR' = 'IDLE';
  cosmoContacts: RenewTypeResponse = undefined


  @ViewChild('emissionModal') emissionModal: ModalComponent;

  $mainLoading: Observable<boolean> = of(true)

  selectedCertifcates: Certificate[] = [];

  constructor(
    private dikerWebService: DikerWebService,
    private dikeService: DikeService,
    public ngxSmartModalService: NgxSmartModalService,
    private store: Store<GlobalState>,
    private translateService: TranslateService,
    private router: Router,
    private appRef: ApplicationRef
  ) {
    this.$mainLoading = this.store.select(isMainLoading);

  }

  ngOnInit(): void {
    debugger;
    logger.log("🚀 ~OrderPaidComponent ngOnInit ~ this.device", this.device)
    if (this.mainStep === 'substitution') {
      this.status = 'LOADING'
      this.store.dispatch(toggleMainLoading({ showLoading: true }));
      this.dikerWebService.checkRenewType(this.device.signCertificate.certId)
        .subscribe({

          error: (error) => {

            console.error('Errore nella chiamata checkRenewType:', error);
            // Puoi eseguire azioni alternative come mostrare un messaggio di errore
            this.status = 'ERROR'

            this.store.dispatch(toggleMainLoading({ showLoading: false }));
            //this.store.dispatch(toggleMainLoading({ showLoading: false, loadingMessage: "Errore nella chiamata checkRenewType" }));
          },
          next: (cosmoContacts) => {
            logger.log("🚀 ~ ngOnInit ~ this.dikerWebService.checkRenewType ~ res", cosmoContacts)
            this.cosmoContacts = cosmoContacts

            this.status = 'LOADED'
            this.store.dispatch(toggleMainLoading({ showLoading: false }));
            this.forceResizeUpdate()
          }
        }

        );

    } else {
      this.status = 'LOADED'
      this.store.dispatch(toggleMainLoading({ showLoading: false }));
      this.forceResizeUpdate()
    }

    this.forceResizeUpdate()

  }

  forceResizeUpdate() {

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500)
  }

  get isLoading() {
    return this.status === 'LOADING'
  }


  get showCertificates() {
    return this.status === 'LOADED'
  }

  get showCompletedComponent() {
    return this.mainStep === 'substitution' && this.device.signCertificate.appStep === "cert_installed"
  }


  onCertsSelected(certificates: Certificate[] = []) {
    //TODO: check che abbiano TUTTI lo stesso stato

    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ onCertSelected ~ certificates:", certificates)
    this.selectedCertifcates = certificates;

  }


  startEmission() {

    if (this.mainStep === 'substitution') {
      this.startEmissionSubstitution()
    } else if (this.mainStep === 'renew') {
      this.startEmissionRenew()
    }

  }

  /** EMISSIONE PER SOSTITUZIONE */
  startEmissionSubstitution() {

    this.status = 'LOADING'

    const emitType = this.cosmoContacts.type;
    const certType = this.translateService.instant(`GLOBALS.${emitType}`);

    this.openEmissionModal({
      title: this.translateService.instant(`SUBSTITUTION.EMISSION.MODAL_TITLE`),
      message: this.translateService.instant(`SUBSTITUTION.EMISSION.MODAL_LOADING_MESSAGE`, { certType })
    })

    this.doEmissionSubstitution(emitType)
      .then((response: any) => {
        logger.log("startEmission response", response);
        this.status = 'LOADED';
        this.cosmoContacts = response;

        this.router.navigate([ROUTE_PATHS.completed], {
          state: {
            mainStep: "substitution",
            cosmoContacts: this.cosmoContacts,
            certId: this.device.signCertificate.certId
          }
        });
      })
      .catch(err => {
        logger.error(err)
        this.status = 'ERROR'
        const modalMessage = this.translateService.instant(`SUBSTITUTION.EMISSION.MODAL_LOADING_MESSAGE`, { certType })
        const errorMessage = err?.error?.message || err?.message || err?.statusText || "";
        this.updateModal({
          title: this.translateService.instant(`SUBSTITUTION.EMISSION.MODAL_ERROR_TITLE`),
          type: 'error',
          message: `${modalMessage} ${errorMessage}`
        })

      }).finally(() => {
        //this.store.dispatch(updateCertificatesStatus())
        //Processo completato!

      })

  }

  async doEmissionSubstitution(emitType: SignType) {

    return new Promise((resolve, reject) => {


      if (emitType === 'REMOTE_SIGN') {
        this.dikerWebService.emitTRISS(this.device.signCertificate.certId)
          .then((response) => {
            logger.log('🚀 ~ emitTRISS ~ response', response);

            //TUTTO OK
            resolve(response)
          })
          .catch(reject)

      } else if (emitType === 'BK' || emitType === 'SC') {
        this.dikerWebService.emitCARA(this.device.signCertificate.certId)
          .then((response) => {
            logger.log('🚀 ~ emitCARA ~ response', response);
            const hasAutCert = this.device.certificates.find((cert) =>
              ['AUT', 'CNS', 'CNDCEC_CNS'].includes(cert.type)
            );
            if (hasAutCert) {
              this.dikerWebService.decline(hasAutCert.certId).then(() => {
                //TUTTO OK
                resolve(response)
              });
            } else {
              //TUTTO OK
              resolve(response)
            }


          }).catch(reject)
      }
    })

  }


  /** EMISSIONE PER RINNOVO */
  async startEmissionRenew() {
    this.status = 'LOADING';
    this.openEmissionModal({
      title: this.translateService.instant(`RENEW.EMISSION.MODAL_TITLE`),
      message: this.translateService.instant(`RENEW.EMISSION.MODAL_LOADING_MESSAGE`)
    });


    try {
      for (const cert of this.selectedCertifcates) {
        await this.certInstallRenew(cert); // Attendere il completamento di ogni promise
      }

      /*
       TODO:Da approfondire
               //https://jira.infocert.it/browse/CADIKE-5247
               //Se è una tessera sanitaria, declino il certificato
               if (this.device.isHealthCard) {
                 logger.log('🚀 ~ emitCARA ~ response', response);
                 const hasAutCert = this.device.certificates.find((cert) =>
                   ['AUT', 'CNS', 'CNDCEC_CNS'].includes(cert.type)
                 );
                 if (hasAutCert) {
                   logger.log('🚀 ~ Provo decline su Health Card', hasAutCert);
                   await this.dikerWebService.decline(hasAutCert?.certId).then(() => {
                     logger.warn("OK decline health card");
                   }).catch(err => {
                     logger.warn("errore decline health card", err);
                   })
                 }
               }
       */

      this.status = 'LOADED';
      this.router.navigate([ROUTE_PATHS.completed]);
    } catch (err) {
      logger.error(err);
      this.status = 'ERROR';
      const modalMessage = this.translateService.instant(`RENEW.EMISSION.MODAL_ERROR_MESSAGE`);
      const errorMessage = err?.error?.message || err?.message || err?.statusText || "";
      this.updateModal({
        title: this.translateService.instant(`RENEW.EMISSION.MODAL_ERROR_TITLE`),
        type: 'error',
        message: `${modalMessage} ${errorMessage}`
      });
    }
  }


  certInstallRenew(cert: Certificate) {
    return new Promise((resolve, reject) => {
      this.dikeService.DikeCommand("CERT_INSTALL", { certId: cert.certId })
        .then(response => {
          logger.log("GEN_P10", response);
          resolve(true)
        })
        .catch(err => {
          logger.error(err)
          reject(err)
        })
    })
  }



  get proceedLabel() {

    if (this.mainStep === 'renew') {
      return this.translateService.instant('GLOBALS.PROCEED');
    } else if (this.mainStep === 'substitution') {
      const type = this?.cosmoContacts?.type === "REMOTE_SIGN" ? "REMOTE_SIGN" : "OTHERS";
      const key = 'SUBSTITUTION.ORDER_PAID.BTN_PROCEED_' + type;
      return this.translateService.instant(key);
    }
  }





  public openEmissionModal(data: ModalData = {}) {

    this.updateModal(data)
    //if (!this?.emissionModal?.isOpen) {
    this?.emissionModal?.openModal();
    //}
  }

  updateModal(data: ModalData) {

    this?.emissionModal?.setData(data);
  }

  public closeModal() {

    this?.emissionModal?.closeModal();

  }

  onCloseModal($event = undefined) {
    //console.log("🚀 ~ onCloseModal ~ $event:", $event)


  }
  onConfirmModal($event = undefined) {
    //console.log("🚀 ~ onConfirmModal ~ $event:", $event)
  }





}
