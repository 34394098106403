import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ModalComponent, ModalData, ModalType } from '@app/components/modal/modal.component';
import { DikeService } from '@app/services/dike.service';
import { DikerWebService } from '@app/services/diker-web.service';
import { LoggerService } from '@app/services/logger.service';
import { updateCertificatesStatus } from '@app/store/actions/device.actions';
import { Certificate, Device } from '@app/store/models';
import { GlobalState } from '@app/store/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';


const logger = new LoggerService("RENEW SelectCertificatesComponent");

@Component({
  selector: 'app-select-certificates',
  templateUrl: './select-certificates.component.html',
  styleUrls: ['./select-certificates.component.scss']
})
export class SelectCertificatesComponent implements OnInit {

  @Input() device: Device;

  @ViewChild('couponRaoModal') couponRaoModal: ModalComponent;
  @ViewChild('loadingModal') loadingModal: ModalComponent;
  loadingModalType: ModalType = "loading";

  @ViewChild(TemplateRef, { static: false }) couponInput: TemplateRef<any>;

  selectedCertificates: Certificate[] = [];
  deprecatedWarning: string;

  constructor(

    private translateService: TranslateService,
    private dikerWebService: DikerWebService,
    private store: Store<GlobalState>,
    private dikeService: DikeService,
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit() {

    this.getWarningMessage();
  }

  get showProceed(): boolean {
    return !!this?.device?.certificates?.find(cert => !cert.isExpired)
  }


  getWarningMessage() {
    if (this.device) {
      const { deviceModel: { name, isDeprecated } } = this.device;

      if (isDeprecated) {
        const translateKey = `DEPRECATED_MESSAGES.DEPRECATED_WARNING.${name.toUpperCase()}`;
        this.deprecatedWarning = this.translateService.instant(translateKey);
      }
    }
  }



  onCertsSelected(certificates: Certificate[]) {
    //TODO: check che abbiano TUTTI lo stesso stato
    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ onCertSelected ~ certificates:", certificates)
    this.selectedCertificates = certificates;

  }

  async checkCertsP10() {
    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ proceedRenew ~ this.certsSelected:", this.selectedCertificates)

    return new Promise(async (resolve, reject) => {

      const certsWithoutP10 = this.selectedCertificates.filter(cert => !cert.hasP10);

      //Se ci sono certificati senza P10, li genero
      if (certsWithoutP10.length > 0) {

        // this.openLoadingModal({
        //   title: this.translateService.instant('RENEW.GEN_P10.MODAL_TITLE'),
        //   message: this.translateService.instant('RENEW.GEN_P10.MODAL_LOADING_MESSAGE'),
        //   type: "loading"
        // });

        //Controllo che tutit i certificati selezionati abbiano il P10
        //se non ce l'hanno,  generateP10
        this.loadingModal.setData({ message: `Genero P10 del certificato 1 di ${certsWithoutP10.length}` });

        const promiseFunctions = certsWithoutP10.map((cert, index) => {
          //http://localhost:42100/dike/web/990696FC0987C8DB37BD5F51B46EAD78.diker1:b12002a6-d276-48bd-842c-564b76ac0c45/GEN_P10?id=3&certId=RNDMSM63P28D708P:2023111114397:C7D229C231A9C3970542FA77FF39EC7B71A6E169:179901&ts=202306141802514
          return () => this.dikeService.DikeCommand("GEN_P10", { certId: cert.certId }).then((res) => {
            this.loadingModal.setData({ message: `Genero P10 del certificato ${(index % 2) + 1} di ${certsWithoutP10.length}` });
            return res;
          });
        });

        // Eseguo le promesse in sequenza
        const sequentialPromise = promiseFunctions.reduce((prevPromise, promiseFn) => {
          return prevPromise.then(promiseFn);
        }, Promise.resolve());

        sequentialPromise
          .then((res) => {
            logger.log("GEN_P10", res);
            resolve(true)
          })
          .catch(err => {
            logger.error(err)
            const message = err?.message || this.translateService.instant('ERRORS.GEN_P10_ERROR');
            this.loadingModal.setData({ message, type: "error" });
            reject(err)
          }).finally(() => {
            resolve(true)
          })


      } else {
        resolve(true)
      }
    })

  }

  async proceedRenew() {

    this.openLoadingModal({
      title: this.translateService.instant('RENEW.GEN_P10.MODAL_TITLE'),
      message: "", type: "loading"
    });

    logger.log("🚀 ~ Renew SelectCertificatesComponent ~ proceedRenew ~ this.certsSelected:", this.selectedCertificates)

    await this.checkCertsP10()
    this.loadingModal.closeModal();

    this.store.dispatch(updateCertificatesStatus())
  }


  public openLoadingModal(data: ModalData = {}) {
    this.loadingModal.setData(data)
    this.loadingModal.openModal();
  }

}
