import { Component, OnInit } from '@angular/core';
import { selectOwner } from '@app/store/selectors/customization.selectors';
import { GlobalState } from '@app/store/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

enum FooterEnum {
  PRIVACY_POLICY,
  COOKIE_POLICY,
  PRIVACY_NOTICE,
}
@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  brandName: string = 'Infocert';
  constructor(private translateService: TranslateService, private store: Store<GlobalState>) { }

  ngOnInit(): void {

    this.store.select(selectOwner)
      .pipe(
        untilDestroyed(this),
        tap(owner => {
          this.brandName = owner;
        })
      )
      .subscribe();

  }

  get rightText() {
    const year = new Date().getFullYear();
    const brandName = this.translateService.instant('FOOTER.NAME');
    const piva = this.translateService.instant('FOOTER.PIVA');
    return `© ${year} ${brandName} - P.IVA ${piva}`;
  }
}
