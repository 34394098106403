<!-- WARNING MESSAGE -->

<app-message
  *ngIf="deprecatedMessage"
  [status]="deprecatedMessageType"
  [text]="deprecatedMessage"
  [hideClose]="true"
>
</app-message>

<!-- LOADING -->
<app-loading
  *ngIf="loading"
  size="xl"
  [message]="'GLOBALS.LOADING' | translate"
>
</app-loading>

<div class="card" *ngIf="!loading">
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <!--  RECAP PRODUCT TYPE -->
      <div *ngIf="showRecapProductType" class="ic1FlexRow">
        <div>
          <span
            translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.METHOD"
            class="bold ic1-mr-2"
          >
          </span>
          <span [translate]="'GLOBALS.' + model.productType"></span>
        </div>
        <ng-ui-kit-button
          (onClick)="goBackTo('productType')"
          [label]="'GLOBALS.EDIT' | translate"
          color="text"
        ></ng-ui-kit-button>
      </div>

      <!-- RECAP CERTYPE (THIRDPARTY) -->
      <div *ngIf="showRecapCertType" class="ic1FlexRow ic1-my-20">
        <div>
          <span
            translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.SELECTED_CERT_TYPE"
            class="bold ic1-mr-2"
          >
          </span>
          <span
            [translate]="
              'SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.OPTIONS.' +
              model.certType +
              '.LABEL'
            "
          ></span>
        </div>
        <ng-ui-kit-button
          (onClick)="goBackTo('certType')"
          [label]="'GLOBALS.EDIT' | translate"
          color="text"
        ></ng-ui-kit-button>
      </div>

      <!-- RECAP CNS RESIDENZA -->
      <div *ngIf="showRecapCNS" class="ic1FlexRow ic1-my-20">
        <div>
          <span
            class="bold ic1-mr-2"
            translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.RESIDENCE_ADDRESS"
          ></span>
          <span>{{ residenceAddress }}</span>
        </div>
        <ng-ui-kit-button
          (onClick)="goBackTo('cns')"
          [label]="'GLOBALS.EDIT' | translate"
          color="text"
        ></ng-ui-kit-button>
      </div>

      <!-- RECAP CONTACTS -->
      <div *ngIf="showRecapContacts" class="ic1-my-20">
        <div class="ic1FlexRow">
          <div>
            <div class="labelValue">
              <span
                class="bold ic1-mr-2"
                translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.EMAIL"
              ></span>
              <span>{{ model.email }}</span>
            </div>
            <div class="labelValue">
              <span
                class="bold ic1-mr-2"
                translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.MOBILE"
              ></span>
              <span>{{ model.mobile }}</span>
            </div>
          </div>
          <ng-ui-kit-button
            (onClick)="goBackTo('contacts')"
            [label]="'GLOBALS.EDIT' | translate"
            color="text"
          ></ng-ui-kit-button>
        </div>

        <div *ngIf="shippingAddress">
          <h4
            class="ic1-my-20"
            translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.SHIPPING_ADDRESS"
          ></h4>

          <div class="ic1FlexRow">
            <div>
              <div class="labelValue">
                <span
                  class="bold ic1-mr-2"
                  translate="SUBSTITUTION.SELECT_SUBSTITUTE.SUBSTITUTE_FORM.STREET"
                ></span>
                <span>{{ shippingAddress }}</span>
              </div>
            </div>
            <ng-ui-kit-button
              (onClick)="goBackTo('contacts')"
              [label]="'GLOBALS.EDIT' | translate"
              color="text"
            ></ng-ui-kit-button>
          </div>
        </div>
      </div>

      <!-- CAMPI STEP CORRENTE -->
      <h4 class="ic1-my-20">{{ currentLabel }}</h4>

      <div class="formWrapper" [ngClass]="{ privacy: step === totalSteps }">
        <formly-form
          [model]="model"
          [fields]="currentFields"
          [form]="form"
          [options]="formOptions"
        ></formly-form>
      </div>

      <!-- AZIONI PREV/NEXT -->
      <div
        class="flex-row space-between ic1-my-32"
        [ngClass]="{ justifyEnd: step === 0 }"
      >
        <ng-ui-kit-button
          [label]="'GLOBALS.PREV' | translate"
          type="button"
          class="btn btn-secondary"
          (onClick)="prev()"
          [hidden]="step === 0"
        >
        </ng-ui-kit-button>

        <ng-ui-kit-button
          class="btn btn-primary ml-3"
          [disabled]="!isCurrentStepValid"
          (onClick)="next()"
          [label]="
            (step === totalSteps ? 'GLOBALS.SUBMIT' : 'GLOBALS.PROCEED')
              | translate
          "
          [hidden]="step > totalSteps"
        >
        </ng-ui-kit-button>
      </div>
    </form>
  </div>
</div>

<app-modal
  #subsModal
  modalId="subsModal"
  [title]="modalTitle"
  [message]="modalMessage"
  type="info"
  (confirm)="onConfirmModal($event)"
  (close)="onCloseModal($event)"
  [showFooter]="false"
>
</app-modal>
<app-debugger [data]="model" title="ModelDebug"></app-debugger>
<app-debugger [data]="{step,totalSteps}" title="FormDebug"></app-debugger>
