import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() showLoading: boolean = true;
  @Input() noMessage: boolean = false;
  @Input() message: string = "";
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = "xl"

}
