import { Component, Input } from '@angular/core';
import { Certificate } from '@app/store/models';
import { BadgeColor } from '@app/store/models/app.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-certificate-expire-date',
  templateUrl: './certificate-expire-date.component.html',
  styleUrls: ['./certificate-expire-date.component.scss']
})
export class CertificateExpireDateComponent {

  @Input() certificate: Certificate;

  constructor(private translateService: TranslateService) { }

  get certificateExpireDate(): string {
    return this.certificate?.expireDate || "N/A"
  }

  get badgeColor(): BadgeColor {
    if (this.certificate?.isExpired) {
      return "error"
    } else {
      return "warning"
    }
  }

  get expireText(): string {

    let translateKey = "GLOBALS.CERTIFICATES.EXPIRING_ON";
    let params = {
      date: this.certificateExpireDate
    };

    if (this.certificate?.isExpired) {
      translateKey = "GLOBALS.CERTIFICATES.EXPIRED";
      params = {
        date: this.certificateExpireDate
      }
    } else if (!this.certificate?.isExpiringIn3Months) {

      params = {
        date: this.certificate.renovableFromDate
      }
      translateKey = "GLOBALS.CERTIFICATES.EXPIRING_IN_3_MONTHS";
    }

    return this.translateService.instant(translateKey, params)
  }

  get showBadge(): boolean {
    return this.certificate.isExpired || this.certificate.isExpiringIn3Months
  }

}
