import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentModes } from '@app/store/models/certificate.model';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription, interval } from 'rxjs';

export type ModalType = 'loading' | 'success' | 'info' | 'warning' | 'error' | 'custom' | 'COUPON' | 'RAO';
export interface ModalData {
  title?: string;
  message?: string;
  type?: ModalType;
  redirectUrl?: string;
  onClose?: () => void;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input("title") _title: string;
  @Input("message") _message: string;
  @Input("redirectUrl") _redirectUrl: string;

  @Input() modalId: string;

  @Input() showFooter: boolean = true;
  @Input() type: ModalType = 'info';

  @Input() paymentRestriction: PaymentModes[] = []

  @Output() confirm = new EventEmitter();
  @Output() close = new EventEmitter();


  countdown: number = 10; // Tempo di countdown in secondi
  private subscription: Subscription;

  get modalRef(): NgxSmartModalComponent {
    return this.ngxSmartModalService.getModal(this.modalId);

  }

  get modalType(): ModalType {
    return this.modalData?.type || this.type || "info";
  }



  emptyModel = {
    coupon: "",
    username: "",
    password: "",
  };

  form = new UntypedFormGroup({});
  formOptions: FormlyFormOptions = {};

  model = this.emptyModel;


  formFieldsTypes = {
    COUPON: [
      {
        key: 'coupon',
        type: 'ic1-text',
        className: 'ic1-col-12  ',

        props: {
          label: 'Coupon',
          placeholder: 'Inserisci il tuo coupon',
          required: true

        },

        validators: {
          validation: [Validators.required],
        },
      }],
    RAO: [{
      key: 'username',
      type: 'ic1-text',
      className: 'ic1-col-12  ',

      props: {
        label: 'Username',
        placeholder: 'Inserisci il tuo username',
        required: true

      },

      validators: {
        validation: [Validators.required],
      },
    },
    {
      key: 'password',
      type: 'ic1-text',
      className: 'ic1-col-12  ',

      props: {
        label: 'Password',
        type: 'password',
        placeholder: 'Inserisci il tuo password',
        required: true

      },

      validators: {
        validation: [Validators.required],
      },
    },
    ]
  }

  formFields = this.formFieldsTypes['COUPON'];


  constructor(public ngxSmartModalService: NgxSmartModalService, private translateService: TranslateService) {

  }

  public openModal() {
    this.initModal();
    this.ngxSmartModalService.open(this.modalId);

  }


  initModal() {

    this.model = this.emptyModel;
    if (this.hasForm) {
      this.formOptions?.resetModel(this.emptyModel);
    }

    if (this.paymentRestriction.length > 0) {
      if (!this.paymentRestriction.includes("COUPON")) {
        this.switchFormFields('RAO');
      }
    }

    // this.modalRef.onAnyCloseEvent.subscribe(() => {
    //   debugger;
    //   this?.modalData?.onClose();
    // });
  }


  resetModal() {
    if (this.type === 'RAO') {
      this.switchFormFields('COUPON');
    }
    this.model = this.emptyModel;
    if (this.hasForm) {
      this.formOptions?.resetModel(this.emptyModel);
    }

  }

  switchFormFields(type: "COUPON" | "RAO") {

    this.type = type;
    this.formFields = this.formFieldsTypes[type];
    this.modalRef.markForCheck();

  }

  public closeModal() {
    this.ngxSmartModalService.close(this.modalId);
    this.resetModal()
    this.close.emit({ event: "close", data: {} });

  }

  public setData(data: ModalData, force: boolean = true) {
    //console.log("🚀 ~ ModalComponent ~ setData ~ data:", data)
    this.modalRef?.setData(data, force);

    if (data.type) {
      this.type = data.type;
    }

    if (data.redirectUrl) {
      this.startCountDown()
    }

    this.modalRef.markForCheck();
  }

  get modalData(): ModalData {
    return this.modalRef?.getData() || {};
  }


  get title() {
    return this.modalData?.title || this._title;
  }

  get message() {
    return this.modalData?.message || this._message;
  }

  get redirectUrl() {
    return this.modalData?.redirectUrl || this._redirectUrl || "";
  }



  confirmModal() {
    //console.log("🚀 ~ ModalComponent ~ onConfirm ~ event:", event)


    if (this.type === 'COUPON') {

      if (this.paymentRestriction.includes("RAO") && this.model.coupon.length >= 5 && this.model.coupon.length <= 9) {
        //è un a login switcho su Rao
        this.switchFormFields('RAO')

        this.model.username = this.model.coupon;
        this.model.coupon = "";
        this.modalRef.markForCheck();
      } else {
        //Invio pagamento coupon
        const data = { coupon: this.model.coupon }
        this.confirm.emit({ event: "confirm", data });
        this.ngxSmartModalService.close(this.modalId);
      }



    } else if (this.type === 'RAO') {
      const data = {
        username: this.model.username,
        password: this.model.password
      }
      this.confirm.emit({ event: "confirm", data });
      this.ngxSmartModalService.close(this.modalId);
    }

  }

  get showLoading() {
    return this.modalType === 'loading';
  }

  get showIcon() {
    return ['success', 'info', 'warning', 'error'].includes(this.modalType)
  }

  get hasForm() {
    return this.type === 'COUPON' || this.type === 'RAO';
  }

  get disabledProceed() {
    if (this.type === 'COUPON' || this.type === 'RAO') {
      return this.form.invalid
    }
    else {
      return false
    }
  }

  onAnyCloseEvent() {
    //console.log("🚀 ~ ModalComponent ~ onAnyCloseEvent ~ event:")

    if (this.modalData.onClose) {

      this.modalData.onClose();

    }
  }


  get seconds() {
    return this.countdown;
  }

  goRedirect() {
    location.href = this.redirectUrl;
  }

  startCountDown() {
    try {
      // Avvia il countdown utilizzando RxJS interval
      this.subscription = interval(1000).subscribe(() => {
        this.countdown--;
        this.modalRef.markForCheck();
        if (this.countdown === 0) {
          // Countdown terminato
          this.subscription?.unsubscribe();
          this.goRedirect();
        }
      });
    } catch (error) {
      console.error('Error starting countdown:', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.subscription) {
        // Annulla la sottoscrizione all'intervallo quando il componente viene distrutto
        this.subscription?.unsubscribe();
      }
    } catch (error) {
      console.error('Error unsubscribing from countdown:', error);
    }
  }
}
