import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-stepper',
  template: `
    <div>
      <div
        *ngFor="
          let step of field.fieldGroup;
          let index = index;
          let last = last
        ">
        <div class="step" >{{ step.props.label }}</div>
        <formly-field [field]="step"></formly-field>

        <div>
          <button *ngIf="index !== 0" class="btn btn-primary" type="button">
            Back
          </button>

          <button
            *ngIf="!last"
            class="btn btn-primary"
            type="button"
            [disabled]="!isValid(step)">
            Next
          </button>

          <button
            *ngIf="last"
            class="btn btn-primary"
            [disabled]="!form.valid"
            type="submit">
            Submit
          </button>
        </div>
      </div>
    </div>
  `,
})
export class FormlyFieldStepper extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every(f => this.isValid(f))
      : true;
  }
}
