<div class="ic1-footer-wrapper">
  <div class="ic1-footer">
    <div class="ic1-footer-content">
      <div class="ic1-footer-left" translate="FOOTER.TITLE"> </div>

      <div class="ic1-footer-right">
        <div class="ic1-footer-copy ">
          {{ rightText}}
        </div>
      </div>

    </div>
  </div>
</div>
<!-- <ng-ui-kit-footer [simple]="true" [footerLeftLabel]="'FOOTER.TITLE' | translate">
</ng-ui-kit-footer> -->
