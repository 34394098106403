import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-privacy-formly-group',
  template: `
    <div class="privacy-formly-group "   >
      <h4  >{{field.props.label}}</h4>
      <div>{{field.props.description}}</div>
      <div class="ic1-my-20">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./form-styles.scss'],

})
export class PrivacyFormlyGroupComponent extends FieldWrapper implements OnInit {

  constructor() {
    super();


  }


  ngOnInit() {

  }


}
