import { createAction, props } from '@ngrx/store';
import { Customization, OwnersType } from '../models/customization.models';


export const setCustomization = createAction(
  '[Session] Set Customization',
  props<Customization>()
);
export const setOwner = createAction(
  '[Session] Set Owner',
  props<{ owner: OwnersType }>()
);
