import { Action, createReducer, on } from '@ngrx/store';
import { setCurrentFlow, setCurrentStepType, setDeviceATR, setDeviceError, toggleDeviceLoaded, toggleDeviceLoading, toggleMainLoading, updateApp } from '../actions/app.actions';
import { AppModels } from '../models';


export const initialState: any = {
  deviceLoading: false,
  mainLoading: false,
  deviceLoaded: false
}

export const appReducer = createReducer<
  AppModels,
  Action
>(
  initialState,
  on(updateApp, (state, app) => {
    return { ...state, ...app };
  }),

  on(toggleMainLoading, (state, { showLoading, loadingMessage = "" }) => {

    return { ...state, loadingMessage, mainLoading: !!showLoading };

  }),
  on(toggleDeviceLoading, (state, { showLoading, loadingMessage = "" }) => {

    if (showLoading) {
      return { ...state, loadingMessage, deviceLoading: !!showLoading, deviceLoaded: false, deviceError: "" };
    } else {

      return { ...state, loadingMessage, deviceLoading: false };
    }
  }),
  on(setDeviceError, (state, { deviceError }) => {

    return { ...state, deviceError, deviceLoading: false, deviceLoaded: true };
  }),

  on(toggleDeviceLoaded, (state, { deviceLoaded }) => {
    return { ...state, deviceLoaded, deviceLoading: false };
  }),
  on(setCurrentFlow, (state, { currentFlow, currentStep }) => {
    return { ...state, currentFlow, currentStep };
  }),

  on(setCurrentStepType, (state, { currentStepType }) => {

    if (!state?.currentFlow) return state;

    const currentStep = state?.currentFlow.find((step) => step.step === currentStepType);
    return { ...state, currentStep };
  }),
  on(setDeviceATR, (state, { deviceATR }) => {
    return { ...state, deviceATR };
  }),

);
