<!-- Warning message se SUBSTITUTION -->
<div *ngIf="mainStep==='substitution'">
  <app-message status="info" [text]="'SUBSTITUTION.GO_PAYMENT.WARNING_MESSAGE' | translate" [hideClose]="true">
  </app-message>
</div>

<!-- Main TITLE -->
<div class="ic1-my-20" *ngIf="!device.isHealthCard">
  <h4 translate="GO_PAYMENT.TITLE"></h4>
  <p translate="GO_PAYMENT.DESCRIPTION"></p>
</div>

<app-certificates-list [selectable]="true" [autoSelect]="true" (certSelected)="onCertsSelected($event)"
  [viewMode]="mainStep"></app-certificates-list>

<!-- PAYMENT METHODS -->
<div class="buttons-row" *ngIf="certificates.length">
  <ng-ui-kit-button *ngIf="payWithCOUPON || payWithRAO" (onClick)="openCouponRaoModal()" [disabled]="!enabledPayButtons"
    [label]="'GO_PAYMENT.BTN_COUPON' | translate" color="secondary"></ng-ui-kit-button>

  <ng-ui-kit-button *ngIf="payWithSHOP" (onClick)="sendEcommercePayment()" [disabled]="!enabledPayButtons"
    [label]="'GO_PAYMENT.BTN_PROCEED' | translate" color="primary"></ng-ui-kit-button>
</div>

<!-- NESSUN METODO DI PAGAMENTO TROVATO -->
<div *ngIf="!hasPaymentMethods">

  <div *ngIf="device.isHealthCard; else noPaymentsMessage">
    <!-- SE E' UNA TESSERA SANITARIA -->
    <div translate="SUBSTITUTION.GO_PAYMENT.NO_PAYMENTS_METHODS_HEALTH_CARD"></div>
  </div>
  <!-- SE NON E' UNA TESSERA SANITARIA -->
  <ng-template #noPaymentsMessage>
    <div translate="SUBSTITUTION.GO_PAYMENT.NO_PAYMENTS_METHODS"></div>
  </ng-template>
</div>

<!-- MODALI -->

<!-- MODALE SOLO PER COUPONS / RAO -->
<app-modal #couponRaoModal modalId="couponRaoModal" [title]="modalTitle" [message]="modalMessage"
  [type]="payWithCOUPON ? 'COUPON' : 'RAO'" [paymentRestriction]="paymentRestriction"
  (confirm)="onConfirmCouponRaoModal($event)" (close)="onCloseModal($event)">
</app-modal>

<!-- MODALE LOADING GENERICA -->
<app-modal #loadingModal modalId="loadingModal" (close)="onCloseLoadingModal($event)" [showFooter]="false">

</app-modal>
