<ng-ui-kit-step-counter
  [steps]="(steps | async) || []"
  [current]="(currentStep | async) || 0"
  (onStepClick)="onStepClick($event)"
>
</ng-ui-kit-step-counter>

<!-- <div class="ic1-step-counter" name="">
  <div
    *ngFor="let step of (steps | async) || []; let i = index"
    class="ic1-step"
    [ngClass]="{
      'ic1-step-done': i < (currentStep | async) || 0,
      'ic1-step-current': i == (currentStep | async) || 0
    }"
  >
    <i *ngIf="i < (currentStep | async)" class="ic1-icon">done</i>
    <span class="ic1-step-name" *ngIf="i == (currentStep | async)">
      {{ i + 1 }} {{ step }}</span
    >
    <i *ngIf="i > (currentStep | async)" class="ic1-step-number">{{ i + 1 }}</i>
  </div>

</div>

<pre>{{ (steps | async) || [] | json }}</pre>
<pre>{{ (currentStep | async) || 0 | json }}</pre> -->
