import { Moment } from 'moment';
import { AppStepsTypes } from './app.models';
import { OwnersType } from './customization.models';
import { DeviceModel } from './device.model';
export type UpdateStatus = "LOADING" | "IDLE" | "UPDATED" | "ERROR";
export type SignType = "REMOTE_SIGN" | "SC" | "BK" | "DECLINED";
export type CertTypeRequest = "CNS" | "CNS_LIKE" | "AUT";
export interface RawCertificate {

  cert: Cert;
  ckaid: 'CNS0' | 'DS3' | string;
  id: string;
  pkcs11: Pkcs11[];
}

interface Pkcs11 {
  ID: string;
  Label: string;
  ObjectClass: string;
}

interface Cert {
  content: string;
  info: Info[];
  nonrepudiation: string;
  type: string;
  validity: Validity;
}

interface Validity {
  from: string;
  to: string;
}

interface Info {
  k: string;
  v: string;
}

export const certTypes = {
  PRA: 'Certificato di Firma Digitale',
  CNDCEC_PRA: 'Certificato di Firma Digitale',
  AUT: 'Certificato di Autenticazione',
  CNS: 'Certificato CNS',
  CNDCEC_CNS: 'Certificato CNS',
  UNKN: 'Sconosciuto',
  REMOTE: 'Certificato remoto',
  UNDEF: 'Sconosciuto'
};

export type CertType = keyof typeof certTypes;
// Certificate ok
export interface Certificate extends RawCertificate {
  certId: string;
  type: CertType;
  humanType: string;
  expires: Moment;
  isExpired: boolean;
  isExpiredMoreThan90days?: boolean;
  /**Se è in scadenza prossima */
  isExpiringIn3Months?: boolean;
  expireDate?: string;
  /**renew service expiration date */
  expireDateServer?: string;
  /**Data in cui è posisible rinnovare */
  renovableFromDate?: string;
  cf: string;
  given_name: string;
  surname: string;
  fullName: string;
  policyId: string;
  title: string;
  email: string;
  mobile: string;
  renewStatus: RenewStatus;
  isSignCertificate: boolean;
  status: CertStatus | undefined;
  hasP10: boolean | undefined;
  cns_pdata?: Date;
  device: { serial: string, atr: string, owner?: string, isHealthCard?: boolean, deviceModel?: DeviceModel }
  owner?: OwnersType;
  updateStatus?: UpdateStatus;
  appStep?: AppStepsTypes;
  paymentRestriction?: PaymentModes[];
  renewableDetails?: RenewableDetails;
  isRenewable?: boolean;
}



export interface CertStatusBools {
  isStatusReady: boolean;
  hasP10: boolean;
  isWaitingPayment: boolean;
  isOrderCreated: boolean;
  isRenewable: boolean;
  isRenewInstallable: boolean;
  certInstalled: boolean;
  canSendRenewRequest: boolean;
  isDeprecated: boolean;
}

interface RenewStatus {
  data?: any;
  status: string;
  err?: any;
}

export interface RenewStatusResponse {
  renewRequest: RenewRequest;
  cert: CertResponse;
}

export interface Contacts {
  email: string;
  mobile: string;
  name: string;
  password: string;
  type: string;
  signedAssertion: string;
  iut: string;
}

export interface DikeTransactionPayload {
  firstName: string;
  lastName: string;
  fiscalCode: string;
  legalNotice: any;
  productType: string;
  email: string;
  mobile: string;
  street?: string;
  number?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  //se e un THIRDPARTY (ruba) il certificato
  certType?: CertTypeRequest,
  /**solo se certType==="CNS" */
  cns?: CNSsubs
}
export interface CNSsubs {
  /**tipo di documento */
  docType: "CI" | "PASSPORT" | "DRIVING_LICENSE";
  /**numero documento */
  docNumber: string;
  /**data di rilascio */
  docReleaseDate: string;
  /**data di scadenza */
  docExpirationDate: string;
  /** indirizzo di residenza */
  residence: address;

}

export interface address {
  street?: string;
  number?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  province?: string;
}
interface CertResponse {
  revoked: boolean;
  renewable: boolean;
  renewableDetails: RenewableDetails;
  type: CertType;
  expirationDate: number;
  alertMessage: string;
  paymentRestriction: PaymentModes[];
  owner: OwnersType;
}

export type PaymentModes = "RAO" | "SHOP" | "COUPON";

export interface RenewableDetails {
  roleCertificateAbilitationError: boolean;
  expiredWithoutP10Error: boolean;
  pDataCNSCheckError: boolean;
  only1024SmartCardError: boolean;
  carteCamerali2014Error: boolean;
  coungruentState: boolean;
}

interface RenewRequest {
  status: CertStatus;
  hasP10: boolean;
  tokenId: string;
}


//export type CertStatus = "empty" | "p10_generated" | "on_cart" | "order_created" | "renew_enabled" | "cert_generated" | "cert_downloaded" | "cert_installed";

export enum CertStatus {
  EMPTY = 'empty',
  P10_GENERATED = 'p10_generated',
  ON_CART = 'on_cart',
  ORDER_CREATED = 'order_created',
  RENEW_ENABLED = 'renew_enabled',
  CERT_GENERATED = 'cert_generated',
  CERT_DOWNLOADED = 'cert_downloaded',
  CERT_INSTALLED = 'cert_installed'
}
//convert enum to  type
export type CertStatusType = keyof typeof CertStatus;

//convert type to array
export const CertStatusArray = Object.keys(CertStatus).map(key => CertStatus[key as CertStatusType]);



export interface RenewTypeResponse {
  email: string;
  mobile: string;
  name: string;
  password: string;
  type: SignType;
  signedAssertion: string;
  iut: string;
  coupon?: string;
}
