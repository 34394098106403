<app-main-loading>

  <div *ngIf="mainStep==='substitution'">
    <app-substitution-complete-title [cosmoContacts]="cosmoContacts"> </app-substitution-complete-title>
  </div>

  <app-message *ngIf="warningMessage;" status="warning" [text]="warningMessage" [hideClose]="true">
  </app-message>


  <app-certificates-list></app-certificates-list>

  <div class="buttons-row">



  </div>

  <app-debugger [data]="device" title="device"></app-debugger>

</app-main-loading>
