<app-debugger [data]="$STATE | async" title="STATE">

</app-debugger>
<ng-container>
  <!-- Loading o errore -->
  <div *ngIf="
      ($deviceErrorMessage | async) || isDeviceLoading;
      else deviceList
    ">
    <app-device-loading></app-device-loading>
  </div>

  <ng-template #deviceList>
    <!-- Se non ho selezionato un device -->
    <div *ngIf="!($deviceSelected | async); else selectedDevice">
      <!-- Device caricati -->
      <div *ngIf="$deviceLoaded | async; else loadDevice">
        <p [translate-html]="'GLOBALS.DEVICES.MULTIPLE_DEVICES_FOUND'"></p>

        <app-devices-select [autoSelectDeviceATR]="autoSelectDeviceATR"></app-devices-select>
      </div>
    </div>
    <!--  device Selezionato -->
    <ng-template #selectedDevice> <app-loading [showLoading]="true"></app-loading> </ng-template>

  </ng-template>

  <!-- Carica Device -->
  <ng-template #loadDevice>
    <h4 [translate-html]="'GLOBALS.SELECT_CERTIFICATE.TITLE'"></h4>
    <p [translate-html]="'GLOBALS.SELECT_CERTIFICATE.DESCRIPTION'"></p>
  </ng-template>
</ng-container>

<ng-container #footer>
  <div class="buttons-row">

    <ng-ui-kit-button [label]="'GLOBALS.DEVICES.REFRESH_DEVICES' | translate" [disabled]="disabledButton"
      (onClick)="getDevices()" [iconBefore]="'sync'" [block]="true"></ng-ui-kit-button>
  </div>
</ng-container>
