import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getTrueBooleanKeyFromCertificate } from '@app/helpers/certificate.helpers';
import { Certificate } from '@app/store/models';
import { AppFlow, AppStep, MainStep } from '@app/store/models/app.models';
import { CertStatus, UpdateStatus, certTypes } from '@app/store/models/certificate.model';
import { DeviceModels } from '@app/store/models/device.model';
import { getCurrentFlowAndStep } from '@app/store/selectors/app.selector';
import { GlobalState } from '@app/store/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  @Input() certificate: Certificate | undefined;
  @Input() viewMode: MainStep = "renew";
  @Input() selectable: boolean = false;
  @Input() autoSelect: boolean = false;
  @Output() certSelected = new EventEmitter<{ certificate: Certificate, selected: boolean }>();

  status: UpdateStatus = "IDLE";
  isChecked: boolean = false;
  $currFlow: {
    currentFlow: AppFlow;
    currentStep: AppStep;
    mainStep: MainStep;
  };

  constructor(private translateService: TranslateService, private store: Store<GlobalState>) {
    this.store.select(getCurrentFlowAndStep).subscribe(flow => {
      this.$currFlow = flow
    })


  }

  ngOnInit(): void {

    setTimeout(() => {
      if (this.autoSelect && this.certificate && !this.disabledCheckbox) {
        this.onChangeCheckbox(true, this.certificate)
      }
    }, 1000)
  }




  get updateStatus(): UpdateStatus {

    return this.certificate?.updateStatus || "IDLE";
  }


  get certificateType(): string {
    if (this.certificate?.type) {
      return certTypes[this.certificate?.type] || "N/A"
    } else {
      return "N/A"
    }
  }

  get certificateStatusLabel(): string {

    if (this.trueBoolean) {
      return this.translateService.instant(`GLOBALS.CERTIFICATES.BOOLEANS.${this.trueBoolean}`)
    }
    if (this.certificate?.status === CertStatus.EMPTY) {
      return "";
    }
    else if (this.certificate?.status && !this?.certificate?.device?.isHealthCard) {
      //ng-if="cert.status.isStatusReady && (cert.status.isOrderCreated || cert.status.isWaitingPayment)"

      const key = `GLOBALS.CERTIFICATES.STATUS.${this.certificate?.status?.toUpperCase()}`
      return this.translateService.instant(key)

    } else if (this?.certificate?.device?.isHealthCard) {

      const key = `GLOBALS.CERTIFICATES.HEALTH_CARD_STATUS.${this.certificate?.status?.toUpperCase()}`
      return this.translateService.instant(key)

    } else {
      return ""
    }
  }


  get expireText(): string {

    const translateKey = this.certificate.isExpired ? `GLOBALS.CERTIFICATES.EXPIRED` : `GLOBALS.CERTIFICATES.EXPIRING_ON`
    return this.translateService.instant(translateKey)
  }

  get badgeColor(): string {

    if (this.trueBoolean) {
      return "danger"
    } else if ([
      CertStatus.CERT_INSTALLED,
      CertStatus.CERT_DOWNLOADED,
      CertStatus.CERT_GENERATED,
      CertStatus.RENEW_ENABLED,
    ].includes(this.certificate?.status)) {
      return "success"
    } else {
      return "warning"
    }
  }

  get certificateExpireDate(): string {
    return this.certificate?.expireDate || "N/A"
  }

  get certificateSerialNumber(): string {
    return `${this.certificate?.type}: ${this.certificate?.certId.replace(/:/g, ': ')}`
  }

  get hasCurrentStep() {
    return this.$currFlow?.currentStep?.step === this.certificate.appStep;
  }

  get showCheckbox(): boolean {
    return this.selectable && (this.viewMode === "substitution" || !this.certificate?.isExpired)
  }


  get trueBoolean(): string {
    // se la vista è in modalità "substitution" skippo il controllo delle booleane
    if (this.viewMode === "substitution") return "";
    return getTrueBooleanKeyFromCertificate(this.certificate);
  }

  get disabledCheckbox(): boolean {
    // Inizialmente si presuppone che la checkbox possa essere disabilitata
    let isDisabled = true;
    //TODO: check if this is correct
    //if this.viewMode === "substitution" return false
    // Verifica se il certificato appartiene a INFOCERT, il modello del dispositivo è "CNS v2"
    // e il certificato è un certificato di firma.
    // Questo indica che la checkbox dovrebbe sempre essere selezionabile indipendentemente dalle altre condizioni.

    const deprecatedModels: DeviceModels[] = ["CNS v2", "JSign v3", "Cosmo v7.0", "GenericDevice"];

    if (this.certificate.owner === "INFOCERT" &&
      deprecatedModels.includes(this.certificate.device.deviceModel.name) &&
      this.certificate.isSignCertificate) {
      isDisabled = false; // La checkbox non è disabilitata
    } else {
      // In tutti gli altri casi, la checkbox sarà disabilitata se una delle seguenti condizioni è vera:
      // 1. `trueBoolean` contiene almeno una booleana vera
      // 2. Non ha lo stesso step di quello corrente
      // 3. Il certificato non sta per scadere nei prossimi 3 mesi (`isExpiringIn3Months` è falso)
      isDisabled = !!this.trueBoolean || !this.hasCurrentStep || !this.certificate.isExpiringIn3Months;
    }

    // Restituisce lo stato della checkbox, true se disabilitata, altrimenti false
    return isDisabled;
  }


  onChangeCheckbox(checked: boolean, certificate: Certificate) {
    this.isChecked = checked;
    this.certSelected.emit({ certificate, selected: checked })


  }


  isOpen: boolean = false;

  onToggle(opened = !this.isOpen) {
    this.isOpen = opened
  }

  get accordionIcon(): string {
    return this.isOpen ? "expand_less" : "expand_more"
  }

}
