import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-layout-default',

  template: `
<div class="main-container"  >
  <app-header></app-header>

  <main class="content">
     <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutDefaultComponent {

}
