import { Action, createReducer, on } from '@ngrx/store';
import { refreshDevices, removeDevices, setDevices } from '../actions/devices.actions';
import { Device } from '../models';


export const initialState: any = []

export const devicesReducer = createReducer<
  Device[],
  Action
>(
  initialState,
  on(setDevices, (state, { devices }) => {
    return [...devices]
  }),
  on(removeDevices, (state, device) => {
    return []
  }),

  on(refreshDevices, (state, device) => {
    return state
  }),



);


