import { Component, Input } from '@angular/core';
import { downloadP7Mcertificate } from '@app/helpers/device.helpers';
import { RenewTypeResponse } from '@app/store/models/certificate.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-substitution-complete-title',
  templateUrl: './complete-title.component.html',
  styleUrls: ['./complete-title.component.scss']
})
export class SubsitutionCompleteTitleComponent {

  @Input() cosmoContacts: RenewTypeResponse = undefined

  constructor(private translateService: TranslateService) {
  }

  get showComponent() {


    if (!this.cosmoContacts?.type || this.cosmoContacts.type === "DECLINED") return false
    return true

  }
  get substituteTitle() {
    if (!this.cosmoContacts?.type) return "";
    const typeLabel = this.translateService.instant('GLOBALS.' + this.cosmoContacts?.type)

    if (this.cosmoContacts?.type === "REMOTE_SIGN") {

      return this.translateService.instant('SUBSTITUTION.CERT_INSTALLED.REMOTE_SIGN', {
        email: this.cosmoContacts?.email,
        username: this.cosmoContacts?.name
      });
    }
    return this.translateService.instant('SUBSTITUTION.CERT_INSTALLED.TITLE', {
      typeLabel
    });
  }

  get substituteIUT() {
    if (!this.cosmoContacts?.iut) return ""

    return this.translateService.instant('SUBSTITUTION.CERT_INSTALLED.IUT', {
      iut: this.cosmoContacts?.iut
    });
  }



  downloadCertificate(base64Data = this?.cosmoContacts?.signedAssertion) {
    console.count("downloadCertificate")
    if (!base64Data) return
    downloadP7Mcertificate(base64Data)
  };

}
