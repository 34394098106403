import { DikeSession } from '@app/models/dike.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { removeSession, setSession } from '../actions/session.actions.';

export const initialState: any = undefined;

export const sessionReducer = createReducer<DikeSession, Action>(
  initialState,
  on(setSession, (state, session) => {
    return { ...state, ...session };
  }),
  on(removeSession, (state, session) => {
    return Object.assign({}, initialState);
  })
);
