<app-main-loading>
  <app-message *ngIf="deprecatedError" status="error" [text]="deprecatedError" [hideClose]="true"></app-message>

  <app-certificates-list [selectable]="false"></app-certificates-list>
  <!-- devono essewre 2 EOL
1) EOL nessun metodo di pagamento, rinnovo disabilitato
2) EOL per certificato scaduto

se substitution e owner != infocert  o sixtema
=> Messaggi errore diveri per  owner


 -->
  <app-debugger [data]="device" title="device"></app-debugger>


</app-main-loading>
