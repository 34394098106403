import { Component, Input } from '@angular/core';
import { BadgeColor, Sizes } from '@app/store/models/app.models';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent {

  @Input() type: BadgeColor = "info";
  @Input() size: Sizes = "large";


  get icon(): string {
    switch (this.type) {
      case "success":
        return "task_alt";
      case "error":
        return "error";
      case "warning":
        return "warning";
      default:
        return "info"
    }
  }
}
