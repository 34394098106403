import { urlHasParam } from '@app/helpers/helpers';
import { STUB_RANDO } from '@app/stubs/stub-devices';
import { GlobalState } from '../store';
const isDebug = urlHasParam('debug');

export const selectDevice = (state: GlobalState) => {
  let device = state.device || undefined;

  if (window.dikeDebug.stubDevice) {
    device = STUB_RANDO()
  }
  //console.log("🚀 ~ selectDevice ~ device:", device)


  return device;
}


export const selectDeviceCertificates = (state: GlobalState) => state?.device?.certificates || [];
