import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutType } from './layout/routes.layouts';

import { checkFlowComponent } from './routes/check-flow/check-flow.component';
import { CompletedComponent } from './routes/completed/completed.component';
import { RenewComponent } from './routes/renew/renew.component';
import { SelectDeviceComponent } from './routes/select-device/select-device.component';
import { SubstitutionComponent } from './routes/substitution/substitution.component';
import { WelcomePageComponent } from './routes/welcome/welcome.component';



export const ROUTE_PATHS = {
  home: '',
  checkDevice: 'checkDevice',
  selectDevice: 'selectDevice',
  selectCertificate: 'selectCertificate',
  renew: 'renew',
  substitution: 'substitution',
  cert_installed: 'cert_installed',
  completed: 'completed',

}


const routes: Routes = [
  {
    path: ROUTE_PATHS.home,
    component: WelcomePageComponent,
    data: {
      layout: AppLayoutType.Center // 1
    },


  },
  {
    path: ROUTE_PATHS.completed,
    component: CompletedComponent,
    data: {
      layout: AppLayoutType.Default
    },
  },

  {
    path: ROUTE_PATHS.checkDevice,
    component: checkFlowComponent,
    children: [
      {
        path: ROUTE_PATHS.selectDevice,
        pathMatch: 'prefix',
        component: SelectDeviceComponent,

      },

      {
        path: '**',
        redirectTo: ROUTE_PATHS.selectDevice,
        pathMatch: 'prefix',
      },
    ],
  },


  {
    path: ROUTE_PATHS.renew,
    component: checkFlowComponent,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: RenewComponent,
      },
      {
        path: '**',
        redirectTo: ROUTE_PATHS.renew,
        pathMatch: 'prefix',
      },
    ],

  },
  /** SUBSTITUTION ROUTES */
  {
    path: ROUTE_PATHS.substitution,
    pathMatch: 'full',
    redirectTo: ROUTE_PATHS.substitution + '/'
  },
  {
    path: ROUTE_PATHS.substitution + '/:step',
    component: checkFlowComponent,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: SubstitutionComponent,
      },
      {
        path: '**',
        redirectTo: ROUTE_PATHS.substitution + '/',
        pathMatch: 'prefix',
      },
    ],
  },



  {
    path: '**',
    redirectTo: '/' + ROUTE_PATHS.home,

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',


  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
